import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { AdminAPI, ProductResponseDto } from "src/shared/api"
import { ProductStatusType } from "src/shared/config/types"

export const useProducts = (status: ProductStatusType) => {
  const [productsCounts, setProductsCounts] = useState<Record<string, number>>({})

  const { data: productsData } = useQuery({
    queryKey: ['products', status],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.managementControllerFindAllProducts({ page: 1, take: 200, status }),
  })

  useEffect(() => {
    if (productsData?.data) {
      const { data, ...newProductsCounts } = productsData.data

      if (newProductsCounts) {
        setProductsCounts(newProductsCounts)
      }
    }
  }, [productsData])

  // @ts-ignore
  const products = (productsData?.data?.products ?? []) as ProductResponseDto[]

  return {
    products,
    productsCounts
  }
}
