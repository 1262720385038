import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'
import { OrderStatusType } from 'src/shared/config/types'

import styles from './СollectionButton.module.scss'

interface СollectionButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  status: OrderStatusType.IN_PROGRESS | OrderStatusType.PAUSE
}

export const СollectionButton: FC<СollectionButtonPropsType> = ({ className, status, ...props }) => {
  return (
    <button className={cn(
        styles.СollectionButton,
        status === OrderStatusType.PAUSE ? styles.СollectionButton__kindStart : styles.СollectionButton__kindStop,
        className
      )}
      {...props}
    >
      {status === OrderStatusType.PAUSE ? 'Возобновить сбор' : 'Приоставить сбор'}
      {<Icon name={status === OrderStatusType.PAUSE ? 'start' : 'stop'} />}
    </button>
  )
}
