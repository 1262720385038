import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { useUserInfo } from 'src/shared/model/useUser'

import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { LINKS as BASE_LINKS } from '../../config/consts'

import styles from './CabinetLinks.module.scss'

const CabinetLinks: FC = () => {
  const { user } = useUserInfo()

  const LINKS = useMemo(() => {
    return BASE_LINKS.map((link) => ({
      ...link,
      href: link.href?.includes('userId=') ? link.href.replace('userId=', `userId=${user?.id || ''}`) : link.href,
    }))
  }, [user])

  return (
    <Flex className={styles.CabinetLinks} flexDirection="column" isFluid>
      <PageTitle>Ссылки</PageTitle>
      <Flex flexDirection="column" gap={20}>
        {LINKS.map((link, index) => (
          <article key={index} className={styles.Link}>
            <div className={styles.Link__info}>
              <Flex flexDirection="column" gap={8}>
                <strong className={styles.Link__title}>{link.title}</strong>
                <p className={styles.Link__desc}>{link.desc}</p>
              </Flex>
              {!link.href ? (
                <span className={cn(styles.Link__button, styles.Link__button_disabled)} aria-disabled="true">
                  Подробнее
                </span>
              ) : (
                <Link to={link.href} className={styles.Link__button}>
                  Подробнее
                </Link>
              )}
            </div>
            <div className={styles.Link__img}>
              <img src={link.img} alt="" />
            </div>
          </article>
        ))}
      </Flex>
    </Flex>
  )
}

export default CabinetLinks
