import { BankDetailsDto, TopicResponseDto } from '../api'

export const getTopicsChanges = (existingTopics: TopicResponseDto[], newTopics: { topicId: string }[]) => {
  if (!newTopics) {
    return { newTopics: [], deletedTopics: [] }
  }

  const existingTopicIds = existingTopics?.map((topic) => String(topic.id)) || []
  const newPreparedTopics = newTopics.filter(({ topicId }) => !existingTopicIds.includes(topicId))
  const deletedTopics = existingTopicIds.filter(
    (existingTopicId) => !newTopics.some(({ topicId }) => topicId === existingTopicId),
  )

  return { newTopics: newPreparedTopics, deletedTopics }
}

export const getBankDetailsChange = (existingBankDetails: BankDetailsDto[], newBankDetails: BankDetailsDto[]) => {
  if (!newBankDetails) {
    return undefined
  }

  const newPreparedBankDetails = newBankDetails.map((bankDetail) => {
    const existingDetail = existingBankDetails.find((item) => item.id === bankDetail.id)
    if (existingDetail) {
      // Если существует, проверить изменения
      const isModified =
        bankDetail.accountNumber !== existingDetail.accountNumber ||
        bankDetail.accountPhone.slice(-10) !== existingDetail.accountPhone.slice(-10) ||
        bankDetail.bankName !== existingDetail.bankName ||
        bankDetail.names !== existingDetail.names

      if (isModified) {
        return {
          id: bankDetail.id,
          bankName: bankDetail.bankName,
          accountNumber: bankDetail.accountNumber,
          accountPhone: `+7${bankDetail.accountPhone.slice(-10)}`,
          names: bankDetail.names,
        } // Обновленный объект
      }
      return null // Без изменений
    } else {
      // Новый объект (без id)
      return {
        bankName: bankDetail.bankName,
        accountNumber: bankDetail.accountNumber,
        accountPhone: `+7${bankDetail.accountPhone.slice(-10)}`,
        names: bankDetail.names,
      }
    }
  })

  // Добавляем для удаления только id отсутствующих объектов
  const deletedBankDetails = existingBankDetails
    .filter((existingItem) => !newBankDetails.some((item) => item.id === existingItem.id))
    .map((item) => ({ id: item.id }))

  // Формируем окончательный массив для отправки
  return [
    ...newPreparedBankDetails.filter(Boolean), // Новые или измененные объекты
    ...deletedBankDetails, // Удаленные объекты в формате { id }
  ]
}
