import React from 'react'
import { Navigate, type RouteObject } from 'react-router-dom'

import { AdminCabinet } from 'src/pages/AdminCabinet/ui/AdminCabinet'
import { AdminCabinetIntegration } from 'src/pages/AdminCabinetIntegration'
import { AdminCabinetMarketplaces } from 'src/pages/AdminCabinetMarketplaces'
import { AdminCabinetProduct } from 'src/pages/AdminCabinetProduct'
import { AdminCabinetProducts } from 'src/pages/AdminCabinetProducts'
import { AdminCabinetTask } from 'src/pages/AdminCabinetTask'
import { AdminCabinetTasks } from 'src/pages/AdminCabinetTasks'
import { AdminCabinetTopics } from 'src/pages/AdminCabinetTopics'
import { AdminCabinetUser } from 'src/pages/AdminCabinetUser'
import { AdminCabinetUsers } from 'src/pages/AdminCabinetUsers'
import { BloggerCabinet } from 'src/pages/BloggerCabinet'
import { BloggerCabinetTask } from 'src/pages/BloggerCabinetTask'
import { BloggerCabinetTasks } from 'src/pages/BloggerCabinetTasks'
import { BloggerLanding } from 'src/pages/BloggerLanding'
import { BloggerRegistration } from 'src/pages/BloggerRegistration'
import { Cabinet } from 'src/pages/Cabinet/ui/Cabinet/Cabinet'
import { CabinetBloggersInfo } from 'src/pages/CabinetBloggersInfo'
import { CabinetIntegration } from 'src/pages/CabinetIntegration'
import { CabinetNotifications } from 'src/pages/CabinetNotifications'
import { CabinetOrderQuide } from 'src/pages/CabinetOrderQuide'
import { CabinetPayment } from 'src/pages/CabinetPayment'
import { CabinetProduct } from 'src/pages/CabinetProduct'
import { CabinetProducts } from 'src/pages/CabinetProducts'
import { CabinetProductsNew } from 'src/pages/CabinetProductsNew'
import { CabinetProfile } from 'src/pages/CabinetProfile'
import { CabinetSettings } from 'src/pages/CabinetSettings'
import { CabinetTask } from 'src/pages/CabinetTask'
import { CabinetTasks } from 'src/pages/CabinetTasks'
import { CabinetTasksNew } from 'src/pages/CabinetTasksNew'
import { CabinetUser } from 'src/pages/CabinetUser'
import { CustomerLanding } from 'src/pages/CustomerLanding'
import { CustomerRegistration } from 'src/pages/CustomerRegistration'
import { Login } from 'src/pages/Login'
import { ForgotPasswordBlock } from 'src/pages/Login/ui/ForgotPasswordBlock/ForgotPasswordBlock'
import { LoginBlock } from 'src/pages/Login/ui/LoginBlock/LoginBlock'
import { ResetPasswordBlock } from 'src/pages/Login/ui/ResetPasswordBlock/ResetPasswordBlock'
import {
  BLOGGER_LANDING_ROUTE,
  BLOGGER_REGISTRATION_ROUTE,
  CABINET_BLOGGERS_INFO_ROUTE,
  CABINET_MARKETPLACES_ROUTE,
  CABINET_NOTIFICATIONS,
  CABINET_ORDER_QUIDE_ROUTE,
  CABINET_PAYMENT_ROUTE,
  CABINET_PRODUCT_ROUTE,
  CABINET_PRODUCTS_NEW_ROUTE,
  CABINET_PRODUCTS_ROUTE,
  CABINET_PROFILE_ROUTE,
  CABINET_ROUTE,
  CABINET_SETTINGS,
  CABINET_TASK_RESPONSE_ROUTE,
  CABINET_TASK_ROUTE,
  CABINET_TASKS_NEW_ROUTE,
  CABINET_TASKS_ROUTE,
  CABINET_TOPICS_ROUTE,
  CABINET_USER_ROUTE,
  CABINET_USERS_ROUTE,
  CUSTOMER_LANDING_ROUTE,
  CUSTOMER_REGISTRATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
} from 'src/shared/config/consts'

import { CabinetLayout } from 'src/widgets/CabinetLayout'
import { Switch } from 'src/widgets/Switch'

export const useRootRoutes = (): RouteObject[] => [
  {
    path: '/',
    element: <Navigate replace to={CUSTOMER_LANDING_ROUTE} />,
  },
  {
    path: BLOGGER_LANDING_ROUTE,
    element: <BloggerLanding />,
  },
  {
    path: BLOGGER_REGISTRATION_ROUTE,
    element: <BloggerRegistration />,
  },
  {
    path: CUSTOMER_LANDING_ROUTE,
    element: <CustomerLanding />,
  },
  {
    path: CUSTOMER_REGISTRATION_ROUTE,
    element: <CustomerRegistration />,
  },
  {
    path: LOGIN_ROUTE,
    element: <Login />,
    children: [
      {
        index: true,
        element: <LoginBlock />,
      },
      {
        path: FORGOT_PASSWORD_ROUTE,
        element: <ForgotPasswordBlock />,
      },
      {
        path: RESET_PASSWORD_ROUTE,
        element: <ResetPasswordBlock />,
      },
    ],
  },
  {
    path: CABINET_ROUTE,
    element: <CabinetLayout />,
    children: [
      {
        index: true,
        element: <Switch BloggerComponent={BloggerCabinet} CustomerComponent={Cabinet} AdminComponent={AdminCabinet} />,
      },
      {
        path: CABINET_PRODUCTS_ROUTE,
        children: [
          {
            index: true,
            element: <Switch CustomerComponent={CabinetProducts} AdminComponent={AdminCabinetProducts} />,
          },
          {
            path: CABINET_PRODUCTS_NEW_ROUTE,
            element: <CabinetProductsNew />,
          },
          {
            path: CABINET_PRODUCT_ROUTE,
            element: <Switch CustomerComponent={CabinetProduct} AdminComponent={AdminCabinetProduct} />,
          },
        ],
      },
      {
        path: CABINET_TASKS_ROUTE,
        children: [
          {
            index: true,
            element: (
              <Switch
                BloggerComponent={BloggerCabinetTasks}
                CustomerComponent={CabinetTasks}
                AdminComponent={AdminCabinetTasks}
              />
            ),
          },
          {
            path: CABINET_TASKS_NEW_ROUTE,
            element: <CabinetTasksNew />,
          },
          {
            path: CABINET_TASK_ROUTE,
            children: [
              {
                index: true,
                element: (
                  <Switch
                    BloggerComponent={BloggerCabinetTask}
                    CustomerComponent={CabinetTask}
                    AdminComponent={AdminCabinetTask}
                  />
                ),
              },
              {
                path: CABINET_TASK_RESPONSE_ROUTE,
                element: <Switch CustomerComponent={CabinetIntegration} AdminComponent={AdminCabinetIntegration} />,
              },
            ],
          },
        ],
      },
      {
        path: CABINET_PROFILE_ROUTE,
        element: <CabinetProfile />,
      },
      {
        path: CABINET_USERS_ROUTE,
        children: [
          {
            index: true,
            element: <AdminCabinetUsers />,
          },
          {
            path: CABINET_USER_ROUTE,
            element: <Switch CustomerComponent={CabinetUser} AdminComponent={AdminCabinetUser} />,
          },
        ],
      },
      {
        path: CABINET_NOTIFICATIONS,
        element: <CabinetNotifications />,
      },
      {
        path: CABINET_SETTINGS,
        element: <CabinetSettings />,
      },
      {
        path: CABINET_TOPICS_ROUTE,
        element: <AdminCabinetTopics />,
      },
      {
        path: CABINET_MARKETPLACES_ROUTE,
        element: <AdminCabinetMarketplaces />,
      },
      {
        path: CABINET_BLOGGERS_INFO_ROUTE,
        element: <CabinetBloggersInfo />,
      },
      {
        path: CABINET_ORDER_QUIDE_ROUTE,
        element: <CabinetOrderQuide />,
      },
      {
        path: CABINET_PAYMENT_ROUTE,
        element: <CabinetPayment />,
      },
    ],
  },
]
