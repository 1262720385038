import { useQuery } from "@tanstack/react-query"
import { AdminAPI, MarketplaceResponseDto } from "../api"
import { useUserInfo } from "./useUser"
import { RoleType } from "../config/types"

export const useMarketplaces = (): MarketplaceResponseDto[] => {
  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const queryKey = ['marketplaces']

  const { data: marketplacesData } = useQuery({
    queryKey,
    queryFn: AdminAPI.api.managementControllerFindAllMarketplace2,
    enabled: role !== RoleType.SUPER_ADMIN
  })
  const marketplaces = (marketplacesData?.data ?? []) as MarketplaceResponseDto[]

  const { data: superAdminMarketplacesData } = useQuery({
    queryKey,
    queryFn: AdminAPI.api.managementControllerFindAllMarketplace,
    enabled: role === RoleType.SUPER_ADMIN
  })
  const superAdminMarketplaces = (superAdminMarketplacesData?.data ?? []) as MarketplaceResponseDto[]

  return role === RoleType.SUPER_ADMIN ? superAdminMarketplaces : marketplaces
}
