import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'

import styles from './CabinetActionButton.module.scss'

interface CabinetActionButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: 'primary' | 'ghost' | 'danger'
}

export const CabinetActionButton: FC<CabinetActionButtonPropsType> = ({ className, kind = 'primary', ...props }) => {
  return (
    <button
      className={cn(
        styles.CabinetActionButton,
        kind === 'ghost' && styles.CabinetActionButton__kindGhost,
        kind === 'danger' && styles.CabinetActionButton__kindDanger,
        className
      )}
      {...props}
   />
  )
}
