import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CabinetBlacklist } from '../CabinetBlacklist/CabinetBlacklist'
import { CabinetFAQ } from '../CabinetFAQ/CabinetFAQ'
import CabinetLinks from '../CabinetLinks/CabinetLinks'

import styles from './Cabinet.module.scss'

export const Cabinet: FC = () => {
  return (
    <CabinetPageLayout>
      <Flex className={styles.Container} gap={32}>
        <Flex flexDirection="column" gap={32}>
          <CabinetFAQ />
          <CabinetBlacklist />
        </Flex>
        <CabinetLinks />
      </Flex>
    </CabinetPageLayout>
  )
}
