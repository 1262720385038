import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { UserResponseDto } from 'src/shared/api'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Profile } from 'src/widgets/Profile'

import { Balance } from '../Balance/Balance'
import { Products } from '../Products/Products'
import { Tasks } from '../Tasks/Tasks'

import styles from './CustomerUser.module.scss'

enum TabType {
  PROFILE = 'PROFILE',
  BALANCE = 'BALANCE',
  PRODUCTS = 'PRODUCTS',
  TASKS = 'TASKS',
}

interface CustomerUserPropsType {
  user: UserResponseDto
}

export const CustomerUser: FC<CustomerUserPropsType> = ({ user }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_USERS_ROUTE), [navigate])

  const [activeTabId, setActiveTabId] = useState<string>(TabType.PROFILE)

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PROFILE,
        title: 'Личная информация',
      },
      {
        tabId: TabType.BALANCE,
        title: 'Баланс',
      },
      {
        tabId: TabType.PRODUCTS,
        title: 'Товары селлера',
      },
      {
        tabId: TabType.TASKS,
        title: 'Задания селлера',
      },
    ],
    [],
  )

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Селлер</PageTitle>
      </Flex>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={setActiveTabId} />
      {activeTabId === TabType.PROFILE && <Profile user={user} className={styles.Profile} />}
      {activeTabId === TabType.BALANCE && <Balance user={user} />}
      {activeTabId === TabType.PRODUCTS && <Products user={user} />}
      {activeTabId === TabType.TASKS && <Tasks user={user} />}
    </CabinetPageLayout>
  )
}
