import React, { FC, useMemo } from 'react'
import { useMarketplaces } from 'src/shared/model/useMarketplaces'
import { SelectFormField, SelectFormFieldPropsType } from 'src/shared/ui/SelectFormField'

type MarketplaceSelectFormFieldPropsType = Omit<SelectFormFieldPropsType, 'options'>

export const MarketplaceSelectFormField: FC<MarketplaceSelectFormFieldPropsType> = (props) => {
  const marketplaces = useMarketplaces()

  const options = useMemo(() => marketplaces.map(item => ({
    value: String(item.id),
    label: item.name
  })), [marketplaces])

  return (
    <SelectFormField
      options={options}
      {...props}
    />
  )
}
