import React, { FC, ReactNode } from 'react'
import { MarketplaceSelectFormField } from '../MarketplaceSelectFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { TopicsMultiSelectFormField } from 'src/shared/ui/TopicsMultiSelectFormField'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { UploaderFormField } from 'src/shared/ui/UploaderFormField'
import { Flex } from 'src/shared/ui/Flex'

import styles from './ProductForm.module.scss'

interface ProductFormPropsType {
  isDisabled: boolean
  children?: ReactNode
}

// TODO Возможно неудачное название, это не полноценная форма, а только поля
export const ProductForm: FC<ProductFormPropsType> = ({ isDisabled, children }) => {
  return (
    <Flex className={styles.ProductForm} flexDirection="column">
      {children}
      <TopicsMultiSelectFormField
        id="topics"
        name="topics"
        fieldName="topicId"
        label="Категория"
        placeholder="Выберите категорию из списка"
        addButtonText="Добавить категорию"
      />
      <TextInputFormField
        id="marketplaceUrl"
        name="marketplaceUrl"
        label="Ссылка на товар"
        placeholder="Ссылка на товар"
        kind="cabinet"
        disabled={isDisabled}
      />
      <MarketplaceSelectFormField
        id="marketplaceId"
        name="marketplaceId"
        label="Выберите маркетплейс"
        placeholder="Выберите маркетплейс"
      />
      <TextareaFormField
        id="title"
        name="title"
        label="Название"
        placeholder="Название"
        kind="cabinet"
        disabled={isDisabled}
      />
      <NumericInputFormField
        id="article"
        name="article"
        label="Артикул"
        placeholder="Артикул"
        kind="cabinet"
        disabled={isDisabled}
      />
      <NumericInputFormField
        id="price"
        name="price"
        label="Цена товара"
        placeholder="Цена товара"
        kind="cabinet"
        unit="₽"
        disabled={isDisabled}
      />
      <UploaderFormField
        id="photo"
        name="photo"
        label="Фотография товара"
        kind="cabinet"
        type="image"
        isDisabled={isDisabled}
      />
    </Flex>
  )
}
