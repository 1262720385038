import React, { FC } from 'react'
import cn from 'classnames'

import styles from './ProgressBar.module.scss'

export enum StagesEnum {
  STAGE_1 = 1,
  STAGE_2 = 2,
}

export interface ProgressBarPropsType {
  stage: StagesEnum
}

export const ProgressBar: FC<ProgressBarPropsType> = ({ stage }) => {
  return (
    <div className={cn(
      styles.ProgressBar,
      stage === StagesEnum.STAGE_1 && styles.ProgressBar__stage1,
      stage === StagesEnum.STAGE_2 && styles.ProgressBar__stage2
    )}>
      <div className={styles.Line} />
      <div className={styles.Line} />
      <div className={styles.Progress} />
    </div>
  )
}
