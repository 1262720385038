import { FC, ReactNode } from 'react'
import cn from 'classnames'

import { Flex } from 'src/shared/ui/Flex'

import styles from './ValueField.module.scss'

interface ValueFieldPropsType {
  label?: string
  children?: ReactNode
  classname?: string
}

export const ValueField: FC<ValueFieldPropsType> = ({ label, children, classname }) => {
  return (
    <Flex className={styles.ValueField} flexDirection="column">
      {label && <span className={styles.Label}>{label}</span>}
      {children && <span className={cn(styles.Value, classname)}>{children}</span>}
    </Flex>
  )
}
