import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse } from 'src/shared/api'

export const useConfirmPaymentMutation = (orderId: number, responseId: number) => {
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>
  >({
    mutationFn: () => AdminAPI.api.orderResponsesControllerPaymentConfirm(orderId, responseId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders', 'my', orderId] })
    },
    onError: (data) => {
      toast.error(data.error?.message || 'Ошибка при выполнении запроса')
    },
  })
}
