import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { AdminAPI, BlacklistResponseDto, CommonMessageResponse, HttpResponse, PaginateResultDto } from 'src/shared/api'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { TextInputField } from 'src/shared/ui/TextInputField'

import styles from './CabinetBlacklist.module.scss'

export const CabinetBlacklist: FC = () => {
  const [search, setSearch] = useState('')
  const [result, setResult] = useState<BlacklistResponseDto[]>([])

  const checkBlacklistMutation = useMutation<
    HttpResponse<
      PaginateResultDto & {
        data?: BlacklistResponseDto[]
      },
      CommonMessageResponse | void
    >,
    HttpResponse<
      PaginateResultDto & {
        data?: BlacklistResponseDto[]
      },
      CommonMessageResponse | void
    >,
    string
  >({
    mutationFn: (search) => AdminAPI.api.blacklistControllerFindAll({ page: 1, take: 50, search }),
  })
  const onSubmit = useCallback(async () => {
    const checkBlacklistToastId = toast.loading('Проверяем блогера')
    setResult([])

    checkBlacklistMutation.mutate(search, {
      onSuccess: (result) => {
        const bloggers = result.data.data as BlacklistResponseDto[]

        if (bloggers.length < 1) {
          toast.error('Блогер не найден', { id: checkBlacklistToastId })
          return
        }

        const isMatch = bloggers.some((item: BlacklistResponseDto) => {
          const url = item.instagram
          const username = url?.split('instagram.com/')[1]?.split('/')[0]
          return username === search
        })

        if (isMatch) {
          toast.success('Данный пользователь обнаружен в нашем списке - будьте осторожнее', {
            id: checkBlacklistToastId,
          })
        } else {
          toast.error('Данный инстаграм логин не был обнаружен в базе, но обратите внимание на ближайшие совпадения', {
            id: checkBlacklistToastId,
          })
          setResult(bloggers.slice(0, 3))
        }
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при проверке блогера', {
          id: checkBlacklistToastId,
        })
      },
    })
  }, [search])
  return (
    <Flex className={styles.Layout} flexDirection="column">
      <Flex flexDirection="column" gap={4}>
        <h1 className={styles.Title}>Проверка блогера</h1>
        <span className={styles.Description}>Временно бесплатно</span>
        <p className={styles.Text}>
          Мы регулярно собираем информацию про недоброкачественных блогеров из открытых источников, вы можете проверить
          инстаграм блогера введя его в поле ниже
        </p>
      </Flex>

      <Flex gap={24} alignItems="center">
        <TextInputField
          id="search"
          kind="cabinet"
          placeholder="Введите instagram блогера"
          className={styles.Search}
          withHiddenLabel
          onChange={(event) => {
            setResult([])
            setSearch(event.target.value)
          }}
        />
        <CabinetButton onClick={onSubmit}>Проверить</CabinetButton>
      </Flex>
      {result.length >= 1 && (
        <Flex flexDirection="column" gap={8}>
          <p className={styles.ResultTitle}>Найденые совпадения:</p>
          {result.map((item) => {
            return <div className={styles.Blogger}>{item.instagram}</div>
          })}
        </Flex>
      )}
    </Flex>
  )
}
