import { useLayoutEffect } from "react"
import { useLocation } from "react-router"

export const useScrollToTop = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    // @ts-ignore
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [location.pathname])
}
