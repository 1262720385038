import React, { FC } from 'react'
import cn from 'classnames'
import { BASE_ROUTE } from 'src/shared/config/consts'
import logo from 'src/shared/assets/logo.svg'

import styles from './Logo.module.scss'
import { Link } from 'react-router-dom'

interface LogoPropsType {
  className?: string
}

export const Logo: FC<LogoPropsType> = ({ className }) => {
  return (
    <Link to={BASE_ROUTE} aria-label="Главная">
      <img className={cn(styles.Logo, className)} src={logo} alt="Логотип: Marketiq" />
    </Link>
  )
}
