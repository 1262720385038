import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import { useUpload } from 'src/shared/model/useUpload'

import { CustomReportersResponseDto, UpdateOrderResponseDto } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { TextInputField } from 'src/shared/ui/TextInputField'
import { Uploader } from 'src/shared/ui/Uploader'
import { ValueField } from 'src/shared/ui/ValueField'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { Block } from '../Block/Block'
import { Section } from '../Section/Section'

import styles from './CompletedSecondStepStage.module.scss'

interface CompletedSecondStepStagePropsType {
  orderId: number
  responseId: number
  customReporters: CustomReportersResponseDto
  startTime: string
}

type UploadTypes = 'reviewsScreenshotImage' | 'storiesScreenshotImage' | 'statisticsScreenshotImage' | 'realsLink'

export const CompletedSecondStepStage: FC<CompletedSecondStepStagePropsType> = ({
  orderId,
  responseId,
  customReporters,
  startTime,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const [formData, setFormData] = useState({
    reviewsScreenshotImage: null as File | null,
    storiesScreenshotImage: null as File | null,
    statisticsScreenshotImage: null as File | null,
    realsLink: customReporters.realsLink || '',
  })

  const { mutate: updateOrderResponse, isPending: isUpdateOrderResponseLoading } = useUpdateOrderResponseMutation(
    orderId,
    responseId,
  )

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const handleSendData = useCallback(
    async (data: File | string | null, type: UploadTypes) => {
      const updateOrderToastId = toast.loading(type === 'realsLink' ? 'Загружаем данные' : 'Загружаем файл')
      try {
        setIsUploading(true)

        let uploadedData
        if (data instanceof File) {
          uploadedData = await upload(data)
        }
        setIsUploading(false)

        setFormData((prev) => ({
          ...prev,
          [type]: data,
        }))

        const updateOrderResponseData = {
          step: 3,
          data: {
            reviewsScreenshot: type === 'reviewsScreenshotImage' ? uploadedData : undefined,
            realsLink: type === 'realsLink' ? (data as string) : undefined,
            storiesScreenshot: type === 'storiesScreenshotImage' ? uploadedData : undefined,
            statisticsScreenshot: type === 'statisticsScreenshotImage' ? uploadedData : undefined,
          } as UpdateOrderResponseDto,
        }

        updateOrderResponse(updateOrderResponseData, {
          onSuccess: () => {
            toast.success(type === 'realsLink' ? 'Данные загружены' : 'Файл загружен', { id: updateOrderToastId })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Ошибка при загрузке', { id: updateOrderToastId })
          },
        })
      } catch (error) {
        toast.error(String(error) || 'Ошибка при загрузке файлов', { id: updateOrderToastId })
        console.error(error)
        setIsUploading(false)
      }
    },
    [upload, updateOrderResponse],
  )

  const fieldsConfig = [
    {
      id: 'reviewsScreenshotImage',
      label: 'Прикрепите скрин отзыва:',
      placeholder: 'Загрузить скриншот',
      file: formData.reviewsScreenshotImage || customReporters.reviewsScreenshot,
    },
    {
      id: 'storiesScreenshotImage',
      label: 'Прикрепите скриншот сторис:',
      placeholder: 'Загрузить скриншот',
      file: formData.storiesScreenshotImage || customReporters.storiesScreenshot,
    },
    {
      id: 'statisticsScreenshotImage',
      label: 'Прикрепите скриншот статистики на сторис:',
      placeholder: 'Загрузить скриншот',
      file: formData.statisticsScreenshotImage || customReporters.statisticsScreenshot,
    },
  ]

  return (
    <Section title="3 раздел. Реклама">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            Оставьте развернутый и положительный отзыв с фото и видео на маркетплейсе. Снимите reels и stories на этот
            товар с учетом ТЗ заказчика. Сделайте скриншот через 24 ч. статистики вашей stories. Когда соберете все
            данные, загрузите их полностью в этот раздел
          </ValueField>

          {fieldsConfig.map(({ id, label, placeholder, file }) => (
            <Field key={id} id={id} label={label} kind="cabinet">
              <Uploader
                type="image"
                file={file}
                onChange={(image) => handleSendData(image, id as UploadTypes)}
                isDisabled={isLoading}
                placeholder={placeholder}
              />
            </Field>
          ))}

          <Flex alignItems="flex-end" gap={20}>
            <TextInputField
              id="realsLink"
              label="Прикрепите ссылку на рилс:"
              kind="cabinet"
              value={formData.realsLink}
              onChange={(event) => {
                setFormData((prev) => ({
                  ...prev,
                  realsLink: event.target.value,
                }))
              }}
              className={styles.RealsInput}
            />
            <CabinetActionButton
              kind="ghost"
              className={styles.SendButton}
              disabled={isLoading}
              onClick={() => {
                if (!isLoading) {
                  handleSendData(formData.realsLink, 'realsLink')
                }
              }}
              type="button"
            >
              Отправить
            </CabinetActionButton>
          </Flex>
          <RemainingTime
            time={startTime}
            duration={168}
            label="Оставшееся время:"
            error="Время вышло"
            className={styles.RemainingTime}
          />
        </Block>
      </Flex>
    </Section>
  )
}
