import { useQuery } from "@tanstack/react-query"
import { AdminAPI, TopicResponseDto } from "../api"
import { useUserInfo } from "./useUser"
import { RoleType } from "../config/types"

export const useTopics = (): TopicResponseDto[] => {
  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const queryKey = ['topics']

  const { data: topicsData } = useQuery({
    queryKey,
    queryFn: AdminAPI.api.topicsControllerFindAll,
    enabled: role !== RoleType.SUPER_ADMIN
  })
  const topics = (topicsData?.data ?? []) as TopicResponseDto[]

  const { data: superAdminTopicsData } = useQuery({
    queryKey,
    // TODO Кажется здесь не нужна пагинация
    queryFn: () => AdminAPI.api.managementControllerFindAllTopics({ page: 1, take: 200 }),
    enabled: role === RoleType.SUPER_ADMIN
  })
  // @ts-ignore
  const superAdminTopics = (superAdminTopicsData?.data?.topics ?? []) as TopicResponseDto[]

  return role === RoleType.SUPER_ADMIN ? superAdminTopics : topics
}
