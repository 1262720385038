import React, { FC } from 'react'
import cn from 'classnames'

import { Flex } from 'src/shared/ui/Flex'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { TopicsMultiSelectFormField } from 'src/shared/ui/TopicsMultiSelectFormField'

import { useBloggerForm } from '../../model/context'

import styles from '../BloggerForm/BloggerForm.module.scss'

interface BlogInfoProps {
  showInstagram?: boolean
  showFollowers?: boolean
  showCoverages?: boolean
  showTopics?: boolean
  className?: string
}

export const BlogInfo: FC<BlogInfoProps> = ({ showInstagram, showFollowers, showCoverages, showTopics, className }) => {
  const { isLoading } = useBloggerForm()

  return (
    <Flex className={cn(styles.Block, className)} flexDirection="column" gap={16}>
      <h2 className={cn(styles.Title, styles.BlogInfo__title)}>Данные блога</h2>
      {showInstagram && (
        <TextInputFormField
          id="instagram"
          name="instagram"
          label="Instagram"
          placeholder="Ссылка"
          kind="cabinet"
          disabled={isLoading}
        />
      )}
      {showFollowers && (
        <NumericInputFormField
          id="followersCount"
          name="followersCount"
          label="Количество подписчиков"
          placeholder="Укажите количество ваших подписчиков"
          kind="cabinet"
          disabled={isLoading}
        />
      )}

      {showCoverages && (
        <NumericInputFormField
          id="coveragesCount"
          name="coveragesCount"
          label="Количество охватов"
          placeholder="Укажите количество ваших охватов"
          kind="cabinet"
          disabled={isLoading}
        />
      )}
      {showTopics && (
        <TopicsMultiSelectFormField
          id="topics"
          name="topics"
          fieldName="topicId"
          label="Тематика блога"
          placeholder="Выберите тематику из списка"
          addButtonText="Добавить тематику"
          disabled={isLoading}
          kind="cabinet"
        />
      )}
    </Flex>
  )
}
