import React, { FC, ReactNode } from 'react'
import { Icon } from 'src/shared/ui/Icon'

import styles from './TelegramLink.module.scss'

interface TelegramLinkPropsType {
  link: string
  children: ReactNode
}

export const TelegramLink: FC<TelegramLinkPropsType> = ({ link, children }) => {
  return (
    <a className={styles.TelegramLink} href={link} target="_blank" rel="noreferrer">
      <Icon name='telegram'/>{children}
    </a>
  )
}
