import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Stages.module.scss'

interface StagesPropsType {
  className: string
}

export const Stages: FC<StagesPropsType> = ({ className }) => {
  return (
    <section className={cn(styles.Stages, className)} aria-labelledby="stages-title">
      <h2 className={styles.Title} id="stages-title">
        Этапы работы с платформой
      </h2>
      <ol className={styles.List}>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>01</div>
          <div className={styles.ItemText}>Находите интересующий вас товар</div>
        </li>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>02</div>
          <div className={styles.ItemText}>Изучаете задание по рекламе</div>
        </li>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>03</div>
          <div className={styles.ItemText}>Оставляете заявку на рекламную интеграцию</div>
        </li>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>04</div>
          <div className={styles.ItemText}>Получаете деньги для покупки товара и делаете заказ</div>
        </li>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>05</div>
          <div className={styles.ItemText}>
            Получаете товар и делаете рекламу в сторис с отметкой карточки на маркетплейсе
          </div>
        </li>
        <li className={styles.Item}>
          <div className={styles.ItemNumber}>06</div>
          <div className={styles.ItemText}>
            Просто добавь ссылку на истории с визитом, а через сутки загрузи статистику
          </div>
        </li>
      </ol>
    </section>
  )
}
