import React, { FC, useCallback, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'

import { AdminAPI, CommonMessageResponse, HttpResponse, ProductResponseDto, UpdateProductDto } from 'src/shared/api'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { useUpload } from 'src/shared/model/useUpload'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { ProductForm as BaseProductForm, ProductFormFields, useProductForm } from 'src/widgets/ProductForm'

import styles from './ProductForm.module.scss'

interface ProductFormPropsType {
  productId: number
  product: ProductResponseDto
}

export const ProductForm: FC<ProductFormPropsType> = ({ productId, product }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const { methods, isInputsInvalid } = useProductForm(product)
  const { handleSubmit } = methods

  const updateProductMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateProductDto
  >({
    mutationFn: (data: UpdateProductDto) => AdminAPI.api.productsControllerUpdate(productId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['products', productId] }),
  })
  const isLoading = updateProductMutation.isPending || isUploading

  const onSubmit = useCallback(
    async (data: ProductFormFields) => {
      const updateProductToastId = toast.loading('Загружаем изображение')
      try {
        let photoUrl: string
        if (typeof data.photo === 'string') {
          photoUrl = data.photo
        } else {
          setIsUploading(true)
          photoUrl = await upload(data.photo)
          setIsUploading(false)
        }

        toast.loading('Проверяем данные', {
          id: updateProductToastId,
        })

        const updateProductDto: UpdateProductDto = {
          topics: data.topics.filter((item) => item.topicId).map((item) => ({ topicId: Number(item.topicId) })),
          marketplaceUrl: data.marketplaceUrl,
          marketplaceId: Number(data.marketplaceId),
          title: data.title,
          article: data.article,
          price: Number(data.price),
          photoUrl,
        }

        updateProductMutation.mutate(updateProductDto, {
          onSuccess: () => {
            toast.success('Товар успешно обновлён!', {
              id: updateProductToastId,
            })
            navigate(CABINET_PRODUCTS_ROUTE, { replace: true })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Ошибка при обновлении товара', {
              id: updateProductToastId,
            })
          },
        })
      } catch (error) {
        toast.error(String(error) || 'Ошибка при обновлении товара', { id: updateProductToastId })
        console.error(error)
        setIsUploading(false)
      }
    },
    [updateProductMutation, product, navigate],
  )

  return (
    <FormProvider {...methods}>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <BaseProductForm isDisabled={isLoading} />
        <CabinetButton className={styles.CabinetButton} disabled={isInputsInvalid}>
          Сохранить товар
        </CabinetButton>
      </form>
    </FormProvider>
  )
}
