import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'

import { Avatar } from 'src/shared/ui/Avatar'

import styles from './Blogger.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export interface BloggerPropsType {
  userId?: number
  firstName: string
  names?: string
  avatar?: string
  instagram?: string
  followersCount?: number
  coveragesCount?: number
  topics?: string[]
  children?: ReactNode
  tgUsername?: string
  reportId?: number
  onClick?(): void
  comments?: { bloggers: string[]; customers: string[] }
}

export const Blogger: FC<BloggerPropsType> = ({
  userId,
  firstName,
  names,
  avatar,
  instagram,
  followersCount,
  coveragesCount,
  topics,
  onClick,
  tgUsername,
  reportId,
  children,
}) => {
  const formattedUsername = tgUsername?.startsWith('@') ? tgUsername.slice(1) : tgUsername
  return (
    <div className={cn(styles.Blogger, onClick && styles.withPointerCursor)} onClick={onClick}>
      <div className={styles.Blogger__wrap}>
        {reportId && <div className={styles.Id}>{reportId}</div>}

        {userId ? (
          <Link to={`${CABINET_USERS_ROUTE}/${userId}`} className={styles.Header}>
            <Avatar className={styles.Image} avatar={avatar} firstName={names || firstName} />
            <div className={styles.Info}>
              <span className={styles.Name}>{names || firstName}</span>
              <span className={styles.instagram}>{instagram}</span>
            </div>
          </Link>
        ) : (
          <div className={styles.Header}>
            <Avatar className={styles.Image} avatar={avatar} firstName={names || firstName} />
            <div className={styles.Info}>
              <span className={styles.Name}>{names || firstName}</span>
              <span className={styles.instagram}>{instagram}</span>
            </div>
          </div>
        )}
        <div className={styles.Body}>
          <div className={styles.Item}>
            <span className={styles.Title}>Подписчики:</span>
            <span className={styles.Value}>
              {followersCount !== undefined ? numberFormat.format(followersCount) : '-'}
            </span>
          </div>
          <div className={styles.Item}>
            <span className={styles.Title}>Средний охват:</span>
            <span className={styles.Value}>
              {coveragesCount !== undefined ? numberFormat.format(coveragesCount) : '-'}
            </span>
          </div>
          {/* <div className={styles.Item}>
            <span className={styles.Title}>Тематика:</span>
            {topics && topics.length > 0 ? (
              <div className={styles.Tags}>
                {topics.map((item) => (
                  <div key={item} className={styles.Tag}>
                    {item}
                  </div>
                ))}
              </div>
            ) : (
              '-'
            )}
          </div> */}
          <div className={styles.Item}>
            <span className={styles.Title}>Telegram:</span>
            {tgUsername ? (
              <a
                href={`https://t.me/${formattedUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.Value, styles.TelegramLink)}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {'@' + tgUsername}
              </a>
            ) : (
              <span className={styles.Value}>Нет</span>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
