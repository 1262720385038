import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Select, SelectPropsType } from '../Select'

export type SelectFieldPropsType = Omit<FieldPropsType, 'children'> & SelectPropsType

export const SelectFieldWithRef = (
  { className, label, id, error, isRequired, withHiddenLabel, ...rest }: SelectFieldPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field
      className={className}
      kind="cabinet"
      label={label}
      id={id}
      error={error}
      isRequired={isRequired}
      withHiddenLabel={withHiddenLabel}
    >
      <Select id={id} isInvalid={Boolean(error)} ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const SelectField = forwardRef(SelectFieldWithRef)
