import React, { FC } from 'react'
import { RoleType } from 'src/shared/config/types'
import { useUserInfo } from 'src/shared/model/useUser'

interface SwitchPropsType {
  BloggerComponent?: FC,
  CustomerComponent?: FC,
  AdminComponent?: FC,
}

export const Switch: FC<SwitchPropsType> = ({ BloggerComponent, CustomerComponent, AdminComponent }) => {
  const { user } = useUserInfo()

  if (user?.role === RoleType.CUSTOMER && CustomerComponent) {
    return <CustomerComponent />
  }

  if (user?.role === RoleType.BLOGER && BloggerComponent) {
    return <BloggerComponent />
  }

  if (user?.role === RoleType.SUPER_ADMIN && AdminComponent) {
    return <AdminComponent />
  }

  return null
}
