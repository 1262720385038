import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Textarea, TextareaPropsType } from '../Textarea'

export type TextareaFieldPropsType = Omit<FieldPropsType, 'children'> & TextareaPropsType

const TextareaFieldWithRef = (
  { className, kind, label, id, error, isRequired, ...rest }: TextareaFieldPropsType,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <Textarea id={id} isInvalid={Boolean(error)} kind={kind} ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const TextareaField = forwardRef(TextareaFieldWithRef)
