import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'

import { AdminAPI, CommonMessageResponse, HttpResponse } from 'src/shared/api'
import { CABINET_PROFILE_ROUTE } from 'src/shared/config/consts'
import { BloggerStatusType, CollectionType } from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'
import { getPriceRange } from 'src/shared/lib/getPriceRange'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { Modal } from 'src/shared/ui/Modal'
import { Textarea } from 'src/shared/ui/Textarea'
import { ValueField } from 'src/shared/ui/ValueField'

import styles from './OrderModal.module.scss'

interface OrderModalPropsType {
  orderId: number
  onClose(): void
}

export const OrderModal: FC<OrderModalPropsType> = ({ orderId, onClose }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>()

  const queryClient = useQueryClient()

  const profile = useBloggerProfile()

  const { data: ordersData } = useQuery({
    queryKey: ['orders', orderId],
    queryFn: () => AdminAPI.api.ordersControllerFindOne(orderId),
  })

  const order = useMemo(() => ordersData?.data, [ordersData])

  const createOrderResponseMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: AdminAPI.api.orderResponsesControllerCreate,
  })

  const isLoading = createOrderResponseMutation.isPending

  const handleCreateOrderClick = useCallback(() => {
    createOrderResponseMutation.mutate(orderId, {
      onSuccess: () => {
        onClose()
        toast.success(
          order?.collection === CollectionType.AUTOMATIC
            ? 'Теперь вы участвуете в задании'
            : 'Заявка на участие отправлена',
        )
        queryClient.invalidateQueries({ queryKey: ['orders'], exact: true })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка')
      },
    })
  }, [createOrderResponseMutation, queryClient, order, orderId])

  const handleOnVerificationClick = useCallback(() => navigate(CABINET_PROFILE_ROUTE), [navigate])

  if (!order || !profile) {
    return null
  }

  return (
    <Modal className={styles.OrderModal} title={order.title!} onClose={onClose}>
      <Flex className={styles.Body} flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <span className={cn(styles.Marketplace, getMarketplaceClass(order.marketplaceName))}>
            {order.marketplaceName}
          </span>
          <Collection className={styles.Collection} collection={order.collection!} />
        </Flex>
        <ValueField label="Артикул">{order.article}</ValueField>
        <ValueField label="Цена для фильтра:">{getPriceRange(order.price!)}</ValueField>
        <ValueField label="Ключевое слово для выкупа товара:">{order.keyWord}</ValueField>
        <ValueField label="Требования к отзыву на маркетплейсе:">{order.commentMarketplace}</ValueField>
        <ValueField label="Требования к рекламной интеграции:">{order.commentIntegration} </ValueField>
        {profile.status === BloggerStatusType.ACTIVATED && (
          <>
            <Textarea
              kind="cabinet"
              placeholder="При желании можете оставить сообщение селлеру"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <CabinetActionButton
              className={styles.CabinetActionButton}
              disabled={isLoading}
              onClick={handleCreateOrderClick}
            >
              {order.collection === CollectionType.AUTOMATIC ? 'Участвовать' : 'Подать заявку на участие'}
            </CabinetActionButton>
          </>
        )}
        {profile.status === BloggerStatusType.NOT_ACTIVATED && !profile.isFullInfo && (
          <CabinetActionButton className={styles.CabinetActionButton} onClick={handleOnVerificationClick}>
            Пройти верификацию
          </CabinetActionButton>
        )}
      </Flex>
    </Modal>
  )
}
