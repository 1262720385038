import React, { FC, useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Field } from 'src/shared/ui/Field'
import { SelectFormField } from 'src/shared/ui/SelectFormField'

import { Icon } from '../Icon'
import { SelectOptionType } from '../Select'

import styles from './MultiSelectFormField.module.scss'

export interface MultiSelectFormFieldPropsType {
  id: string
  name: string
  fieldName: string
  label: string
  placeholder: string
  options: SelectOptionType[]
  addButtonText: string
  limit?: number
  disabled?: boolean
  kind?: 'cabinet' | 'smallCabinet'
}

export const MultiSelectFormField: FC<MultiSelectFormFieldPropsType> = ({
  id,
  name,
  fieldName,
  label,
  placeholder,
  options,
  addButtonText,
  limit = 3,
  disabled = false,
  kind = 'cabinet',
}) => {
  const { control, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const selectedValues = watch(name)

  const handleAddButtonClick = useCallback(() => {
    append({ [fieldName]: undefined })
  }, [fields, append])

  return (
    <Field id={id} label={label} kind={kind}>
      <div className={styles.MultiSelectFormField}>
        {fields.map((item, index) => {
          const currentValues = selectedValues
            .filter((field: { [key: string]: unknown }, i: number) => i !== index)
            .map((field: { [key: string]: unknown }) => field[fieldName])

          const filteredOptions = options.filter((option) => !currentValues.includes(option.value))

          return (
            <div className={styles.Row} key={item.id}>
              <SelectFormField
                className={styles.SelectFormField}
                id={`${name}.${index}.${fieldName}`}
                name={`${name}.${index}.${fieldName}`}
                options={filteredOptions}
                isInvalid={false}
                placeholder={placeholder}
                withHiddenLabel
                disabled={disabled}
              />
              {fields.length !== 1 && !disabled && (
                <Icon
                  className={styles.CloseIcon}
                  name="close"
                  onClick={() => {
                    if (!disabled) {
                      remove(index)
                    }
                  }}
                />
              )}
            </div>
          )
        })}
        {fields.length < limit && !disabled && (
          <button className={styles.AddButton} type="button" onClick={handleAddButtonClick} disabled={disabled}>
            {addButtonText}
          </button>
        )}
      </div>
    </Field>
  )
}
