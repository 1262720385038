import React, { FC, useState } from 'react'

import { ResetBodyDto } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { Modal } from 'src/shared/ui/Modal'
import { Textarea } from 'src/shared/ui/Textarea'

import styles from './OrderProblemModal.module.scss'

interface OrderProblemModalProps {
  onClose: () => void
  onSubmit: ({ comment }: ResetBodyDto) => void
}

export const OrderProblemModal: FC<OrderProblemModalProps> = ({ onClose, onSubmit }) => {
  const [commentValue, setCommentValue] = useState('')

  const handleOnClick = () => {
    onSubmit({ comment: commentValue })
  }

  return (
    <Modal className={styles.Modal} title="Проблема с заказом" size="small" onClose={onClose}>
      <Flex flexDirection="column" gap={16}>
        <Textarea
          kind="cabinet"
          placeholder="Укажите комментарий"
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
          rows={5}
        />
        <Flex className={styles.Buttons} flexDirection="column" alignSelf="center" gap={8}>
          <CabinetActionButton kind="danger" onClick={handleOnClick} disabled={commentValue.length < 1}>
            Отправить
          </CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onClose}>
            Отмена
          </CabinetActionButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
