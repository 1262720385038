import React, { FC, useState } from 'react'
import { Modal } from 'src/shared/ui/Modal'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { BloggerType } from 'src/shared/config/types'
import { Avatar } from 'src/shared/ui/Avatar'
import { Textarea } from 'src/shared/ui/Textarea'

import styles from './RejectModal.module.scss'

interface RejectModalPropsType {
  blogger: BloggerType
  onSendForImprovement(comment?: string): void
  onBlock(): void
  onClose(): void
}

export const RejectModal: FC<RejectModalPropsType> = ({ blogger, onSendForImprovement, onBlock, onClose }) => {
  const [commentValue, setCommentValue] = useState('')

  const { avatar, firstName, instagram } = blogger

  return (
    <Modal className={styles.RejectModal} title="Отклонить блогера?" size="small" onClose={onClose}>
      <Flex flexDirection="column" gap={16}>
        <Flex gap={16}>
          <Avatar className={styles.Avatar} avatar={avatar} firstName={firstName} />
          <Flex flexDirection="column" gap={4}>
            <span className={styles.FirstName}>{firstName}</span>
            <span className={styles.instagram}>{instagram}</span>
          </Flex>
        </Flex>
        <Textarea
          kind="cabinet"
          placeholder="Укажите комментарий"
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
        />
        <Flex className={styles.Buttons} flexDirection="column" alignSelf="center" gap={8}>
          <CabinetActionButton kind="primary" onClick={() => onSendForImprovement(commentValue)}>
            Отправить на доработку
          </CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onBlock}>
            Заблокировать
          </CabinetActionButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
