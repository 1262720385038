import React, { FC, ReactNode } from 'react'

import { RoleType } from 'src/shared/config/types'

import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'

interface RespondedBloggerPropsType extends BloggerPropsType {
  role: RoleType
  onConfirmClick?(): void
  onRejectClick?(): void
  children?: ReactNode
}

export const RespondedBlogger: FC<RespondedBloggerPropsType> = ({
  role,
  onConfirmClick,
  onRejectClick,
  children,
  ...props
}) => {
  return (
    <Blogger {...props}>
      {role === RoleType.CUSTOMER && (
        <Flex flexDirection="column" alignItems="center" gap={8}>
          <CabinetActionButton onClick={onConfirmClick}>Подтвердить участника</CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onRejectClick}>
            Отклонить участника
          </CabinetActionButton>
        </Flex>
      )}
      {children}
    </Blogger>
  )
}
