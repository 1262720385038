import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, ResetBodyDto } from 'src/shared/api'

export const useResetResponseMutation = (orderId: number) => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    ResetBodyDto
  >({
    mutationFn: (data) => AdminAPI.api.orderResponsesControllerResetResponse(orderId, data),
  })
}
