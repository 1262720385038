import { createContext, useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { BloggerFormFields } from '../ui/BloggerForm/BloggerForm'

interface BloggerFormContextProps {
  methods: UseFormReturn<BloggerFormFields, unknown, undefined>
  isLoading: boolean
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
}

export const BloggerFormContext = createContext<BloggerFormContextProps | null>(null)

export const useBloggerForm = () => {
  const context = useContext(BloggerFormContext)
  if (!context) {
    throw new Error('useBloggerForm должен использоваться внутри BloggerFormProvider')
  }
  return context
}
