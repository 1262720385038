import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Accordion } from 'src/shared/ui/Accordion'
import { Flex } from 'src/shared/ui/Flex'
import { INTERACTION_STAGES } from '../../config/consts'
import { CABINET_ROUTE } from 'src/shared/config/consts'
import { PageTitle } from 'src/shared/ui/PageTitle'

import styles from './CabinetOrderQuide.module.scss'

export const CabinetOrderQuide: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_ROUTE), [navigate])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Этапы взаимодействия</PageTitle>
      </Flex>
      <Accordion kind="cabinet" type="multiply" data={INTERACTION_STAGES} />
    </CabinetPageLayout>
  )
}
