import React, { FC, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import cn from 'classnames'

import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, EditOrderByAdminDto, HttpResponse } from 'src/shared/api'
import { OrderByCustomerResponseDto, RoleType } from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'
import { getPriceRange } from 'src/shared/lib/getPriceRange'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Modal } from 'src/shared/ui/Modal'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { ValueField } from 'src/shared/ui/ValueField'

import styles from './ReferenceModal.module.scss'

interface ReferenceModalProps {
  order: OrderByCustomerResponseDto
  onClose: () => void
}

export const ReferenceModal: FC<ReferenceModalProps> = ({ order, onClose }) => {
  const { user } = useUserInfo()

  if (user?.role !== RoleType.SUPER_ADMIN) {
    return (
      <Modal title="Техническое задание" onClose={onClose}>
        <div className={styles.Block}>
          <ValueField
            label="Маркетплейс:"
            classname={cn(styles.marketplaceName, getMarketplaceClass(order.marketplaceName))}
          >
            {order.marketplaceName}
          </ValueField>
          <ValueField label="Артикул:">{order.article}</ValueField>
          <ValueField label="Цена для фильтра:">{getPriceRange(order.price!)}</ValueField>
          <ValueField label="Ключевое слово для выкупа товара:">{order.keyWord}</ValueField>
          <ValueField label="Требования к отзыву на маркетплейсе:">{order.commentMarketplace}</ValueField>
          <ValueField label="Требования к рекламной интеграции:">{order.commentIntegration}</ValueField>
        </div>
      </Modal>
    )
  }

  const methods = useForm<EditOrderByAdminDto>({
    defaultValues: {
      commentMarketplace: order.commentMarketplace || '',
      commentIntegration: order.commentIntegration || '',
      keyWord: order.keyWord || '',
    },
  })
  const { handleSubmit } = methods

  const updateReferenceMutation = useMutation<
    HttpResponse<any, void | CommonMessageResponse>,
    HttpResponse<any, void | CommonMessageResponse>,
    EditOrderByAdminDto
  >({
    mutationFn: (data) => AdminAPI.api.managementControllerEditOrder(order.orderId, data),
  })

  const onSubmit = useCallback(async (data: EditOrderByAdminDto) => {
    const updateReferenceToastId = toast.loading('Проверяем данные')

    updateReferenceMutation.mutate(data, {
      onSuccess: () => {
        toast.success('Данные обновлены! ✨', {
          id: updateReferenceToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при обновлении личной информации', {
          id: updateReferenceToastId,
        })
      },
    })
  }, [])

  const isLoading = updateReferenceMutation.isPending

  return (
    <Modal title="Техническое задание" onClose={onClose}>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.Block}>
            <ValueField
              label="Маркетплейс:"
              classname={cn(styles.marketplaceName, getMarketplaceClass(order.marketplaceName))}
            >
              {order.marketplaceName}
            </ValueField>
            <ValueField label="Артикул:">{order.article}</ValueField>
            <ValueField label="Цена для фильтра:">{getPriceRange(order.price!)}</ValueField>
            <TextareaFormField
              rows={3}
              id="keyWord"
              name="keyWord"
              label="Ключевое слово для выкупа товара:"
              kind="cabinet"
            />
            <TextareaFormField
              rows={3}
              id="commentMarketplace"
              name="commentMarketplace"
              label="Требования к отзыву на маркетплейсе:"
              kind="cabinet"
            />
            <TextareaFormField
              rows={3}
              id="commentIntegration"
              name="commentIntegration"
              label="Требования к рекламной интеграции:"
              kind="cabinet"
            />
          </div>
          <CabinetActionButton disabled={isLoading} className={styles.Button}>
            Сохранить
          </CabinetActionButton>
        </form>
      </FormProvider>
    </Modal>
  )
}
