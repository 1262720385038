import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'

import { BloggerCollection } from '../BloggerCollection/BloggerCollection'
import { IntegrationsBlock } from '../IntegrationsBlock/IntegrationsBlock'
import { TariffsBlock } from '../TariffsBlock/TariffsBlock'

import styles from './CabinetPayment.module.scss'

export const CabinetPayment: FC = () => {
  return (
    <div className={styles.CabinetPageLayout}>
      <TariffsBlock />
      <Flex flexDirection="column" gap={18} flexBasis={1} flexGrow={1}>
        <IntegrationsBlock />
        <BloggerCollection />
      </Flex>
    </div>
  )
}
