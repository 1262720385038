import React, { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Input.module.scss'

export interface InputPropsType extends InputHTMLAttributes<HTMLInputElement> {
  kind?: 'landing' | 'cabinet' | 'smallCabinet'
  isInvalid?: boolean
  isRequired?: boolean
  postfix?: ReactNode
}

const InputWithRef = (
  { className, kind = 'landing', isInvalid = false, isRequired, postfix, ...rest }: InputPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  const withPostfix = postfix && kind === 'cabinet'

  return (
    <label
      className={cn(
        styles.Container,
        isInvalid && styles.isInvalid,
        withPostfix && styles.withPostfix,
        kind === 'landing' && styles.Container__landing,
        (kind === 'cabinet' || kind === 'smallCabinet') && styles.Container__cabinet,
        kind === 'smallCabinet' && styles.Container__cabinet_small,
        className,
      )}
    >
      <input className={styles.Input} ref={forwardedRef} required={isRequired} type="text" {...rest} />
      {withPostfix && postfix}
    </label>
  )
}

export const Input = forwardRef(InputWithRef)
