import React, { FC } from 'react'

import { Logo } from 'src/shared/ui/Logo'
import { LOGIN_ROUTE } from 'src/shared/config/consts'
import { LandingButton } from 'src/shared/ui/LandingButton'

import styles from './Header.module.scss'

export interface HeaderPropsType {
  arrowButtonText: string
  arrowButtonLink: string
}

export const Header: FC<HeaderPropsType> = ({ arrowButtonText, arrowButtonLink }) => {
  return (
    <header className={styles.Header}>
      <h1 className="visibility-hidden">Marketiq</h1>
      <div className={styles.Container}>
        <Logo />
        <nav className={styles.Buttons}>
          <LandingButton kind="ghost" icon="arrow" to={arrowButtonLink}>
            {arrowButtonText}
          </LandingButton>
          <LandingButton to={LOGIN_ROUTE}>Войти</LandingButton>
        </nav>
      </div>
    </header>
  )
}
