import { FC, ReactNode } from 'react'

import styles from './PageTitle.module.scss'

interface PageTitlePropsType {
  children: ReactNode
}

export const PageTitle: FC<PageTitlePropsType> = ({ children }) => {
  return <h1 className={styles.PageTitle}>{children}</h1>
}
