import React, { FC, ReactNode } from 'react'
import { HeaderButtons, HeaderButtonsPropsType } from '../HeaderButtons/HeaderButtons'
import { Description, DescriptionPropsType } from '../Description/Description'

import styles from './Main.module.scss'

interface MainPropsType extends DescriptionPropsType, Pick<HeaderButtonsPropsType, 'type'> {
  title: ReactNode
  children?: ReactNode
}

export const Main: FC<MainPropsType> = ({ title, descriptionTitle, descriptionText, type, children }) => {
  return (
    <section className={styles.Main}>
      <div className={styles.Container}>
        <div className={styles.FirstBlock}>
          <strong className={styles.Title}>{title}</strong>
          <HeaderButtons className={styles.HeaderButtons} type={type} />
        </div>
        <div className={styles.SecondBlock}>
          <Description descriptionTitle={descriptionTitle} descriptionText={descriptionText} />
          {children}
          <HeaderButtons className={styles.HeaderButtons} type={type} />
        </div>
      </div>
    </section>
  )
}
