import React, { FC, useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, ExcelReportResponseDto, HttpResponse } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { Task } from 'src/shared/ui/Task'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './AdminCabinetTasks.module.scss'

export const AdminCabinetTasks: FC = () => {
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])

  const { data: ordersData, isLoading } = useQuery({
    queryKey: ['orders'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.managementControllerGetAllOrders({ page: 1, take: 200 }),
  })

  const orders = useMemo(() => (ordersData?.data?.data ?? []) as CustomerOrderListResponseDto[], [ordersData])

  const downloadExcelMutation = useMutation<
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>
  >({
    mutationFn: () => AdminAPI.api.managementControllerGenerateOrdersExcel(),
  })

  const handleDownloadExcel = useCallback(() => {
    const updateCustomerToastId = toast.loading('Подготавливаем Excel файл')

    downloadExcelMutation.mutate(undefined, {
      onSuccess: (data) => {
        const link = document.createElement('a')
        link.href = data.data.link
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.success('Файл скачан! ✨', {
          id: updateCustomerToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при создании файла', {
          id: updateCustomerToastId,
        })
      },
    })
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <CabinetPageLayout>
      <Flex justifyContent="space-between" alignItems="center">
        <h1 className={styles.Title}>
          {`${orders.length} ${plural(['задание', 'задания', 'заданий'], orders.length)}`}
        </h1>
        <CabinetGhostButton icon="download" onClick={handleDownloadExcel}>
          Отчёт по заданиям
        </CabinetGhostButton>
      </Flex>

      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => (
          <Task key={item.id} task={item} onClick={openTask} />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
