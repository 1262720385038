import { useQuery } from '@tanstack/react-query'

import { AdminAPI } from '../api'
import { RoleType } from '../config/types'

import { useUserInfo } from './useUser'

export const useBloggerProfile = () => {
  const { user } = useUserInfo()

  const { data: profileData } = useQuery({
    queryKey: ['profile'],
    queryFn: () => AdminAPI.api.bloggerControllerGetProfile({ format: 'json' }),
    enabled: user?.role === RoleType.BLOGER,
  })

  return profileData?.data
}
