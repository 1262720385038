import React, { FC, ReactNode } from 'react'
import { Flex } from '../Flex'

import styles from './Product.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface ProductPropsType {
  photoUrl: string
  title: string
  price: number
  onClick(): void
  children?: ReactNode
}

export const Product: FC<ProductPropsType> = ({ photoUrl, title, price, onClick, children }) => {
  return (
    <Flex className={styles.Product} flexDirection="column" onClick={onClick}>
      <img className={styles.Photo} src={photoUrl} />
      <Flex className={styles.Body} flexDirection="column" gap={24}>
        <Flex flexDirection="column" justifyContent="space-between" flexGrow={1} gap={20}>
          <span className={styles.Title}>{title}</span>
          <span className={styles.Price}>{numberFormat.format(price)} ₽</span>
        </Flex>
        {children}
      </Flex>
    </Flex>
  )
}
