import React, { ForwardedRef, forwardRef, ReactElement } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'
import { ReactHookFormFieldType } from 'src/shared/config/types'
import { SelectField, SelectFieldPropsType } from '../SelectField'

export type SelectFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = ReactHookFormFieldType<
  TFieldValues,
  SelectFieldPropsType
>

const SelectFormFieldWithRef = <TFieldValues extends FieldValues = FieldValues>(
  { name, rules, shouldUnregister, defaultValue, ...props }: SelectFormFieldPropsType<TFieldValues>,
  forwardedRef: ForwardedRef<HTMLInputElement>,
): ReactElement<SelectFormFieldPropsType<TFieldValues>> => {
  const { field, error } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return <SelectField {...field} error={error} {...props} ref={forwardedRef} />
}

export const SelectFormField = forwardRef(SelectFormFieldWithRef)
