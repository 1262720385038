import React, { FC, useState } from 'react'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CheckboxField } from 'src/shared/ui/CheckboxField'
import { Flex } from 'src/shared/ui/Flex'
import { Modal } from 'src/shared/ui/Modal'

import styles from './DeleteReportModal.module.scss'

interface DeleteReportModalProps {
  onDelete(isRefund: boolean): void
  onClose(): void
}

export const DeleteReportModal: FC<DeleteReportModalProps> = ({ onDelete, onClose }) => {
  const [isRefund, setRefundState] = useState(false)

  return (
    <Modal className={styles.Modal} title="Удаление отклика" size="small" onClose={onClose}>
      <Flex flexDirection="column" gap={16}>
        <p>
          Вы уверены, что хотите удалить выбранный отклик на это задание. Это действие необратимо, и вы не сможете
          восстановить удалённый отклик.
        </p>
        <CheckboxField id="isRefund" checked={isRefund} onChange={() => setRefundState((prev) => !prev)}>
          Оформить возврат денег
        </CheckboxField>
        <Flex className={styles.Buttons} flexDirection="column" alignSelf="center" gap={8}>
          <CabinetActionButton kind="danger" onClick={() => onDelete(isRefund)}>
            Удалить
          </CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onClose}>
            Отмена
          </CabinetActionButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
