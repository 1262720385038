import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { AdminAPI } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { BloggerOrderListResponseDto } from 'src/shared/config/types'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { Task } from '../Task/Task'

import styles from './BloggerCabinetTasks.module.scss'

enum TabType {
  ACTIVE_TASKS = 'active',
  COMPLETED_TASKS = 'completed',
}

export const BloggerCabinetTasks: FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])
  const [activeTabId, setActiveTabId] = useState<string>(TabType.ACTIVE_TASKS)

  // TODO Добавить пагинацию
  const { data: ordersData } = useQuery({
    queryKey: ['orders', 'my', activeTabId],
    queryFn: () =>
      AdminAPI.api.ordersControllerFindAll({
        page: 1,
        take: 200,
        reportCompleted: activeTabId === TabType.COMPLETED_TASKS,
      }),
  })

  const orders = (ordersData?.data?.data ?? []) as BloggerOrderListResponseDto[]

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.ACTIVE_TASKS,
        title: 'Активные',
      },
      {
        tabId: TabType.COMPLETED_TASKS,
        title: 'Завершённые',
      },
    ],
    [],
  )

  return (
    <CabinetPageLayout className={styles.Layout}>
      <Flex justifyContent="space-between" alignItems="center">
        <PageTitle>Мои задания</PageTitle>
        <Flex gap={8}>
          <a
            href="https://docs.google.com/document/d/1lgLma5W1pTJxNfB5lzFunPClgBLz2PZkwnaEJqfwJFY"
            target="_blank"
            className={styles.EducationButton}
          >
            <CabinetGhostButton>Обучение</CabinetGhostButton>
          </a>
        </Flex>
      </Flex>

      <Tabs className={styles.Tabs} tabs={tabs} activeTabId={activeTabId} onTabClick={setActiveTabId} />

      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => (
          <Task key={item.id} task={item} onClick={() => openTask(item.id)} />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
