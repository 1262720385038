import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import LogoImg from 'src/shared/assets/Logo.png'
import { CABINET_ROUTE } from 'src/shared/config/consts'

import styles from './Logo.module.scss'

export const Logo: FC = () => {
  return (
    <Link to={CABINET_ROUTE} className={styles.Logo}>
      {/* Marketiq */}
      <img src={LogoImg} alt="" />
    </Link>
  )
}
