import React, { FC, ReactNode } from 'react'

import { RoleType } from 'src/shared/config/types'

import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'

import styles from './SubmittedTheReportBlogger.module.scss'

interface SubmittedTheReportBloggerPropsType extends BloggerPropsType {
  role: RoleType
  onIntegrationDetailClick(): void
  children?: ReactNode
}

export const SubmittedTheReportBlogger: FC<SubmittedTheReportBloggerPropsType> = ({
  role,
  onIntegrationDetailClick,
  children,
  ...props
}) => {
  return (
    <Blogger {...props}>
      {role === RoleType.CUSTOMER && (
        <Flex className={styles.Container} flexDirection="column" alignItems="center" gap={16}>
          <span className={styles.Text}>Блогер выполнил все этапы задания. Проверьте их.</span>
          <CabinetActionButton onClick={onIntegrationDetailClick}>Детали интеграции</CabinetActionButton>
        </Flex>
      )}
      {role === RoleType.SUPER_ADMIN && (
        <CabinetActionButton onClick={onIntegrationDetailClick}>Детали интеграции</CabinetActionButton>
      )}
      {children}
    </Blogger>
  )
}
