import { FC, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, UserEmailDto } from 'src/shared/api'
import { LOGIN_ROUTE } from 'src/shared/config/consts'

import { emailRules } from 'src/shared/lib/emailRules'

import { AuthButton } from 'src/shared/ui/AuthButton'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { AuthBlock } from 'src/widgets/AuthBlock'

import styles from '../Login/Login.module.scss'

export const ForgotPasswordBlock: FC = () => {
  const navigate = useNavigate()
  const methods = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  })
  const { handleSubmit, watch } = methods
  const values = watch()

  const forgotPasswordMutation = useMutation<
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    UserEmailDto
  >({
    mutationFn: AdminAPI.api.authControllerForgotPassword,
  })
  const isLoading = forgotPasswordMutation.isPending

  const onSubmit = useCallback((data: { email: string }) => {
    const signInDto: UserEmailDto = {
      email: data.email,
    }

    forgotPasswordMutation.mutate(signInDto, {
      onSuccess: () => {
        navigate(LOGIN_ROUTE)
        toast.success('На ваш email отправлено письмо с ссылкой для восстановления пароля.', { duration: 5000 })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка с восстановлением пароля')
      },
    })
  }, [])

  const isAuthButtonDisabled = useMemo(() => !values.email || isLoading, [values])

  return (
    <AuthBlock title="Восстановление пароля" className={styles.AuthBlock}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
          <div className={styles.InputsContainer}>
            <TextInputFormField
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              autoComplete="email"
              rules={emailRules}
              disabled={isLoading}
            />
            <input type="password" className="visibility-hidden" />
          </div>
          <Link to={LOGIN_ROUTE} className={styles.PasswordLink} type="button">
            Вспомнили пароль?
          </Link>
          <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
            Восстановить
          </AuthButton>
        </form>
      </FormProvider>
    </AuthBlock>
  )
}
