import React, { FC, useState } from 'react'
import { useDebounce } from 'react-use'

import { Icon } from 'src/shared/ui/Icon'
import { Input } from 'src/shared/ui/Input'

import styles from './AdminCabinetSearch.module.scss'

interface AdminCabinetSearchProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

export const AdminCabinetSearch: FC<AdminCabinetSearchProps> = ({ setSearchValue }) => {
  const [value, setValue] = useState('')

  useDebounce(
    () => {
      setSearchValue(value)
    },
    500,
    [value],
  )

  return (
    <Input
      kind="cabinet"
      placeholder="Поиск по никнейму инстаграма или имени"
      className={styles.Input}
      postfix={<Icon name="search" />}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  )
}
