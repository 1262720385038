import React, { FC, useMemo, useState } from 'react'

import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'

import { UserStatusType } from 'src/shared/config/types'

import { Tabs } from 'src/shared/ui/Tabs'

import { BloggerPersonalForm } from '../BloggerPersonalForm/BloggerPersonalForm'
import { BloggerProfileData } from '../BloggerProfileData/BloggerProfileData'
import { BloggerVerification } from '../BloggerVerification/BloggerVerification'

import styles from './BloggerProfile.module.scss'

enum TabType {
  PERSONAL_INFO_TAB_ID = 'personal',
  PROFILE_DATA = 'profile',
  VERIFICATION_TAB_ID = 'verification',
}

export const BloggerProfile: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(TabType.PERSONAL_INFO_TAB_ID)
  const profile = useBloggerProfile()

  const isBloggerActivated = profile?.status === UserStatusType.ACTIVATED

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PERSONAL_INFO_TAB_ID,
        title: 'Личная информация',
      },
      {
        tabId: TabType.VERIFICATION_TAB_ID,
        title: 'Верификация аккаунта',
      },
      ...(isBloggerActivated
        ? [
            {
              tabId: TabType.PROFILE_DATA,
              title: 'Данные аккаунта',
            },
          ]
        : []),
    ],
    [isBloggerActivated],
  )

  return (
    <div>
      <Tabs className={styles.Tabs} tabs={tabs} activeTabId={activeTabId} onTabClick={setActiveTabId} />
      {activeTabId === TabType.PERSONAL_INFO_TAB_ID && (
        <BloggerPersonalForm profile={profile} key={`profile-${profile?.id}`} />
      )}
      {activeTabId === TabType.VERIFICATION_TAB_ID && (
        <BloggerVerification profile={profile} key={`profile-${profile?.id}`} />
      )}
      {activeTabId === TabType.PROFILE_DATA && isBloggerActivated && (
        <BloggerProfileData profile={profile} key={`profile-${profile?.id}`} />
      )}
    </div>
  )
}
