import React, { FC, useCallback, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  AdminAPI,
  CommonMessageResponse,
  ExcelReportResponseDto,
  HttpResponse,
  OrderManagementDto,
} from 'src/shared/api'
import { CABINET_TASKS_NEW_ROUTE, CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto, OrderStatusType } from 'src/shared/config/types'

import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { Task } from 'src/shared/ui/Task'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './CabinetTasks.module.scss'

export const CabinetTasks: FC = () => {
  const navigate = useNavigate()
  const handleAddTaskClick = useCallback(() => navigate(CABINET_TASKS_NEW_ROUTE), [navigate])
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])

  const queryClient = useQueryClient()

  const { data: ordersData } = useQuery({
    queryKey: ['orders'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.ordersControllerFindAll({ page: 1, take: 200 }),
  })

  const cancelOrderMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { id: number }
  >({
    mutationFn: ({ id }: { id: number }) => AdminAPI.api.ordersControllerCancelOrder(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders'] }),
  })

  const handleCancelOrder = useCallback(
    (id: number) => {
      const cancelOrderToastId = toast.loading('Отменяем задание')
      cancelOrderMutation.mutate(
        { id },
        {
          onSuccess: () => {
            toast.success('Задание отменено! ✨', {
              id: cancelOrderToastId,
            })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при отмене задания', {
              id: cancelOrderToastId,
            })
          },
        },
      )
    },
    [cancelOrderMutation],
  )

  const changeOrderStatusMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { id: number; variables: OrderManagementDto }
  >({
    mutationFn: ({ id, variables }: { id: number; variables: OrderManagementDto }) =>
      AdminAPI.api.ordersControllerChangeOrderStatus(id, variables),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders'] }),
  })

  const orders = useMemo(() => (ordersData?.data?.data ?? []) as CustomerOrderListResponseDto[], [ordersData])

  const handleStatusChange = useCallback(
    (id: number, status: OrderStatusType) => {
      changeOrderStatusMutation.mutate({ id, variables: { status } })
    },
    [changeOrderStatusMutation],
  )

  const downloadExcelMutation = useMutation<
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>,
    HttpResponse<ExcelReportResponseDto, CommonMessageResponse | void>
  >({
    mutationFn: () => AdminAPI.api.customersControllerGenerateOrdersExcel(),
  })

  const handleDownloadExcel = useCallback(() => {
    const updateCustomerToastId = toast.loading('Подготавливаем Excel файл')

    downloadExcelMutation.mutate(undefined, {
      onSuccess: (data) => {
        const link = document.createElement('a')
        link.href = data.data.link
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.success('Файл скачан! ✨', {
          id: updateCustomerToastId,
        })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка при создании файла', {
          id: updateCustomerToastId,
        })
      },
    })
  }, [])

  return (
    <CabinetPageLayout>
      <Flex justifyContent="space-between" alignItems="center">
        <PageTitle>Мои задания</PageTitle>
        <Flex gap={8}>
          <CabinetGhostButton icon="download" onClick={handleDownloadExcel}>
            Отчёт по заданиям
          </CabinetGhostButton>
          <CabinetGhostButton onClick={handleAddTaskClick} icon="plus">
            Добавить задание
          </CabinetGhostButton>
        </Flex>
      </Flex>
      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => {
          if (!item || !item.customReportersStatistic) {
            return null
          }
          return (
            <Task
              key={item.id}
              task={item}
              onClick={openTask}
              onStatusChange={handleStatusChange}
              onCancelOrder={handleCancelOrder}
            />
          )
        })}
      </Flex>
    </CabinetPageLayout>
  )
}
