import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Tabs.module.scss'

export interface TabType {
  tabId: string
  title: string
  badge?: number
}

interface TabsPropsType {
  className?: string
  activeTabId?: string
  tabs: TabType[]
  onTabClick(tabId: string): void
}

export const Tabs: FC<TabsPropsType> = ({ className, activeTabId, tabs, onTabClick }) => {
  return (
    <div className={cn(styles.Tabs, className)}>
      {tabs.map(({ tabId, title, badge }) => (
        <div
          key={tabId}
          className={cn(styles.Tab, tabId === activeTabId && styles.isActive)}
          onClick={() => onTabClick(tabId)}
        >
          <span className={styles.Title}>{title}</span>
          {badge !== undefined && <span className={styles.Badge}>{badge}</span>}
        </div>
      ))}
    </div>
  )
}
