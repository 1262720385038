import React, { forwardRef, ForwardedRef } from 'react'
import { Field, FieldPropsType } from '../Field'
import { Checkbox, CheckboxPropsType } from '../Checkbox'

export type CheckboxFieldPropsType = Omit<FieldPropsType, 'children'> & CheckboxPropsType

const CheckboxFieldWithRef = (
  { className, label, id, error, description, isRequired, withHiddenLabel, ...rest }: CheckboxFieldPropsType,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field
      className={className}
      kind="cabinet"
      label={label}
      id={id}
      error={error}
      description={description}
      isRequired={isRequired}
      withHiddenLabel={withHiddenLabel}
    >
      <Checkbox ref={ref} id={id} isInvalid={Boolean(error)} {...rest} />
    </Field>
  )
}

export const CheckboxField = forwardRef(CheckboxFieldWithRef)
