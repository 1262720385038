import React, { FC } from 'react'
import cn from 'classnames'

import styles from './TheyTalkAboutUs.module.scss'
import { COMMENTS } from '../../config/consts'

interface TheyTalkAboutUsProps {
  className: string
}

export const TheyTalkAboutUs: FC<TheyTalkAboutUsProps> = ({ className }) => {
  return (
    <section className={cn(styles.TheyTalkAboutUs, className)} aria-labelledby="stages-title">
      <h2 className={styles.Title}>Что о нас говорят</h2>
      <div className={styles.Comments}>
        {COMMENTS.map((CommentsColumn, ColumnIndex) => (
          <div className={styles.CommentsColumn} key={ColumnIndex}>
            {CommentsColumn.map((comment, index) => (
              <div key={index} className={styles.Comment}>
                <div className={styles.CommentText}>{comment.text}</div>
                <span className={styles.CommentName}>{comment.name}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}
