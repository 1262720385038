import React, { CSSProperties, type FC, HTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Flex.module.scss'

export const PROPERTIES = [
  'alignItems',
  'alignSelf',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'justifyContent',
  'order',
] as const

export type FlexProperties =
  | 'alignItems'
  | 'alignSelf'
  | 'flexBasis'
  | 'flexDirection'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexWrap'
  | 'justifyContent'
  | 'order'
  | 'gap'

interface FlexPropsType extends Pick<CSSProperties, FlexProperties>, HTMLAttributes<HTMLDivElement> {
  isInline?: boolean
  isFluid?: boolean
  children: ReactNode | ReactNode[]
}

export const Flex: FC<FlexPropsType> = ({
  isFluid,
  className,
  flexDirection,
  flexWrap,
  alignItems,
  justifyContent,
  children,
  isInline,
  flexShrink,
  flexGrow,
  flexBasis,
  order,
  gap,
  alignSelf,
  ...props
}) => {
  return (
    <div
      className={cn(
        styles.Flex,
        { [styles.isFluid]: isFluid },
        { [styles.isInline]: isInline },
        className,
      )}
      style={{
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        flexShrink,
        flexGrow,
        flexBasis,
        order,
        gap,
        alignSelf,
      }}
      {...props}
    >
      {children}
    </div>
  )
}
