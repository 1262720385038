import React, { FC } from 'react'
import cn from 'classnames'
import image from 'src/shared/assets/any_questions_1.webp'

import { TelegramButton } from '../TelegramButton/TelegramButton'
import styles from './AnyQuestions.module.scss'

interface AnyQuestionsPropsType {
  className: string
}

export const AnyQuestions: FC<AnyQuestionsPropsType> = ({ className }) => {
  return (
    <article className={cn(styles.AnyQuestions, className)}>
      <div className={styles.Container}>
        <div className={styles.FirstBlock}>
          <strong className={styles.Title}>Остались вопросы?</strong>
          <p className={styles.Description}>Напишите напрямую нам. Мы на связи</p>
        </div>
        <div className={styles.SecondBlock}>
          <div className={styles.ImageContainer}>
            <img className={styles.Image} src={image} alt="" aria-hidden="true" />
            <span className={styles.ImageDescription}>Служба заботы</span>
          </div>
          <TelegramButton className={styles.TelegramButton} />
        </div>
      </div>
    </article>
  )
}
