import React, { FC, useCallback, useRef } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'

import backgroundGradient1 from 'src/shared/assets/how_does_this_work_background_gradient_1.svg'
import backgroundGradient2 from 'src/shared/assets/how_does_this_work_background_gradient_2.svg'

import { ITEMS } from '../../config/consts'

import styles from './HowDoesThisWork.module.scss'

interface HowDoesThisWorkPropsType {
  className: string
}

export const HowDoesThisWork: FC<HowDoesThisWorkPropsType> = ({ className }) => {
  const listRef = useRef<HTMLOListElement>(null)

  const handleArrowLeftClick = useCallback(() => {
    if (!listRef.current) {
      return
    }

    const gap = parseInt(window.getComputedStyle(listRef.current).gap) || 0
    const itemWidth = listRef.current.children[0].clientWidth
    const position = listRef.current.scrollLeft / (itemWidth + gap)
    const nextPosition = position % 1 === 0 ? position - 1 : Math.floor(position)

    listRef.current?.scrollTo({
      left: nextPosition * (itemWidth + gap),
      behavior: 'smooth',
    })
  }, [])

  const handleArrowRightClick = useCallback(() => {
    if (!listRef.current) {
      return
    }

    const gap = parseInt(window.getComputedStyle(listRef.current).gap) || 0
    const itemWidth = listRef.current.children[0].clientWidth
    const position = Math.ceil(listRef.current.scrollLeft / (itemWidth + gap))
    const nextPosition = position % 1 === 0 ? position + 1 : position

    listRef.current?.scrollTo({
      left: nextPosition * (itemWidth + gap),
      behavior: 'smooth',
    })
  }, [])

  const renderArrows = useCallback(
    () => (
      <div className={styles.Arrows} aria-label="Кнопки управления">
        <button className={styles.Arrow__button} onClick={handleArrowLeftClick} aria-label="Пролистать назад">
          <Icon className={styles.Arrow} name="arrowLeft" />
        </button>
        <button className={styles.Arrow__button} onClick={handleArrowRightClick} aria-label="Пролистать вперед">
          <Icon className={styles.Arrow} name="arrowRight" />
        </button>
      </div>
    ),
    [handleArrowLeftClick, handleArrowRightClick],
  )

  return (
    <section className={cn(styles.HowDoesThisWork, className)} aria-labelledby="how-does-this-work-title">
      <img className={styles.BackgroundGradient1} src={backgroundGradient1} alt="" aria-hidden="true" />
      <img className={styles.BackgroundGradient2} src={backgroundGradient2} alt="" aria-hidden="true" />
      <div className={styles.Header}>
        <h2 id="how-does-this-work-title" className={styles.Title}>
          Как это работает?
        </h2>
        {renderArrows()}
      </div>
      <ol className={styles.List} ref={listRef} role="list">
        {ITEMS.map(({ number, title, description, image }) => (
          <li key={number} className={styles.Item} role="listitem">
            <div className={styles.ItemHeader}>
              <strong className={styles.ItemTitle}>{title}</strong>
              <span className={styles.ItemNumber}>{number}</span>
            </div>
            <span className={styles.ItemDescription}>{description}</span>
            <img className={styles.ItemImage} src={image} alt="" />
          </li>
        ))}
      </ol>
      <div className={styles.Footer}>{renderArrows()}</div>
    </section>
  )
}
