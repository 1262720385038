import React, { FC } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'

import { AdminAPI } from 'src/shared/api'
import { OrderResponsesResponseDto } from 'src/shared/config/types'

import { CabinetIntegrationPageLayout } from 'src/widgets/CabinetIntegrationPageLayout'

export const CabinetIntegration: FC = () => {
  const { taskId, responseId } = useParams()
  const orderId = Number(taskId)
  const reportId = Number(responseId)

  const { data: orderResponseData } = useQuery({
    queryKey: ['orders', orderId, reportId],
    queryFn: () => AdminAPI.api.ordersControllerFindByIdResponses(reportId, orderId),
  })
  const orderResponse = orderResponseData?.data as OrderResponsesResponseDto | undefined

  if (!orderResponse) {
    return null
  }

  return <CabinetIntegrationPageLayout orderResponse={orderResponse} />
}
