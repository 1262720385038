import React, { ChangeEventHandler, FC, FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useTopics } from 'src/shared/model/useTopics'

import { AdminAPI, CommonMessageResponse, CreateTopicDto, HttpResponse } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { Icon } from 'src/shared/ui/Icon'
import { Input } from 'src/shared/ui/Input'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './AdminCabinetTopics.module.scss'

export const AdminCabinetTopics: FC = () => {
  const [isAddNewTopic, setIsAddNewTopic] = useState(false)
  const [newTopicName, setNewTopicName] = useState('')

  const queryClient = useQueryClient()

  const topics = useTopics()

  const { mutate: addTopicMutation, isPending: isAddTopicLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateTopicDto
  >({
    mutationFn: AdminAPI.api.managementControllerAddTopic,
  })

  const { mutate: removeTopicMutation } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: AdminAPI.api.managementControllerRemoveTopic,
  })

  const addTopic = useCallback(() => {
    if (!newTopicName) {
      return
    }
    const addTopicToastId = toast.loading('Добавляем топик')

    addTopicMutation(
      { name: newTopicName },
      {
        onSuccess: () => {
          setIsAddNewTopic(false)
          setNewTopicName('')
          queryClient.invalidateQueries({ queryKey: ['topics'] })
          toast.success('Новый топик добавлен! ✨', {
            id: addTopicToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при создании топика', {
            id: addTopicToastId,
          })
        },
      },
    )
  }, [newTopicName, addTopicMutation])

  const removeTopic = useCallback(
    (id: number) => {
      const removeTopicToastId = toast.loading('Удаляем топик')

      removeTopicMutation(id, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['topics'] })
          toast.success('Топик успешно удалён!', {
            id: removeTopicToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при удалении топика', {
            id: removeTopicToastId,
          })
        },
      })
    },
    [removeTopicMutation],
  )

  const handleNewTopicNameInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewTopicName(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      addTopic()
    },
    [addTopic],
  )

  const handleAddButtonClick = useCallback(() => setIsAddNewTopic(true), [])

  const handleCancelButtonClick = useCallback(() => {
    setIsAddNewTopic(false)
    setNewTopicName('')
  }, [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <PageTitle>Топики товаров</PageTitle>
      <Flex className={styles.Topics} flexDirection="column" gap={8}>
        {topics.map((item) => (
          <Flex key={item.name} alignItems="center" gap={20}>
            <Input kind="cabinet" value={item.name} readOnly />
            <Icon className={styles.CloseIcon} name="close" onClick={() => removeTopic(item.id)} />
          </Flex>
        ))}
        {isAddNewTopic ? (
          <form onSubmit={handleSubmit}>
            <Flex alignItems="center" gap={20}>
              <Input
                kind="cabinet"
                value={newTopicName}
                onChange={handleNewTopicNameInputChange}
                disabled={isAddTopicLoading}
                autoFocus
              />
              <Icon className={styles.CloseIcon} name="close" onClick={handleCancelButtonClick} />
            </Flex>
          </form>
        ) : (
          <CabinetActionButton className={styles.AddButton} kind="ghost" onClick={handleAddButtonClick}>
            Добавить топик
          </CabinetActionButton>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
