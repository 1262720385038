import React, { FC } from 'react'

import styles from './RequisiteSection.module.scss'

export const RequisiteSection: FC = () => {
  return (
    <div className={styles.Requisite}>
      <h2 className={styles.Title}>Реквизиты</h2>
      <div className={styles.Container}>
        <p>
          <span className={styles.Label}>ИП:</span> ПОПОВА АНГЕЛИНА ДМИТРИЕВНА
        </p>
        <p>
          <span className={styles.Label}>ИНН:</span> 772346939433
        </p>
        <p>
          <span className={styles.Label}>ОГРН:</span> 324774600359400
        </p>
        <p>
          <span className={styles.Label}>Телефон:</span> 8-993-366-77-59
        </p>
        <p>
          <span className={styles.Label}>Email:</span>{' '}
          <a href="mailto:Marketiq.info@yandex.ru" className={styles.Link}>
            Marketiq.info@yandex.ru
          </a>
        </p>
      </div>
    </div>
  )
}
