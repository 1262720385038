export function getMarketplaceClass(marketplaceName: string | undefined): string {
  const normalizedName = marketplaceName?.trim().toUpperCase()

  switch (normalizedName) {
    case 'ОЗОН':
    case 'OZON':
      return 'OZON'
    case 'WB':
    case 'WILDBERRIES':
      return 'WB'
    case 'ЗОЛОТОЕ ЯБЛОКО':
      return 'GOLDAPPLE'
    case 'ЯНДЕКС МАРКЕТ':
      return 'YANDEX'
    default:
      return ''
  }
}
