import React, { FC, FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { Flex } from 'src/shared/ui/Flex'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { NumericInputField } from 'src/shared/ui/NumericInputField'
import { NumericInputOnChangeHandlerType } from 'src/shared/ui/NumericInput/NumericInput'

import { AdminAPI, CommonMessageResponse, CreatePaymentDto, HttpResponse, PaymentResponseDto } from 'src/shared/api'

import styles from './IntegrationsBlock.module.scss'

export const IntegrationsBlock: FC = () => {
  const [balance, setBlance] = useState(20)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { mutateAsync: initPaymentMutation, isPending: isLoading } = useMutation<
    HttpResponse<PaymentResponseDto, CommonMessageResponse | void>,
    HttpResponse<PaymentResponseDto, CommonMessageResponse | void>,
    CreatePaymentDto
  >({
    mutationFn: AdminAPI.api.paymentControllerInitializePayment,
  })

  const getPricePerIntegration = (count: number): number => {
    if (count >= 0 && count <= 20) return 500
    if (count >= 21 && count < 100) return 400
    if (count >= 100 && count <= 1000000) return 300
    return 0
  }

  const calculateTotalPrice = (count: number): number => {
    const pricePerIntegration = getPricePerIntegration(count)
    return pricePerIntegration * count
  }

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      const initPaymentToastId = toast.loading('Создаём платёж')

      event.preventDefault()
      if (balance <= 0) {
        setErrorMessage('Кол-во интеграций должно быть положительным числом')
        return
      }

      await initPaymentMutation(
        {
          description: 'Покупка интеграций MarketIQ',
          quantity: balance,
        },
        {
          // TODO - подключить правильные типы
          onSuccess: ({ data }: any) => {
            if ('PaymentURL' in data) {
              window.location.href = String(data.PaymentURL)
            }
            if (!data.Success) {
              toast.error(data.Message + ' ' + data.Details, {
                id: initPaymentToastId,
              })
            } else {
              toast.success('Платёж создан! ✨', { id: initPaymentToastId })
            }
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Произошла ошибка при создании платежа', {
              id: initPaymentToastId,
            })
          },
        },
      )

      setErrorMessage('')
    },
    [balance, initPaymentMutation],
  )

  const handleBalanceChange = useCallback<NumericInputOnChangeHandlerType>((_, value) => {
    if (Number(value) < 20) {
      setErrorMessage('Минимальное кол-во 20 интеграций')
    } else {
      setErrorMessage('')
    }
    setBlance(Number(value))
  }, [])

  const pricePerIntegration = getPricePerIntegration(balance)
  const totalPrice = calculateTotalPrice(balance)
  const isButtonDisable = balance < 20 || balance > 1000000 || isLoading

  return (
    <div className={styles.Integrations}>
      <h2 className={styles.Integrations__title}>Пополнить интеграции</h2>
      <form className={styles.IntegrationsСard} onSubmit={handleSubmit}>
        <NumericInputField
          id="total"
          kind="cabinet"
          value={balance}
          onChange={handleBalanceChange}
          error={errorMessage}
          className={styles.IntegrationsСard__input}
          maxLength={100}
        />
        <span className={styles.IntegrationsСard__priceOfOne}>1 интеграция = {pricePerIntegration} ₽</span>
        <hr className={styles.IntegrationsСard__delimiter} />
        <div className={styles.IntegrationsСard__footer}>
          <Flex flexDirection="column" gap={2}>
            <span className={styles.IntegrationsСard__price_title}>Цена</span>
            <strong className={styles.IntegrationsСard__price}>{totalPrice.toLocaleString()} ₽</strong>
          </Flex>
          <CabinetActionButton className={styles.IntegrationsСard__button} disabled={isButtonDisable}>
            Оплатить
          </CabinetActionButton>
        </div>
      </form>
    </div>
  )
}
