import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { NumericInput, NumericInputPropsType } from '../NumericInput'

export type NumericInputFieldPropsType = Omit<FieldPropsType, 'children'> & NumericInputPropsType

const NumericInputFieldWithRef = (
  { className, kind, label, id, error, isRequired, ...rest }: NumericInputFieldPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <NumericInput id={id} isInvalid={Boolean(error)} kind={kind} ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const NumericInputField = forwardRef(NumericInputFieldWithRef)
