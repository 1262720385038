import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { PhoneInput, PhoneInputPropsType } from '../PhoneInput'

export type PhoneInputFieldPropsType = Omit<FieldPropsType, 'children'> & PhoneInputPropsType

const PhoneInputFieldWithRef = (
  { className, kind, label, id, error, isRequired, ...rest }: PhoneInputFieldPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <PhoneInput id={id} isInvalid={Boolean(error)} kind={kind} type="tel" ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const PhoneInputField = forwardRef(PhoneInputFieldWithRef)
