import { S3Client } from '@aws-sdk/client-s3'
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { v4 as uuidv4 } from 'uuid'

const REGION = 'ru-central1'
const ENDPOINT = 'https://storage.yandexcloud.net'

const s3Client = new S3Client({
  region: REGION,
  endpoint: ENDPOINT,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!,
  },
})

const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/webp',
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/mkv',
  'video/avi',
  'video/mov',
  'video/quicktime',
]

export const StorageAPI = {
  upload: async (file: File) => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      throw `Формат файла: ${file.type} недоступен для загрузки`
    }

    const Key = uuidv4()
    await s3Client.send(
      new PutObjectCommand({ Key, Bucket: process.env.REACT_APP_S3_BUCKET, Body: file, ContentType: file.type }),
    )

    return `${ENDPOINT}/${process.env.REACT_APP_S3_BUCKET}/${Key}`
  },
}
