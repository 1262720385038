import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { addDays, addHours, addMonths, differenceInSeconds } from 'date-fns'

import { plural } from 'src/shared/lib/plural'

import styles from './RemainingTime.module.scss'

interface RemainingTimeProps {
  time: string
  duration: number
  durationType?: 'hours' | 'days' | 'months'
  label: string
  error: string
  className?: string
}

export const RemainingTime: FC<RemainingTimeProps> = ({
  time,
  duration,
  durationType = 'hours',
  label,
  error,
  className,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>('00:00:00')
  const [isTimeOver, setTimeOver] = useState(false)

  useEffect(() => {
    const endTime = (() => {
      const startTime = new Date(time)
      if (durationType === 'hours') return addHours(startTime, duration)
      if (durationType === 'days') return addDays(startTime, duration)
      if (durationType === 'months') return addMonths(startTime, duration)
      return startTime
    })()

    let timer: NodeJS.Timeout | null = null

    const updateRemainingTime = () => {
      const now = new Date()
      const secondsLeft = differenceInSeconds(endTime, now)

      if (secondsLeft <= 0) {
        setRemainingTime('00:00:00')
        setTimeOver(true)
        if (timer) clearInterval(timer)
      } else {
        const daysLeft = Math.ceil(secondsLeft / (3600 * 24))

        if (daysLeft > 2) {
          setRemainingTime(`${daysLeft} ${plural(['день', 'дня', 'дней'], daysLeft)}`)
        } else {
          const hours = Math.floor(secondsLeft / 3600)
          const minutes = Math.floor((secondsLeft % 3600) / 60)
          const seconds = secondsLeft % 60

          setRemainingTime(
            `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
          )
        }
      }
    }

    timer = setInterval(updateRemainingTime, 1000)
    updateRemainingTime()

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [time, duration])

  return (
    <div className={cn(styles.RemainingTime, className)}>
      <span className={cn(styles.Label, isTimeOver && styles.Error)}>{isTimeOver ? error : label}</span>
      <strong className={cn(styles.Time, isTimeOver && styles.Error)}>{remainingTime}</strong>
    </div>
  )
}
