import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { ProductResponseDto, UserResponseDto } from 'src/shared/api'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { Flex } from 'src/shared/ui/Flex'
import { Product } from 'src/shared/ui/Product'

interface ProductsPropsType {
  user: UserResponseDto
}

export const Products: FC<ProductsPropsType> = ({ user }) => {
  const navigate = useNavigate()
  const products = (user.customer?.products ?? []) as unknown as ProductResponseDto[]

  const renderProduct = useCallback((product: ProductResponseDto) => {
    const { id, photoUrl, title, price } = product

    return (
      <Product
        key={id}
        photoUrl={photoUrl}
        title={title}
        price={price}
        onClick={() => navigate(`${CABINET_PRODUCTS_ROUTE}/${id}`)}
      />
    )
  }, [navigate])

  return (
    <Flex flexWrap="wrap" gap={16}>
      {products.map(renderProduct)}
    </Flex>
  )
}
