import React, { FC, MouseEvent, useCallback } from 'react'
import cn from 'classnames'

import { useUserInfo } from 'src/shared/model/useUser'

import { CollectionType, CustomerOrderListResponseDto, OrderStatusType, RoleType } from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'

import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex'
import { OrderStatus } from 'src/shared/ui/OrderStatus'
import { СollectionButton } from 'src/shared/ui/СollectionButton'

import { Icon } from '../Icon'

import styles from './Task.module.scss'

interface AdminOrderListResponseDto extends CustomerOrderListResponseDto {
  tgUsername?: string
  firstName?: string
}

interface TaskPropsType {
  task: AdminOrderListResponseDto
  onClick(id: number): void
  onStatusChange?(id: number, status: OrderStatusType): void
  onCancelOrder?(id: number): void
}

export const Task: FC<TaskPropsType> = ({ task, onClick, onStatusChange, onCancelOrder }) => {
  const { user } = useUserInfo()
  const { photoUrl, title, collection, marketplaceName, status, maxParticipants, customReportersStatistic } = task

  const handleClick = useCallback(() => onClick(task.id), [task, onClick])

  const handleCollectionButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onStatusChange?.(task.id, status === OrderStatusType.PAUSE ? OrderStatusType.IN_PROGRESS : OrderStatusType.PAUSE)
    },
    [task, onStatusChange],
  )

  const handleCancelOrdernButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onCancelOrder?.(task.id)
    },
    [task, onCancelOrder],
  )

  const renderItem = useCallback(
    (title: string, count: number, isWaitingForPayment = false) => (
      <Flex flexDirection="column" gap={8}>
        <span className={styles.CustomReportersTitle}>{title}</span>
        <span
          className={cn(
            styles.CustomReportersCount,
            isWaitingForPayment && styles.CustomReportersCount__isWaitingForPayment,
          )}
        >
          {count}
        </span>
      </Flex>
    ),
    [],
  )

  if (!customReportersStatistic) {
    return null
  }

  const confirmedCount =
    customReportersStatistic.COMPLETED +
    customReportersStatistic.COMPLETED_STEP_2 +
    customReportersStatistic.CONFIRMED +
    customReportersStatistic.PAID +
    customReportersStatistic.PENDING +
    customReportersStatistic.WAIT_PAYMENT +
    customReportersStatistic.WAIT_PAY_CONFIRM

  const formattedUsername = task.tgUsername?.startsWith('@') ? task.tgUsername.slice(1) : task.tgUsername

  return (
    <Flex className={styles.Task} flexDirection="column" onClick={handleClick}>
      <div className={styles.Id}>{task.id}</div>
      <Flex className={styles.Header}>
        <Flex className={styles.Main} alignItems="center">
          <img className={styles.Photo} src={photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{title}</span>
            <Flex alignItems="baseline" gap={16}>
              <span className={cn(styles.Marketplace, getMarketplaceClass(marketplaceName))}>{marketplaceName}</span>
              <Collection collection={collection!} />
            </Flex>
            {(task.firstName || task.tgUsername) && (
              <Flex gap={8}>
                <Flex className={styles.PersonalInfo}>
                  <span className={styles.PersonalInfoTitle}>Селлер:</span>
                  <span className={styles.PersonalInfoValue}>{task.firstName || ''}</span>
                </Flex>
                <Flex className={styles.PersonalInfo}>
                  <span className={styles.PersonalInfoTitle}>Telegram:</span>
                  {task.tgUsername ? (
                    <a
                      href={`https://t.me/${formattedUsername}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={cn(styles.PersonalInfoValue, styles.PersonalInfoLink)}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {'@' + task.tgUsername}
                    </a>
                  ) : (
                    <span className={styles.PersonalInfoValue}>Нет</span>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        <OrderStatus
          status={status!}
          collection={collection!}
          confirmedCount={confirmedCount}
          maxParticipants={maxParticipants!}
        />
      </Flex>
      <Flex className={styles.Body} gap={20}>
        <Flex className={styles.CustomReporters} flexWrap="wrap">
          {collection === CollectionType.MANUAL && (
            <>
              {renderItem('Отклонены:', customReportersStatistic!.REJECTED)}
              {renderItem('Откликнулись:', customReportersStatistic!.PENDING)}
            </>
          )}
          {renderItem('Оформляют заказ:', customReportersStatistic!.CONFIRMED)}
          {renderItem('Ждут оплаты:', customReportersStatistic!.WAIT_PAYMENT, true)}
          {renderItem('Оплата отправлена:', customReportersStatistic!.WAIT_PAY_CONFIRM)}
          {renderItem(
            'Выполняют задание:',
            customReportersStatistic!.PAID + customReportersStatistic!.COMPLETED_STEP_2,
          )}
          {renderItem('Сдали отчет:', customReportersStatistic!.COMPLETED)}
        </Flex>
        {onStatusChange &&
          collection === CollectionType.MANUAL &&
          (status === OrderStatusType.PAUSE || status === OrderStatusType.IN_PROGRESS) && (
            <СollectionButton
              className={styles.СollectionButton}
              status={status}
              onClick={handleCollectionButtonClick}
            />
          )}

        {onCancelOrder &&
          user?.role === RoleType.CUSTOMER &&
          collection === CollectionType.AUTOMATIC &&
          status === OrderStatusType.COLLECTING && (
            <Flex gap={8} flexWrap="wrap" alignSelf="flex-end" className={styles.AutomaticBtns}>
              <button className={cn(styles.AutomaticBtn, styles.Cancel)} onClick={handleCancelOrdernButtonClick}>
                Отмена задания
                <Icon name="close" />
              </button>
              {/* <button className={cn(styles.AutomaticBtn, styles.EarlyStart)}>
                Досрочный запуск
                <Icon name="start" />
              </button> */}
            </Flex>
          )}
      </Flex>
    </Flex>
  )
}
