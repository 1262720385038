import React, { FC } from 'react'
import cn from 'classnames'
import { Accordion } from 'src/shared/ui/Accordion'
import { QUESTIONS } from '../../config/consts'

import styles from './FAQ.module.scss'

interface FAQPropsType {
  className: string
}

export const FAQ: FC<FAQPropsType> = ({ className }) => {
  return (
    <section className={cn(styles.FAQ, className)} aria-labelledby="faq-title">
      <h2 id="faq-title" className={styles.Title}>
        Частые вопросы
      </h2>
      <Accordion data={QUESTIONS} />
    </section>
  )
}
