import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Uploader, UploaderPropsType } from '../Uploader/Uploader'

export type UploaderFieldPropsType = Omit<FieldPropsType, 'children'> & UploaderPropsType

const UploaderFieldWithRef = (
  { className, kind, label, id, error, isRequired, ...rest }: UploaderFieldPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <Uploader ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const UploaderField = forwardRef(UploaderFieldWithRef)
