import { useCallback, useRef } from 'react'
import { StorageAPI } from '../api'

export const useUpload = () => {
  const uploadMap = useRef<Record<string, string>>({})

  return useCallback(async (image: File) => {
    if (!uploadMap.current[image.name]) {
      uploadMap.current[image.name] = await StorageAPI.upload(image)
    }

    return uploadMap.current[image.name]
  }, [])
}
