import React, { FC } from 'react'

import styles from './Description.module.scss'

export interface DescriptionPropsType {
  descriptionTitle: string
  descriptionText: string
}

export const Description: FC<DescriptionPropsType> = ({ descriptionTitle, descriptionText }) => {
  return (
    <div className={styles.Description}>
      <p className={styles.DescriptionTitle}>{descriptionTitle}</p>
      <p className={styles.DescriptionText}>{descriptionText}</p>
    </div>
  )
}
