import React, { FC } from 'react'
import { Section } from './Section/Section'
import { ValueField } from 'src/shared/ui/ValueField'
import { Block } from './Block/Block'

export const PendingStage: FC = () => {
  return (
    <Section title="Вы откликнулись на заказ">
      <Block>
        <ValueField label="Ожидайте подтверждения от селлера">
          Когда селлер подтвердит ваше участие, вы сможете начать выполнять заказ.
        </ValueField>
      </Block>
    </Section>
  )
}
