import React, { FC, useCallback } from 'react'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CheckboxField } from 'src/shared/ui/CheckboxField'
import { Flex } from 'src/shared/ui/Flex'
import { useUserInfo } from 'src/shared/model/useUser'

import styles from './TelegramConnectBlock.module.scss'
import { AdminAPI, CommonMessageResponse, HttpResponse } from 'src/shared/api'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

interface TelegramConnectBlockProps {
  isTgBotConnect: boolean
  setTgBotConnect: React.Dispatch<React.SetStateAction<boolean>>
}

export const TelegramConnectBlock: FC<TelegramConnectBlockProps> = ({ isTgBotConnect, setTgBotConnect }) => {
  const { user } = useUserInfo()

  const checkTgVerifyMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    undefined
  >({
    mutationFn: AdminAPI.api.usersControllerTgVerify,
  })

  const handleVerifyClick = useCallback(() => {
    const checkTgVerifyToastId = toast.loading('Проверяем подключение Telegram-бот')

    checkTgVerifyMutation.mutate(undefined, {
      onSuccess: async (response) => {
        const { status } = await response.json()

        if (status) {
          toast.success('Telegram-бот подключён! ✨', {
            id: checkTgVerifyToastId,
          })
          setTgBotConnect(Boolean(status))
          const currentData = JSON.parse(localStorage.getItem('user') || '{}')

          const updatedData = {
            ...currentData,
            hasTg: Boolean(status),
          }

          localStorage.setItem('user', JSON.stringify(updatedData))
        } else {
          toast.error('Telegram-бот не подключён', {
            id: checkTgVerifyToastId,
          })
        }
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Произошла ошибка во время проверки', {
          id: checkTgVerifyToastId,
        })
      },
    })
  }, [checkTgVerifyMutation])

  return (
    <Flex className={styles.TgConnect} flexDirection="column">
      <Flex flexDirection="column" gap={16}>
        <span className={styles.Label}>Подключите нашего Telegram-бота</span>
        <span className={styles.Text}>
          Нажмите на ссылку ниже, чтобы подключить Telegram-бота. После завершения настройки нажмите кнопку «Проверить».
          Если подключение прошло успешно, галочка появится автоматически.
        </span>

        <a
          className={styles.Link}
          href={`${process.env.REACT_APP_TG_BOT_URL}?start=userId=${user?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          Ссылка на бот
        </a>
      </Flex>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <CheckboxField id="isBotConnected" name="isBotConnected" checked={isTgBotConnect} withHiddenLabel disabled>
          Я подключил бота
        </CheckboxField>
        <CabinetActionButton kind="ghost" className={styles.CheckButton} onClick={handleVerifyClick} type="button">
          Проверить
        </CabinetActionButton>
      </Flex>
    </Flex>
  )
}
