import React, { type FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { useKey, useMount, useUnmount } from 'react-use'
import cn from 'classnames'

import { Flex } from '../Flex/Flex'
import { Icon } from '../Icon'

import styles from './Modal.module.scss'

const toggleModalOpenClassOnBody = (state: boolean) => document.body.classList.toggle(styles.isOpen, state)

interface ModalPropsType {
  className?: string
  title: string
  size?: 'big' | 'small'
  children: ReactNode
  onClose(): void
}

export const Modal: FC<ModalPropsType> = ({ className, title, size = 'big', children, onClose }) => {
  useMount(() => {
    toggleModalOpenClassOnBody(true)
  })

  useUnmount(() => {
    toggleModalOpenClassOnBody(false)
  })

  useKey('Escape', onClose, {}, [onClose])

  return createPortal(
    <Flex className={styles.Overlay} justifyContent="center" alignItems="center">
      <Flex
        className={cn(
          styles.Container,
          size === 'big' && styles.Container__big,
          size === 'small' && styles.Container__small,
          className,
        )}
        gap={20}
        flexDirection="column"
      >
        <Flex justifyContent="space-between" alignItems="center" gap={20}>
          <h2 className={styles.Title}>{title}</h2>
          <Icon className={styles.CloseIcon} name="close" onClick={onClose} />
        </Flex>
        {children}
      </Flex>
    </Flex>,
    document.body,
  )
}
