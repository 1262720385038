import React, { FC } from "react";
import cn from 'classnames'
import { ReactComponent as Star } from 'src/shared/assets/star.svg'

import styles from './ImageCard.module.scss'

interface ImageCardPropsType {
  className?: string
  image: string
  rating: string
  size: 'small' | 'big'
}

export const ImageCard: FC<ImageCardPropsType> = ({ className, image, rating, size }) => {
  return (
    <div className={cn(
      styles.ImageCard,
      size === 'big' && styles.ImageCard__big,
      size === 'small' && styles.ImageCard__small,
      className,
    )}
    style={{ backgroundImage: `url(${image})` }}
  >
      <div className={styles.Rating}><Star/>{rating}</div>
    </div>
  )
}
