import React, { FC } from 'react'

import { ValueField } from 'src/shared/ui/ValueField'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { Block } from '../Block/Block'
import { Section } from '../Section/Section'

import styles from './WaitPaymentStage.module.scss'

interface WaitPaymentStagePropsType {
  orderId: number
  responseId: number
  startTime: string
}

export const WaitPaymentStage: FC<WaitPaymentStagePropsType> = ({ startTime }) => {
  return (
    <Section title="Ожидание оплаты от селлера">
      <Block>
        <ValueField label="Селлер еще не предоставил подтверждение оплаты, средства поступят в течении следующего времени" />
        <RemainingTime
          time={startTime}
          duration={48}
          label="Оставшееся время:"
          error="Время вышло"
          className={styles.RemainingTime}
        />
      </Block>
    </Section>
  )
}
