import React, { FC } from 'react'

import { Accordion } from 'src/shared/ui/Accordion'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { QUESTIONS } from '../../config/consts'

import styles from './CabinetFAQ.module.scss'

export const CabinetFAQ: FC = () => {
  return (
    <Flex className={styles.CabinetFAQ} flexDirection="column">
      <PageTitle>Частые вопросы</PageTitle>
      <Accordion kind="cabinet" data={QUESTIONS} />
    </Flex>
  )
}
