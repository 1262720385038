import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetChangePassword } from '../CabinetChangePassword/CabinetChangePassword'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { CABINET_ROUTE } from 'src/shared/config/consts'

import styles from './CabinetSettings.module.scss'

export const CabinetSettings: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_ROUTE), [navigate])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Изменить пароль</PageTitle>
      </Flex>
      <CabinetChangePassword />
    </CabinetPageLayout>
  )
}
