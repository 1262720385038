import React from 'react'

export const BLOGGERCHECK = [
  {
    title: '1) Поиск блогеров по критериям',
    text: (
      <span className="preLineText">{`- Подбираются блогеры, соответствующие целевой аудитории вашего товара. Это
включает в себя фильтрацию по географии, интересам, возрасту, полу.
- наш сервис позволяет сортировать блогеров по количеству подписчиков,
вовлеченности, просмотрам`}</span>
    ),
  },
  {
    title: '2) Подлинность аккаунта и накрутка',
    text: (
      <span className="preLineText">{`- Проверка на наличие накрученных подписчиков (фальшивые или неактивные
аккаунты).
- Анализ вовлеченности: оцениваются лайки, комментарии, репосты и их
соотношение с количеством подписчиков.
- Проверка истории аккаунта: насколько стабильно растет число подписчиков и
просмотров.
А также каждый блогер проходит модерацию на подлинность своего аккаунта, точно
ли он сам его ведет!`}</span>
    ),
  },
  {
    title: '3) Анализ прошлых рекламных кампаний блогера',
    text: (
      <span className="preLineText">{`- Изучаются предыдущие рекламные интеграции блогера: их эффективность,
обратная связь аудитории, соответствие рекламируемых продуктов профилю блогера.`}</span>
    ),
  },
  {
    title: '4) Проверка на наличие негативных отзывов',
    text: (
      <span className="preLineText">{`- Проверяется репутация блогера в сети, включая возможные конфликты с другими
брендами, негативные отзывы о сотрудничестве.`}</span>
    ),
  },
]
