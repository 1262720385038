import React, { FC, useCallback, useState } from 'react'
import cn from 'classnames'

import { Flex } from 'src/shared/ui/Flex'

import { MediaModal } from 'src/widgets/MediaModal'

import styles from './Media.module.scss'

interface MediaPropsType {
  className?: string
  src?: string
  desciption: string
  type: 'image' | 'video'
}

export const Media: FC<MediaPropsType> = ({ className, src, desciption, type }) => {
  const [isMediaOpen, setMediaOpen] = useState(false)

  const renderContent = useCallback(() => {
    if (!src) {
      return null
    }

    const handleClick = () => {
      setMediaOpen((prev) => !prev)
    }

    return type === 'image' ? (
      <img className={styles.Image} src={src} alt={desciption} onClick={handleClick} />
    ) : (
      <video className={styles.Video} src={src} controls />
    )
  }, [src, desciption, type])

  return (
    <>
      <Flex className={cn(styles.Media, className)} flexDirection="column" alignItems="center" gap={12}>
        <div className={styles.Preview}>{renderContent()}</div>
        <span className={styles.Description}>{desciption}</span>
      </Flex>
      {isMediaOpen && <MediaModal src={src} desciption={desciption} type={type} onClose={() => setMediaOpen(false)} />}
    </>
  )
}
