import React, { FC } from 'react'

import styles from './MainImages.module.scss'

import bloggerLandingMain from 'src/shared/assets/blogger_landing_main.webp'

export const MainImages: FC = () => {
  return (
    <div className={styles.MainImages}>
      <div className={styles.ImageWrapper}>
        <img src={bloggerLandingMain} alt="" className={styles.Image} />
      </div>
    </div>
  )
}
