import React, { FC } from 'react'

import { BloggerType } from 'src/shared/config/types'

import { Avatar } from 'src/shared/ui/Avatar'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { Modal } from 'src/shared/ui/Modal'

import styles from './RejectModal.module.scss'

interface RejectModalPropsType {
  blogger: BloggerType
  onSubmit(): void
  onClose(): void
}

export const RejectModal: FC<RejectModalPropsType> = ({ blogger, onSubmit, onClose }) => {
  const { avatar, names, instagram } = blogger

  return (
    <Modal
      className={styles.RejectModal}
      title="Вы уверены, что хотите отклонить блоггера?"
      size="small"
      onClose={onClose}
    >
      <Flex flexDirection="column" gap={24}>
        <Flex gap={16}>
          <Avatar className={styles.Avatar} avatar={avatar} firstName={names} />
          <Flex flexDirection="column" gap={4}>
            <span className={styles.FirstName}>{names}</span>
            <span className={styles.instagram}>{instagram}</span>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={8}>
          <CabinetActionButton kind="danger" onClick={onSubmit}>
            Да, отклонить
          </CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onClose}>
            Нет, вернуться назад
          </CabinetActionButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
