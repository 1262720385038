import React, { ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'

import { Input, InputPropsType } from '../Input'

import styles from './Select.module.scss'
import { Icon } from '../Icon'

export interface SelectOptionType {
  value: string
  label: string
}

export interface SelectPropsType extends Pick<InputPropsType, 'className' | 'id' | 'isInvalid' | 'placeholder'> {
  value: string
  options: SelectOptionType[]
  disabled?: boolean
  onChange(value: string): void
}

export const SelectWithRef = (
  { className, id, isInvalid, placeholder, value, options, disabled = false, onChange }: SelectPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const filtredOptions = options.filter((option) => option.value !== value)
  const isEmpty = filtredOptions.length === 0

  const handleInputClick = useCallback(() => {
    if (!disabled && !isEmpty) {
      setIsOpen((prevState) => !prevState)
    }
  }, [disabled, isEmpty])

  const handleClick = useCallback((event: MouseEvent) => {
    if (selectRef.current && event.target instanceof HTMLElement && !selectRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])

  const handleSelect = useCallback(
    (value: string) => {
      onChange(value)
      setIsOpen(false)
    },
    [onChange],
  )

  const inputValue = useMemo(() => options.find((item) => item.value === value)?.label, [value, options])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <div className={cn(styles.Select, className)} ref={selectRef}>
      <Input
        id={id}
        isInvalid={isInvalid}
        placeholder={placeholder}
        autoComplete="off"
        kind="cabinet"
        value={inputValue || ''}
        readOnly
        postfix={<Icon name="arrowDown" className={cn(styles.SelectIcon, isOpen && styles.SelectIcon__isOpen)} />}
        onClick={handleInputClick}
        className={styles.Input}
        ref={forwardedRef}
      />
      {isOpen && (
        <div className={styles.Options}>
          {filtredOptions.map((item) => (
            <div key={item.value} className={styles.Option} onClick={() => handleSelect(item.value)}>
              <span className={styles.Text}>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export const Select = forwardRef(SelectWithRef)
