import React, { FC } from 'react'

import styles from './RegistrationRules.module.scss'

interface RegistrationRulesProps {
  type: 'blogger' | 'customer'
}

export const RegistrationRules: FC<RegistrationRulesProps> = ({ type }) => {
  const links = {
    blogger: {
      offer: 'https://docs.google.com/document/d/17uIeN_uP3d6MM4_8_qUldnJ8bovXsDjC',
      privacy: 'https://docs.google.com/document/d/1qGx2yNEegdfN-3yX_0zorhFSW-gDmQpP',
      consent: 'https://docs.google.com/document/d/1wHruFJeDH7I5UjY5KV4jQa8lPXnlLsbz',
    },
    customer: {
      offer: 'https://docs.google.com/document/d/17uIeN_uP3d6MM4_8_qUldnJ8bovXsDjC',
      privacy: 'https://docs.google.com/document/d/1wHruFJeDH7I5UjY5KV4jQa8lPXnlLsbz',
      consent: 'https://docs.google.com/document/d/1D9v89lvjjfzF4QaSu--wPSg0_FkyKFLX',
    },
  }

  const { offer, privacy, consent } = links[type]

  return (
    <p className={styles.Text}>
      При прохождении регистрации на нашем сайте вы автоматически соглашаетесь с нашей{' '}
      <a href={offer} target="_blank" rel="noopener noreferrer">
        Офертой
      </a>
      ,{' '}
      <a href={privacy} target="_blank" rel="noopener noreferrer">
        Политикой конфиденциальности
      </a>{' '}
      и{' '}
      <a href={consent} target="_blank" rel="noopener noreferrer">
        Согласием на обработку персональных данных
      </a>
      .
    </p>
  )
}
