import { useQuery } from '@tanstack/react-query'
import React, { FC, useMemo } from 'react'
import { AdminAPI, ProductResponseDto } from 'src/shared/api'
import { SelectFormField, SelectFormFieldPropsType } from 'src/shared/ui/SelectFormField'

type ProductSelectFormFieldPropsType = Omit<SelectFormFieldPropsType, 'options'>

export const ProductSelectFormField: FC<ProductSelectFormFieldPropsType> = (props) => {
  const { data: productsData } = useQuery({
    queryKey: ['products'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.productsControllerFindAll({ page: 1, take: 200 }),
  })

  // TODO Разработать с типом ответа
  // @ts-ignore
  const products = useMemo<ProductResponseDto[]>(() => productsData?.data?.products ?? [], [productsData])

  const options = useMemo(() => products.map(item => ({
    value: String(item.id),
    label: item.title
  })), [products])

  return (
    <SelectFormField
      options={options}
      {...props}
    />
  )
}
