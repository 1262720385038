import React, { FC } from 'react'
import { OrderResponsesResponseDto } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './CompletedStage.module.scss'

interface CompletedStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const CompletedStage: FC<CompletedStagePropsType> = ({ orderResponse }) => {
  return (
    <Flex className={styles.CompletedStage} flexDirection="column">
      <MediaSections orderResponse={orderResponse} />
    </Flex>
  )
}
