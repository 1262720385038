import { jwtDecode } from 'jwt-decode'

import { LOGIN_ROUTE } from '../config/consts'

import { Api } from './AdminAPI'
export * from './AdminAPI'
export { StorageAPI } from './StorageAPI'

const logout = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('user')
  localStorage.removeItem('fpHash')

  window.location.replace(LOGIN_ROUTE)
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const customFetch: typeof fetch = async (url, options = {}) => {
  const performRequest = async () => {
    // Получаем текущий токен из localStorage
    const token = localStorage.getItem('accessToken')
    const headers = {
      ...options.headers,
      Authorization: token,
    }

    // Выполняем запрос с текущим токеном
    // @ts-ignore
    const response = await fetch(url, { ...options, headers })

    // Проверяем, есть ли новый токен в заголовках ответа
    const newAccessToken = response.headers.get('authorization')

    if (newAccessToken) {
      const decodedToken = jwtDecode<{ exp: number }>(newAccessToken)
      const isTokenValid = decodedToken.exp > Date.now() / 1000

      if (isTokenValid) {
        // Сохраняем новый токен
        localStorage.setItem('accessToken', newAccessToken)
      }
    }

    return response
  }

  let response = await performRequest()

  if (response.status === 401) {
    await delay(500)

    const token = localStorage.getItem('accessToken')
    const decodedToken = jwtDecode(token as string)

    const tokenUpdateTime = decodedToken?.iat ? decodedToken.iat * 1000 : 0
    const now = Date.now()

    const tokenRecentlyUpdated = now - tokenUpdateTime < 15 * 1000 // Проверяем, был ли токен обновлен в последние 15 секунд

    if (tokenRecentlyUpdated) {
      // Повторяем запрос с новым токеном
      response = await performRequest()

      if (response.status === 401) {
        // Токен недействителен даже после повторной попытки, разлогиниваем пользователя
        logout()
      }
    } else {
      // Токен не был недавно обновлен, разлогиниваем пользователя
      logout()
    }
  }

  if (!response.ok) {
    const data = await response.json()

    throw { error: data }
  }

  return response
}

export const AdminAPI = new Api({
  baseUrl: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_PROXY_URL : '',
  securityWorker: () => ({
    headers: {
      Authorization: localStorage.getItem('accessToken') as string,
      Client: localStorage.getItem('fpHash') as string,
    },
  }),
  customFetch,
})
