import React, { FC, ReactNode } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import cn from 'classnames'

import { BankDetailsDto, UpdateUserByAdminDto } from 'src/shared/api'

import { Flex } from 'src/shared/ui/Flex'

import { BloggerFormContext } from '../../model/context'

import styles from './BloggerForm.module.scss'

export interface BloggerFormFields extends Omit<UpdateUserByAdminDto, 'blogTopics' & 'bankDetails'> {
  topics: { topicId: string }[]
  bankDetails: BankDetailsDto[]
  comment?: string
  isMessageSent?: boolean
  isBotConnected?: boolean
}

interface BloggerFormProps {
  isLoading: boolean
  formId: string
  methods: UseFormReturn<BloggerFormFields, unknown, undefined>
  children?: ReactNode
  className?: string
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  onSubmit: (data: BloggerFormFields) => void
}

export const BloggerForm: FC<BloggerFormProps> = ({
  isLoading,
  formId,
  methods,
  children,
  className,
  setIsUploading,
  onSubmit,
}) => {
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <BloggerFormContext.Provider value={{ methods, isLoading, setIsUploading }}>
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Flex className={cn(styles.Blocks, className)}>{children}</Flex>
        </form>
      </BloggerFormContext.Provider>
    </FormProvider>
  )
}
