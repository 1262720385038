import React, { FC }  from 'react'
import { Flex } from 'src/shared/ui/Flex/Flex'

import styles from './Order.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface OrderPropsType {
  photoUrl: string
  title: string
  price: number
  onClick(): void
}

export const Order: FC<OrderPropsType> = ({ photoUrl, title, price, onClick }) => {
  return (
    <Flex className={styles.Order} flexDirection="column" onClick={onClick}>
      <img className={styles.Photo} src={photoUrl} />
      <Flex className={styles.Info} flexDirection="column" justifyContent="space-between">
        <span className={styles.Title}>{title}</span>
        <span className={styles.Price}>{numberFormat.format(price)} ₽</span>
      </Flex>
    </Flex>
  )
}
