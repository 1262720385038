export const emailRules = {
  validate: (value: string) => {
    if (!value) {
      return 'Email обязателен для заполнения'
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      return 'Введите корректный email адрес'
    }
  },
}
