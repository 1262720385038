import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, PaymentReportDto } from 'src/shared/api'

export const useSetOrderPaymentMutation = (reportId: number, orderId: number) => {
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    PaymentReportDto
  >({
    mutationFn: (data: PaymentReportDto) => AdminAPI.api.ordersControllerSetReportPayment(reportId, orderId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders', orderId, reportId] }),
  })
}
