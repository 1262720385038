import React, { FC, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'

import { useRegistrateMutation } from 'src/shared/model/useRegistrateMutation'

import { SignUpDto } from 'src/shared/api'
import { LOGIN_ROUTE } from 'src/shared/config/consts'

import { emailRules } from 'src/shared/lib/emailRules'
import { firstNameRules } from 'src/shared/lib/firstNameRules'
import { getPasswordAgainRules } from 'src/shared/lib/getPasswordAgainRules'
import { passwordRules } from 'src/shared/lib/passwordRules'
import { phoneRules } from 'src/shared/lib/phoneRules'
import { useScrollToTop } from 'src/shared/lib/useScrollToTop'

import { AuthButton } from 'src/shared/ui/AuthButton'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { AuthBlock } from 'src/widgets/AuthBlock'
import { AuthLayout } from 'src/widgets/AuthLayout'
import { RegistrationRules } from 'src/widgets/RegistrationRules'

import styles from './CustomerRegistration.module.scss'

interface CustomerRegistrationFormFields {
  phone: string
  email: string
  password: string
  passwordAgain: string
  firstName: string
  lastName: string
  inn?: string
}

export const CustomerRegistration: FC = () => {
  const navigate = useNavigate()
  useScrollToTop()

  const methods = useForm<CustomerRegistrationFormFields>({
    defaultValues: {
      phone: '',
      email: '',
      password: '',
      passwordAgain: '',
      firstName: '',
      lastName: '',
      inn: '',
    },
  })
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  const registrateMutation = useRegistrateMutation()
  const isLoading = registrateMutation.isPending

  const onSubmit = useCallback(
    (data: CustomerRegistrationFormFields) => {
      const registrateToastId = toast.loading('Проверка данных')
      const signUpDto: SignUpDto = {
        phone: `+7${data.phone}`,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        role: 'CUSTOMER',
        customer: {
          inn: data.inn || '',
        },
      }

      registrateMutation.mutate(signUpDto, {
        onSuccess: () => {
          navigate(LOGIN_ROUTE)
          toast.success('Регистрация прошла успешно', {
            id: registrateToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Произошла во время регистрации', {
            id: registrateToastId,
          })
        },
      })
    },
    [registrateMutation, navigate, setError],
  )

  const passwordAgainRules = useMemo(() => getPasswordAgainRules(values.password), [values.password])

  const isAuthButtonDisabled = useMemo(
    () =>
      !(
        Boolean(values.phone) &&
        Boolean(values.firstName) &&
        Boolean(values.password) &&
        Boolean(values.passwordAgain)
      ) || isLoading,
    [values, isLoading],
  )

  return (
    <AuthLayout>
      <AuthBlock title="Регистрация селлера">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.InputsContainer}>
              <TextInputFormField
                id="email"
                name="email"
                label="Email"
                placeholder="Email"
                autoComplete="email"
                rules={emailRules}
                disabled={isLoading}
                isRequired
              />
              <PhoneInputFormField
                id="phone"
                name="phone"
                label="Номер телефона"
                placeholder="Номер телефона"
                rules={phoneRules}
                disabled={isLoading}
                autoComplete="tel"
                type="tel"
                isRequired
              />
              <TextInputFormField
                id="firstName"
                name="firstName"
                label="Ваше имя"
                placeholder="Имя"
                autoComplete="given-name"
                rules={firstNameRules}
                disabled={isLoading}
                isRequired
              />
              <TextInputFormField
                id="lastName"
                name="lastName"
                label="Фамилия"
                placeholder="Фамилия"
                autoComplete="family-name"
                disabled={isLoading}
              />
              <NumericInputFormField id="inn" name="inn" label="ИНН" placeholder="ИНН" disabled={isLoading} />
              <TextInputFormField
                id="password"
                name="password"
                label="Придумайте пароль"
                placeholder="Пароль"
                type="password"
                autoComplete="new-password"
                rules={passwordRules}
                disabled={isLoading}
                isRequired
              />
              <TextInputFormField
                id="passwordAgain"
                name="passwordAgain"
                label="Повторите пароль"
                placeholder="Пароль"
                type="password"
                rules={passwordAgainRules}
                disabled={isLoading}
                isRequired
              />
            </div>
            <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
              Зарегистрироваться как селлер
            </AuthButton>
            <RegistrationRules type="customer" />
          </form>
        </FormProvider>
      </AuthBlock>
    </AuthLayout>
  )
}
