import React, { FC, useCallback, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { plural } from 'src/shared/lib/plural'
import { AdminAPI, OrderListResponseDto } from 'src/shared/api'
import { Order } from '../Order/Order'
import { OrderModal } from '../OrderModal/OrderModal'

import styles from './BloggerCabinet.module.scss'

export const BloggerCabinet: FC = () => {
  const [orderId, setOrderId] = useState<number>()

  const { data: ordersData } = useQuery({
    queryKey: ['orders'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.ordersControllerFindAllOrders({ page: 1, take: 200 }),
  })

  const orders = useMemo<OrderListResponseDto[]>(() => ordersData?.data?.data ?? [], [ordersData])

  const openModal = useCallback((orderId: number) => setOrderId(orderId), [])

  const handleCloseClick = useCallback(() => setOrderId(undefined), [])

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>{`${orders.length} ${plural(['предложение', 'предложения', 'предложений'], orders.length)}`}</h1>
      <Flex className={styles.Orders} flexWrap="wrap" gap={16}>
        {orders.map(item => (
          <Order
            key={item.id}
            photoUrl={item.photoUrl!}
            title={item.title!}
            price={item.price!}
            onClick={() => openModal(item.id)}
          />
        ))}
      </Flex>
      {orderId && <OrderModal orderId={orderId} onClose={handleCloseClick} />}
    </CabinetPageLayout>
  )
}
