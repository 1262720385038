import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import { useUpload } from 'src/shared/model/useUpload'

import { UpdateOrderResponseDto } from 'src/shared/api'

import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { DatePickerInput } from 'src/shared/ui/DatePickerInput'
import { Field } from 'src/shared/ui/Field'
import { Flex } from 'src/shared/ui/Flex'
import { Uploader } from 'src/shared/ui/Uploader'
import { ValueField } from 'src/shared/ui/ValueField'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { Block } from '../Block/Block'
import { Section } from '../Section/Section'

import styles from './PaidStage.module.scss'

interface PaidStagePropsType {
  orderId: number
  responseId: number
  startTime: string
}

export const PaidStage: FC<PaidStagePropsType> = ({ orderId, responseId, startTime }) => {
  const upload = useUpload()
  const [isUploading, setIsUploading] = useState(false)

  const [myPurchasesImage, setMyPurchasesImage] = useState<string>()
  const [shtrihcodeImage, setShtrihcodeImage] = useState<string>()
  const [adDate, setAdDate] = useState<Date | null>(null)

  const { mutate: updateOrderResponse, isPending: isUpdateOrderResponseLoading } = useUpdateOrderResponseMutation(
    orderId,
    responseId,
  )

  const handleButtonClick = useCallback(async () => {
    const updateOrderToastId = toast.loading('Проверяем данные')

    const updateOrderResponseData = {
      step: 2,
      data: {
        screenshotMyPurchases: myPurchasesImage,
        screenshotShtrihcode: shtrihcodeImage,
        adDate: adDate?.toLocaleDateString('ru-RU'),
      } as UpdateOrderResponseDto,
    }

    updateOrderResponse(updateOrderResponseData, {
      onSuccess: () => {
        toast.success('Данные успешно отправлены', { id: updateOrderToastId })
      },
      onError: (data) => {
        toast.error(data.error?.message || 'Ошибка при загрузке файлов', { id: updateOrderToastId })
      },
    })
  }, [myPurchasesImage, shtrihcodeImage, adDate])

  const uploadMedia = useCallback(async (file: File, media: 'myPurchasesImage' | 'shtrihcodeImage') => {
    const uploadMediaToastId = toast.loading('Загружаем файл')
    setIsUploading(true)

    try {
      const uploadedFile = await upload(file)

      switch (media) {
        case 'myPurchasesImage':
          setMyPurchasesImage(uploadedFile)
          break
        case 'shtrihcodeImage':
          setShtrihcodeImage(uploadedFile)
          break
        default:
          break
      }

      toast.success('Файл загружен', {
        id: uploadMediaToastId,
      })
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке файла', {
        id: uploadMediaToastId,
      })
    } finally {
      setIsUploading(false)
    }
  }, [])

  const calculateMaxDate = useCallback(() => {
    const date = new Date()
    date.setDate(date.getDate() + 15)
    return date
  }, [])

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const isButtonDisabled = isLoading || !myPurchasesImage || !shtrihcodeImage || !adDate

  return (
    <Section title="2 раздел. Выкуп">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            В личном кабинете маркетплейса сделайте скриншот из раздела «мои покупки», где будет видно, что вы выкупили
            товар и прикрепите данный скриншот. Порежьте все штрих-кода, а также упаковки товара и загрузите данное фото
            в соответсвующий раздел. Укажите дату выхода рекламы
          </ValueField>
          <Field id="image" label="Прикрепите скрин раздела покупок:" kind="cabinet">
            <Uploader
              type="image"
              file={myPurchasesImage}
              onChange={(file) => uploadMedia(file, 'myPurchasesImage')}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
          <Field id="image" label="Прикрепите фото разрезанного штрихкода:" kind="cabinet">
            <Uploader
              type="image"
              file={shtrihcodeImage}
              onChange={(file) => uploadMedia(file, 'shtrihcodeImage')}
              isDisabled={isLoading}
            />
          </Field>
          <Field id="date" label="Укажите дату выхода рекламы:" kind="cabinet">
            <DatePickerInput value={adDate} onChange={(date) => setAdDate(date)} maxDate={calculateMaxDate()} />
          </Field>
          <RemainingTime time={startTime} duration={168} label="Оставшееся время:" error="Время вышло" />
        </Block>
        <CabinetButton className={styles.CabinetButton} onClick={handleButtonClick} disabled={isButtonDisabled}>
          Отправить
        </CabinetButton>
      </Flex>
    </Section>
  )
}
