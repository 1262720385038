import React, { ReactElement } from 'react'
import type { FieldValues } from 'react-hook-form'

import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'

import { ReactHookFormFieldType } from 'src/shared/config/types'

import { UploaderField, UploaderFieldPropsType } from '../UploaderField'

type UploaderFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = Omit<
  ReactHookFormFieldType<TFieldValues, UploaderFieldPropsType>,
  'file'
> & {
  onChange?: (file: File) => void
}

export const UploaderFormField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  ...props
}: UploaderFormFieldPropsType<TFieldValues>): ReactElement<UploaderFormFieldPropsType<TFieldValues>> => {
  const { field, error } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return <UploaderField {...field} file={field.value} error={error} {...props} />
}
