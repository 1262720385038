import React, { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import cn from 'classnames'

import { AdminAPI } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomReporterType } from 'src/shared/config/types'

import { getMarketplaceClass } from 'src/shared/lib/getMarketplaceClass'
import { getPriceRange } from 'src/shared/lib/getPriceRange'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex'
import { ValueField } from 'src/shared/ui/ValueField'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { Block } from '../Block/Block'
import { CompletedSecondStepStage } from '../CompletedSecondStepStage/CompletedSecondStepStage'
import { ConfirmedStage } from '../ConfirmedStage/ConfirmedStage'
import { PaidStage } from '../PaidStage/PaidStage'
import { PendingStage } from '../PendingStage'
import { Section } from '../Section/Section'
import { WaitPayConfirmStage } from '../WaitPayConfirmStage/WaitPayConfirmStage'
import { WaitPaymentStage } from '../WaitPaymentStage/WaitPaymentStage'

import styles from './BloggerCabinetTask.module.scss'

export const BloggerCabinetTask: FC = () => {
  const { taskId } = useParams()
  const orderId = Number(taskId)

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const { data: ordersData } = useQuery({
    queryKey: ['orders', 'my', orderId],
    queryFn: () => AdminAPI.api.ordersControllerFindOneBlogerReport(orderId),
  })
  const order = ordersData?.data

  const renderStage = useCallback(() => {
    if (!order) {
      return null
    }

    if (!order.customReporters) {
      return null
    }

    const reportStatus = order.customReporters.status
    const responseId = order.customReporters.id
    const paymentScreenshot = order.customReporters.paymentScreenshot

    if (!responseId) {
      return null
    }

    if (reportStatus === CustomReporterType.PENDING) {
      return <PendingStage />
    }

    if (reportStatus === CustomReporterType.CONFIRMED) {
      return (
        <ConfirmedStage
          orderId={orderId}
          responseId={responseId}
          startTime={order.customReporters.updateAt || ''}
          comment={order.customReporters.comment}
        />
      )
    }

    if (reportStatus === CustomReporterType.WAIT_PAYMENT) {
      return (
        <WaitPaymentStage
          orderId={orderId}
          responseId={responseId}
          startTime={order.customReporters.firstStageTime || ''}
        />
      )
    }

    if (reportStatus === CustomReporterType.WAIT_PAY_CONFIRM) {
      return (
        <WaitPayConfirmStage
          orderId={orderId}
          responseId={responseId}
          paymentScreenshot={paymentScreenshot}
          startTime={order.customReporters.updateAt || ''}
        />
      )
    }

    if (reportStatus === CustomReporterType.PAID) {
      return <PaidStage orderId={orderId} responseId={responseId} startTime={order.customReporters.updateAt || ''} />
    }

    if (reportStatus === CustomReporterType.COMPLETED_STEP_2) {
      return (
        <CompletedSecondStepStage
          orderId={orderId}
          responseId={responseId}
          customReporters={order.customReporters}
          startTime={order.customReporters.secondStageTime || ''}
        />
      )
    }

    return null
  }, [order, orderId])

  if (!order) {
    return null
  }

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex className={styles.Main} alignItems="center">
          <img className={styles.Photo} src={order.photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{order.title}</span>
            <Collection collection={order.collection!} />
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.Body}>
        <Section title="Техническое задание">
          <Block className={styles.Block}>
            <ValueField
              label="Маркетплейс:"
              classname={cn(styles.marketplaceName, getMarketplaceClass(order.marketplaceName))}
            >
              {order.marketplaceName}
            </ValueField>
            <ValueField label="Артикул:">{order.article}</ValueField>
            <ValueField label="Цена для фильтра:">{getPriceRange(order.price!)}</ValueField>
            <ValueField label="Ключевое слово для выкупа товара:">{order.keyWord}</ValueField>
            <ValueField label="Требования к отзыву на маркетплейсе:">{order.commentMarketplace}</ValueField>
            <ValueField label="Требования к рекламной интеграции:">{order.commentIntegration}</ValueField>
          </Block>
        </Section>
        {renderStage()}
      </Flex>
    </CabinetPageLayout>
  )
}
