import { FC } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import cn from 'classnames'
import { ru } from 'date-fns/locale/ru'

import { Icon } from '../Icon'
import { Input } from '../Input'
registerLocale('ru', ru)

import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePickerInput.module.scss'

interface DatePickerInputProps {
  value: Date | null
  minDate?: Date | undefined
  maxDate?: Date | undefined
  onChange: (date: Date | null) => void
}

export const DatePickerInput: FC<DatePickerInputProps> = ({ value, minDate = new Date(), maxDate, onChange }) => {
  return (
    <div className={styles.Container}>
      <DatePicker
        locale="ru"
        className={styles.DatePicker}
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date) => onChange(date)}
        dateFormat="d MMMM, yyyy"
        customInput={
          <Input
            kind="cabinet"
            value=""
            postfix={
              <div className={styles.Icon}>
                <Icon name="calendar" />
              </div>
            }
            className={cn(styles.Input)}
          />
        }
      />
    </div>
  )
}
