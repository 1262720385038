import { useCallback } from 'react'

import { UserStatusType } from 'src/shared/config/types'

import { BloggerFormFields } from 'src/widgets/BloggerForm/ui/BloggerForm/BloggerForm'

interface useVerificationRequestProps {
  formData?: BloggerFormFields
  setFormData: React.Dispatch<React.SetStateAction<BloggerFormFields | undefined>>
  updateUser: (
    data: BloggerFormFields,
    status: UserStatusType,
    message: { loading: string; success: string; error: string },
  ) => void
}

export const useVerificationRequest = ({ formData, setFormData, updateUser }: useVerificationRequestProps) => {
  const activateUser = useCallback(
    (data: BloggerFormFields) => {
      const message = {
        loading: 'Подтверждаем пользователя',
        success: 'Пользователь подтверждён! ✨',
        error: 'Ошибка при подтверждении пользователя',
      }

      updateUser(data, UserStatusType.ACTIVATED, message)
    },
    [updateUser],
  )

  const handleReject = useCallback((data: BloggerFormFields) => setFormData(data), [])

  const handleRejectModalSendForImprovement = useCallback(
    (comment: string) => {
      if (formData) {
        const message = {
          loading: 'Отправляем пользователя на доработку',
          success: 'Пользователь отправлен на доработку!',
          error: 'Ошибка при отправке пользователя на доработку',
        }

        updateUser({ ...formData, comment }, UserStatusType.INCOMPLETE_DATA, message)
        setFormData(undefined)
      }
    },
    [formData, updateUser],
  )

  const handleRejectModalBlock = useCallback(() => {
    if (formData) {
      const message = {
        loading: 'Отклоняем пользователя',
        success: 'Пользователь отклонён!',
        error: 'Ошибка при отклонении пользователя',
      }

      updateUser(formData, UserStatusType.CANCELED, message)
      setFormData(undefined)
    }
  }, [formData, updateUser])

  const handleRejectModalClose = useCallback(() => setFormData(undefined), [])

  return {
    activateUser,
    handleReject,
    handleRejectModalSendForImprovement,
    handleRejectModalBlock,
    handleRejectModalClose,
  }
}
