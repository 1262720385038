import React, { ChangeEventHandler, FC, FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useMarketplaces } from 'src/shared/model/useMarketplaces'

import { AdminAPI, CommonMessageResponse, CreateMarketplaceDto, HttpResponse } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'
import { Icon } from 'src/shared/ui/Icon'
import { Input } from 'src/shared/ui/Input'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import styles from './AdminCabinetMarketplaces.module.scss'

export const AdminCabinetMarketplaces: FC = () => {
  const [isAddNewMarketplace, setIsAddNewMarketplace] = useState(false)
  const [newMarketplaceName, setNewMarketplaceName] = useState('')

  const queryClient = useQueryClient()

  const marketplaces = useMarketplaces()

  const { mutate: addMarketplaceMutation, isPending: isAddMarketplaceLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateMarketplaceDto
  >({
    mutationFn: AdminAPI.api.managementControllerCreateMarketplace,
  })

  const { mutate: removeMarketplaceMutation } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: AdminAPI.api.managementControllerRemoveMarketplace,
  })

  const addMarketplace = useCallback(() => {
    if (!newMarketplaceName) {
      return
    }
    const addMarketplaceToastId = toast.loading('Добавляем маркетплейс')

    addMarketplaceMutation(
      { name: newMarketplaceName },
      {
        onSuccess: () => {
          setIsAddNewMarketplace(false)
          setNewMarketplaceName('')
          queryClient.invalidateQueries({ queryKey: ['marketplaces'] })
          toast.success('Новый маркетплейс добавлен! ✨', {
            id: addMarketplaceToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при создании маркетплейса', {
            id: addMarketplaceToastId,
          })
        },
      },
    )
  }, [newMarketplaceName, addMarketplaceMutation])

  const removeMarketplace = useCallback(
    (id: number) => {
      const removeMarketplaceToastId = toast.loading('Удаляем маркетплейс')

      removeMarketplaceMutation(id, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['marketplaces'] })
          toast.success('Маркетплейс успешно удалён!', {
            id: removeMarketplaceToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при удалении маркетплейса', {
            id: removeMarketplaceToastId,
          })
        },
      })
    },
    [removeMarketplaceMutation],
  )

  const handleNewMarketplaceNameInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewMarketplaceName(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      addMarketplace()
    },
    [addMarketplace],
  )

  const handleAddButtonClick = useCallback(() => setIsAddNewMarketplace(true), [])

  const handleCancelButtonClick = useCallback(() => {
    setIsAddNewMarketplace(false)
    setNewMarketplaceName('')
  }, [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <PageTitle>Маркетплейсы</PageTitle>
      <Flex className={styles.Marketplaces} flexDirection="column" gap={8}>
        {marketplaces.map((item) => (
          <Flex key={item.name} alignItems="center" gap={20}>
            <Input kind="cabinet" value={item.name} readOnly />
            <Icon className={styles.CloseIcon} name="close" onClick={() => removeMarketplace(item.id)} />
          </Flex>
        ))}
        {isAddNewMarketplace ? (
          <form onSubmit={handleSubmit}>
            <Flex alignItems="center" gap={20}>
              <Input
                kind="cabinet"
                value={newMarketplaceName}
                onChange={handleNewMarketplaceNameInputChange}
                disabled={isAddMarketplaceLoading}
                autoFocus
              />
              <Icon className={styles.CloseIcon} name="close" onClick={handleCancelButtonClick} />
            </Flex>
          </form>
        ) : (
          <CabinetActionButton className={styles.AddButton} kind="ghost" onClick={handleAddButtonClick}>
            Добавить маркетплейс
          </CabinetActionButton>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
