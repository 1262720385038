import React, { FC } from 'react'
import cn from 'classnames'

import product1 from 'src/shared/assets/product_1.webp'
import product2 from 'src/shared/assets/product_2.webp'
import product3 from 'src/shared/assets/product_3.webp'
import product4 from 'src/shared/assets/product_4.webp'

import styles from './Products.module.scss'

interface ProductsPropsType {
  className: string
}

export const Products: FC<ProductsPropsType> = ({ className }) => {
  return (
    <section className={cn(styles.Products, className)} aria-label="Товары">
      <ul className={styles.ProductsList}>
        <li className={styles.Item}>
          <img className={styles.ItemImage} src={product1} alt="Куртка" />
          <strong className={styles.ItemTitle}>Куртка</strong>
          <span className={styles.ItemPrice}>Цена</span>
          <button className={styles.ItemButton}>Получить</button>
        </li>
        <li className={styles.Item}>
          <img className={styles.ItemImage} src={product2} alt="Топик" />
          <strong className={styles.ItemTitle}>Топик</strong>
          <span className={styles.ItemPrice}>Цена</span>
          <button className={styles.ItemButton}>Получить</button>
        </li>
        <li className={styles.Item}>
          <img className={styles.ItemImage} src={product3} alt="Органайзер" />
          <strong className={styles.ItemTitle}>Органайзер</strong>
          <span className={styles.ItemPrice}>Цена</span>
          <button className={styles.ItemButton}>Получить</button>
        </li>
        <li className={styles.Item}>
          <img className={styles.ItemImage} src={product4} alt="Топик" />
          <strong className={styles.ItemTitle}>Топик</strong>
          <span className={styles.ItemPrice}>Цена</span>
          <button className={styles.ItemButton}>Получить</button>
        </li>
      </ul>
    </section>
  )
}
