import React, { FC, useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'

import { useUpload } from 'src/shared/model/useUpload'
import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, CreateProductDto, HttpResponse } from 'src/shared/api'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'

import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { ProductForm as BaseProductForm, ProductFormFields, useProductForm } from 'src/widgets/ProductForm'

import { TelegramConnectBlock } from '../TelegramConnectBlock/TelegramConnectBlock'

import styles from './CabinetProductsNew.module.scss'

export const CabinetProductsNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_PRODUCTS_ROUTE), [navigate])
  const { user } = useUserInfo()

  const hasUserTgId = user?.hasTg || false
  const [isTgBotConnect, setTgBotConnect] = useState(hasUserTgId)

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const { methods, isInputsInvalid } = useProductForm()
  const { handleSubmit } = methods

  const createProductMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateProductDto
  >({
    mutationFn: AdminAPI.api.productsControllerCreate,
  })
  const isLoading = createProductMutation.isPending || isUploading

  const onSubmit = useCallback(
    async (data: ProductFormFields) => {
      const createProductToastId = toast.loading('Загружаем изображение')

      try {
        let photoUrl: string
        if (typeof data.photo === 'string') {
          photoUrl = data.photo
        } else {
          setIsUploading(true)
          photoUrl = await upload(data.photo)
          setIsUploading(false)
        }

        toast.loading('Проверяем данные', {
          id: createProductToastId,
        })

        const createProductDto: CreateProductDto = {
          topics: data.topics.filter((item) => item.topicId).map((item) => ({ topicId: Number(item.topicId) })),
          marketplaceUrl: data.marketplaceUrl,
          marketplaceId: Number(data.marketplaceId),
          title: data.title,
          article: data.article,
          price: Number(data.price),
          photoUrl,
        }

        createProductMutation.mutate(createProductDto, {
          onSuccess: () => {
            toast.success('Товар успешно добавлен!', {
              id: createProductToastId,
            })
            navigate(CABINET_PRODUCTS_ROUTE, { replace: true })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Ошибка при создании товара', {
              id: createProductToastId,
            })
          },
        })
      } catch (error) {
        toast.error(String(error) || 'Ошибка при создании товара', { id: createProductToastId })
        console.error(error)
        setIsUploading(false)
      }
    },
    [createProductMutation, navigate],
  )

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Добавление товара</PageTitle>
      </Flex>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <BaseProductForm isDisabled={isLoading} />
          {!hasUserTgId && <TelegramConnectBlock isTgBotConnect={isTgBotConnect} setTgBotConnect={setTgBotConnect} />}
          <CabinetButton className={styles.CabinetButton} disabled={isInputsInvalid || !isTgBotConnect}>
            Добавить товар
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
