import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'
import { Modal } from 'src/shared/ui/Modal'

import styles from './CommentsModal.module.scss'

interface CommentsModalProps {
  onClose(): void
  comments?: string[]
  from: 'blogger' | 'customer'
}

export const CommentsModal: FC<CommentsModalProps> = ({ comments, from, onClose }) => {
  return (
    <Modal
      className={styles.Modal}
      title={`Комментарии от ${from === 'blogger' ? 'блогера' : 'селлера'}`}
      size="small"
      onClose={onClose}
    >
      <Flex flexDirection="column" gap={8} className={styles.Comments}>
        {comments?.map((comment, index) => {
          return (
            <div className={styles.Comment} key={index}>
              {comment}
            </div>
          )
        })}
      </Flex>
    </Modal>
  )
}
