import React, { InputHTMLAttributes, forwardRef, ForwardedRef } from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './Checkbox.module.scss'

export interface CheckboxPropsType extends InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean
}

const CheckboxWithRef = (
  { className, children, disabled, isInvalid, ...props }: CheckboxPropsType,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { checked = false } = props

  const iconName = checked ? 'checkbox-true' : 'checkbox-false'

  return (
    <label className={cn(styles.Checkbox, disabled && styles.Checkbox_disabled, className)}>
      <input className={styles.Input} type="checkbox" disabled={disabled || isInvalid} ref={ref} {...props} />
      <Icon className={styles.Icon} name={iconName} />
      {children && <span className={styles.Text}>{children}</span>}
    </label>
  )
}

export const Checkbox = forwardRef(CheckboxWithRef)
