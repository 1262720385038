import React, { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'

import { useUpload } from 'src/shared/model/useUpload'
import { useUserInfo } from 'src/shared/model/useUser'

import { AdminAPI, CommonMessageResponse, HttpResponse, UpdateUserDto } from 'src/shared/api'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { ProfileAvatarForm } from 'src/widgets/ProfileAvatarForm'

import styles from './AdminProfileForm.module.scss'

interface AdminPersonalFormFields {
  avatar: File | string
  firstName: string
  lastName: string
  phone: string
}

export const AdminProfileForm: FC = () => {
  const [isUploading, setIsUploading] = useState(false)
  const { user, logout } = useUserInfo()
  const upload = useUpload()

  const methods = useForm<AdminPersonalFormFields>({
    defaultValues: {
      avatar: user?.avatar || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phone: user?.phone || '',
    },
  })
  const { handleSubmit } = methods

  const updateAdminMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateUserDto
  >({
    mutationFn: AdminAPI.api.usersControllerUpdate,
  })

  const onSubmit = useCallback(async (data: AdminPersonalFormFields) => {
    const updateBloggerToastId = toast.loading('Загружаем изображение')
    try {
      let avatar: string

      if (typeof data.avatar === 'string') {
        avatar = data.avatar
      } else {
        setIsUploading(true)
        avatar = await upload(data.avatar)
        setIsUploading(false)
      }

      const updateUserDto = { avatar }

      toast.loading('Обновляем данные', {
        id: updateBloggerToastId,
      })

      updateAdminMutation.mutate(updateUserDto, {
        onSuccess: () => {
          toast.success('Данные обновлены! ✨', {
            id: updateBloggerToastId,
          })
          const currentData = JSON.parse(localStorage.getItem('user') || '{}')

          const updatedData = {
            ...currentData,
            avatar,
          }

          localStorage.setItem('user', JSON.stringify(updatedData))
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Произошла ошибка при обновлении личной информации', {
            id: updateBloggerToastId,
          })
        },
      })
    } catch (error) {
      console.error(error)
      toast.error(String(error) || 'Произошла ошибка при обновлении личной информации', {
        id: updateBloggerToastId,
      })
      setIsUploading(false)
    }
  }, [])

  const isLoading = updateAdminMutation.isPending || isUploading

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
        <div className={styles.FormInputs}>
          <ProfileAvatarForm name="avatar" firstName={user?.firstName} />
          <TextInputFormField id="firstName" name="firstName" label="Имя" kind="cabinet" disabled />
          <TextInputFormField id="lastname" name="lastname" label="Фамилия" kind="cabinet" disabled />
          <PhoneInputFormField id="phone" name="phone" label="Номер телефона" kind="cabinet" disabled />
        </div>
        <Flex alignItems="center" justifyContent="space-between" className={styles.Footer}>
          <CabinetActionButton kind="ghost" className={styles.ExitButton} onClick={logout} type="button">
            Выйти из профиля
          </CabinetActionButton>
          <CabinetButton className={styles.CabinetButton} disabled={isLoading}>
            Сохранить
          </CabinetButton>
        </Flex>
      </form>
    </FormProvider>
  )
}
