import React, { FC } from 'react'

import { Flex } from 'src/shared/ui/Flex'
import { ReactComponent as Checkbox } from 'src/shared/assets/checkbox.svg'

import styles from './TariffsBlock.module.scss'

export const TariffsBlock: FC = () => {
  return (
    <Flex flexDirection="column" className={styles.Tariffs}>
      <h2 className={styles.Tariffs__title}>Тарифы</h2>
      <Flex flexDirection="column" gap={16} className={styles.TariffsList}>
        <div className={styles.TariffsItem}>
          <Flex flexDirection="column" gap={8} alignItems="flex-start">
            <p className={styles.TariffsItem__Title}>Базовый</p>
            <p className={styles.TariffsItem__Integrations}>20 интеграций</p>
          </Flex>
          <strong className={styles.TariffsItem__Price}>500 ₽</strong>
        </div>
        <div className={styles.TariffsItem}>
          <Flex flexDirection="column" gap={8} alignItems="flex-start">
            <p className={styles.TariffsItem__Title}>Стандартный</p>
            <p className={styles.TariffsItem__Integrations}>21 - 100 интеграций</p>
          </Flex>
          <strong className={styles.TariffsItem__Price}>400 ₽</strong>
        </div>
        <div className={styles.TariffsItem}>
          <Flex flexDirection="column" gap={8} alignItems="flex-start">
            <p className={styles.TariffsItem__Title}>Максимум</p>
            <p className={styles.TariffsItem__Integrations}>100+ интеграций</p>
          </Flex>
          <strong className={styles.TariffsItem__Price}>300 ₽</strong>
        </div>
      </Flex>
      <ul className={styles.BenefitsList}>
        <li className={styles.BenefitsItem}>
          <Checkbox className={styles.BenefitsItem__Checkbox} />
          <span>Reels & stories у блогера</span>
        </li>
        <li className={styles.BenefitsItem}>
          <Checkbox className={styles.BenefitsItem__Checkbox} />
          <span>Отзыв с фото</span>
        </li>
        <li className={styles.BenefitsItem}>
          <Checkbox className={styles.BenefitsItem__Checkbox} />
          <span>Выкуп по ключевой фразе</span>
        </li>
      </ul>
    </Flex>
  )
}
