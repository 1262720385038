import React, { FC, ReactNode } from 'react'
import { Flex } from 'src/shared/ui/Flex'

import styles from './Section.module.scss'

interface SectionPropsType {
  title: string
  children: ReactNode
}

export const Section: FC<SectionPropsType> = ({ title, children }) => {
  return (
    <Flex className={styles.Section} flexDirection="column" isFluid>
      <h1 className={styles.Title}>{title}</h1>
      {children}
    </Flex>
  )
}
