/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RoleType, СustomReportersDto } from '../config/types'

export type CustomerProfileDto = SignInUserResponseDto & {
  total: number
  transactions: number
  inn: string
}

export type BloggerProfileDto = SignInUserResponseDto & {
  email: string
  /** Заполненность данных для блогера */
  isFullInfo: boolean
  /** Телеграм username */
  tgUsername: string
  /** Ссылка на инстаграмм */
  instagram: string
  /** Город проживания */
  city: string
  /** Страна */
  country: string
  /** Количество подписчиков */
  followersCount: number
  /** Количество охватов */
  coveragesCount: number
  /** Информация по банковским данным */
  bankDetails: BankDetailsDto[]
  /** Тематики */
  topics: TopicResponseDto[]
  /** Информация по банковским данным */
  verifyInfo: VerifyInfoResponseDto
  lastUpdateAt: string
}

export interface CustomerInfoDto {
  /** ИНН заказчика */
  inn: string
}

export interface SignUpDto {
  /** Почта пользователя */
  email: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Имя пользователя */
  avatar?: string
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /**
   * User role - "CUSTOMER" | "BLOGER"
   * @default "CUSTOMER"
   */
  role: string
  /** Information about the customer, the filling is checked if the role "CUSTOMER" */
  customer: CustomerInfoDto
}

export interface CommonMessageResponse {
  /** Текст сообщения, предоставляющий информацию или обратную связь. */
  message?: string
  /** Необязательный код ошибки для идентификации конкретных проблем. */
  code?: number
}

export interface SignInDto {
  /** Почта пользователя */
  email: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Уникальный ключ клиента */
  client: string
}

export interface SignInUserResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Телефон пользователя */
  phone: string
  /** URL аватара пользователя */
  avatar?: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /** Статус пользователя */
  status: string
  /** Роль пользователя в системе */
  role: RoleType
}

export interface SignInResponseDto {
  /** Первый токен доступа для авторизации пользователя */
  accessToken: string
  /** Информация о пользователе, вошедшем в систему */
  user: SignInUserResponseDto
}

export interface ChangePasswordDto {
  /**
   * Новый пароль
   * @default "QWErty321"
   */
  newPassword: string
  /**
   * Старый пароль
   * @default "QWErty123"
   */
  oldPassword: string
}

export interface UserEmailDto {
  /**
   * Email
   * @example "example@marketiq.ru"
   */
  email: string
}

export interface AuthResetPasswordDto {
  /** Hash */
  hash: string
  /**
   * Старый пароль
   * @default "QWErty123"
   */
  password: string
}

export interface BankDetailsDto {
  /** Уникальный идентификатор записи */
  id: number
  /** Наименование банка */
  bankName: string
  /** Номер счета */
  accountNumber: string
  /**
   * Номер телефона счета пользователя
   * @example "+79931234567"
   */
  accountPhone: string
  /** Номер счета */
  names: string
}

export interface Topic {
  /** Id темы */
  topicId: number
}

export interface Topics {
  /** Перечисление тем, интересных блогеру для ДОБАВЛЕНИЯ */
  create?: Topic[]
  /** Перечисление тем, интересных блогеру для УДАЛЕНИЯ */
  delete?: Topic[]
}

export interface VerifyInfo {
  /** Описание статистики видео */
  videoStatistics?: string
  /** Описание статистики по полу */
  genderStatistics?: string
  /** Описание статистики по странам */
  countriesStatistics?: string
  /** Описание статистики последней истории */
  lastStoryStatistics?: string
  /** Описание статистики публикаций */
  publicationStatistics?: string
}

export interface UpsertBloggerDto {
  /** Аватар пользователя */
  avatar?: string
  /** Ссылка на инстаграм блогера */
  instagram?: string
  /** Город проживания */
  city?: string
  /** Страна проживания */
  country?: string
  /** Количество подписчиков */
  followersCount?: number
  /** Средний охват */
  coveragesCount?: number
  /** Информация по банковским реквизитам */
  bankDetails?: BankDetailsDto[]
  /** Интересуюшие тематики */
  blogTopics?: Topics
  /** Данные для верификации пользователя */
  verifyInfo?: VerifyInfo
}

export interface UpdateCustomerDto {
  /** URL изображения аватара клиента */
  avatar?: string
  /** Имя клиента */
  firstName?: string
  /** Фамилия клиента */
  lastName?: string
  /** ИНН клиента */
  inn?: string
}

export interface ExcelReportResponseDto {
  /** Ссылка на excel файл с отчетностями на облаке */
  link: string
}

export interface UserActivateDto {
  /**
   * Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED"
   * @default "NOT_ACTIVATED"
   */
  status?: string
  /** Причина отказа */
  сomment?: string
}

export interface PaginateResultDto {
  count: number
  total: number
  data: string[]
}

export interface UserListResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** URL аватара пользователя */
  avatar?: string
  /** Телефонный номер пользователя */
  phone: string
  /** Имя пользователя */
  firstName: string
  /** Статус пользователя */
  status: string
  /** Роль пользователя */
  role: string
  /** Имя пользователя в Telegram */
  tgUsername?: string
  /** Ссылка на профиль Instagram */
  instagram?: string
  /** Количество охватов */
  coveragesCount?: number
  /** Количество подписчиков */
  followersCount?: number
  /** Темы, которые интересуют пользователя */
  topics?: string[]
  /** ИНН пользователя */
  inn?: string
  /** Количество заказов */
  orders?: number
  /** Количество продуктов */
  products?: number
  /** Количество интеграций на счету */
  total?: number
}

export interface ApproveChangesDto {
  /** Статус изменений */
  status: string
}

export interface ApproveBlogerChangesResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /** Аватар пользователя */
  avatar: string
  /** Инстаграм блогера */
  instagram: string
  /** Имя */
  firstName: string
  /** Фамилия */
  lastName: string
  /** Телефон */
  phone: string
  /** Электронная почта */
  email: string
  /** Новое количество подписчиков */
  newFollowersCount: number
  /** Новый средний охват */
  newCoveragesCount: number
  /** Новая статистика публикаций */
  newPublicationStatistics: string
  /** Новая статистика последнего сториз */
  newLastStoryStatistics: string
  /** Новая статистика стран */
  newCountriesStatistics: string
  /** Новая статистика полов */
  newGenderStatistics: string
  /** Новая статистика по видео */
  newVideoStatistics: string
  /** Старое количество подписчиков */
  oldFollowersCount: number
  /** Старый средний охват */
  oldCoveragesCount: number
  /** Старая статистика публикаций */
  oldPublicationStatistics: string
  /** Старая статистика последнего сториз */
  oldLastStoryStatistics: string
  /** Старая статистика стран */
  oldCountriesStatistics: string
  /** Старая статистика полов */
  oldGenderStatistics: string
  /** Старая статистика по видео */
  oldVideoStatistics: string
}

export interface VerifyInfoResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Видео статистики за 7,14,30 дней */
  videoStatistics?: string
  /** Скриншот статистики возраста и пола аудитории */
  genderStatistics?: string
  /** Скриншот статистики по городам */
  countriesStatistics?: string
  /** Скриншот статистики  последней истории */
  lastStoryStatistics?: string
  /** Скриншот статистики последнего поста */
  publicationStatistics?: string
}

export interface BlogerResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Ссылка на инстаграмм */
  instagram?: string
  /** Город проживания */
  city?: string
  /** Идентификатор заявки на изменение данных блогера */
  approveId?: number
  /** Страна */
  country?: string
  /** Количество подписчиков */
  followersCount: number
  /** Количество охватов */
  coveragesCount: number
  /** Информация по банковским данным */
  bankDetails: string[]
  /** Тематики */
  topics: string[]
  /** Информация по банковским данным */
  verifyInfo: VerifyInfoResponseDto
  /** Информация по банковским данным */
  orders: string[]
}

export interface CustomerResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** ИНН заказчика */
  inn?: string
  /** Актуальный баланс */
  total?: number
  /** количество продуктов */
  prductsCount?: number
  /** Количество заказов */
  orderCount?: number
  /** Товары заказчика(с пагинацией) */
  products?: string[]
  /** Заказы заказчика(с пагинацией) */
  orders?: string[]
}

export interface UserResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** avatar */
  avatar?: string
  /** Роль */
  role: string
  /** E-mail */
  email: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /** Номер телефона пользователя */
  phone: string
  /** Телеграм username */
  tgUsername?: string
  /** Статус состояния пользователя */
  status: string
  /** Информация блогера */
  bloger?: BlogerResponseDto
  /** Информация заказчика */
  customer?: CustomerResponseDto
}

export interface UpdateUserByAdminDto {
  /**
   * Email
   * @example "example@marketiq.ru"
   */
  email?: string
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone?: string
  /** ИНН заказчика */
  inn?: string
  /** Avatar пользователя */
  avatar?: string
  /** Ссылка на инстаграм блогера */
  instagram?: string
  /** Фамилия пользователя */
  lastName?: string
  /** Имя пользователя */
  firstName?: string
  /** Средний охват */
  coveragesCount?: number
  /** Количество подписчиков */
  followersCount?: number
  /** Город пользователя */
  city?: string
  /** Страна пользователя */
  country?: string
  /** Информация по банковским реквизитам */
  bankDetails?: BankDetailsDto[]
  /** Интересуюшие тематики */
  blogTopics?: Topics
  /** Данные для верификации пользователя */
  verifyInfo?: VerifyInfo
}

export interface CreateTransactionDto {
  /** ID заказчика */
  customerId: number
  /** Актуальный баланс */
  total: number
}

export interface BlackListDto {
  /** Идентификатор пользователя  */
  userId?: number
  /** Ссылка на инстаграмм блоггера */
  instagram?: string
  /** Номер телефона пользователя */
  phone?: string
  /** Номер телефона пользователя для банковских переводов */
  accountPhone?: string
  /** Номер банковского счета пользователя */
  accountNumber?: string
  /** Описание причины блокировки */
  comment: string
}

export interface BlacklistResponseDto {
  /** Инстаграм */
  instagram: string
}

export interface MyOrderListByCustomerResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Ссылка на фото товара */
  photoUrl: string
  /** Имя заказчика */
  firstName: string
  /** Имя заказчика в телеграмм */
  tgUsername: string
  /** Название товара */
  title: string
  /** Статус заказа */
  status: string
  /** Тип сбора заказа */
  collection: string
  /** Идентификатор торговой площадки */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Сколько на каком статусе людей */
  customReportersStatistic?: object
}

export interface EditOrderByAdminDto {
  commentIntegration?: string
  commentMarketplace?: string
  keyWord?: string
}

export interface BlogerOrderWithCommentResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Аватар */
  avatar?: string
  /** Идентификатор пользователя */
  userId?: number
  /** ФИО блогера */
  names?: string
  /** Тематики блогера */
  topics?: string[]
  /** Ссылка на инсту */
  instagram?: string
  /** Telegram username Блогера */
  tgUsername?: string
  /** Охват */
  coveragesCount?: number
  /** Количество подписчиков */
  followersCount?: number
  /** Идентификатор отклика */
  reportId?: number
  /** Статус отклика */
  reportStatus?: string
  /** Комментарий */
  comment?: string
  /** Блогеры */
  comments?: object
}

export interface OrderByAdminResponseDto {
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  orderId: number
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Идентификатор продукта */
  productId: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Идентификатор */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Статистика по откликам */
  customReportersStatistic?: object
  /** Блогеры */
  blogers?: BlogerOrderWithCommentResponseDto
}

export interface BlogerReportResponseDto {
  /** Имя блогера */
  firstName: string
  /** аватар блогера */
  avatar?: string
  /** Общее количество охватов блогера */
  coveragesCount: number
  /** Ссылка на Instagram блогера (необязательное поле) */
  instagram?: string
  /** Количество подписчиков у блогера */
  followersCount: number
  /** Тематики */
  topics: string[]
  bankDetails: string[]
}

export interface ReportResponeDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Информация о блогере */
  bloger: BlogerReportResponseDto
  /** Дата добавление рекламы */
  adDate?: string
  /** Aртикул на товар */
  article: string
  /** Cбор участников */
  collection: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарии маркетплейса */
  commentMarketplace?: string
  /** Ключевое слово */
  keyWord: string
  /** Скриншот заказа */
  orderScreenshot?: string
  /** Статус заказа */
  orderStatus?: string
  /** Видео заказа */
  orderVideo?: string
  /** URL фотографии товара */
  photoUrl: string
  /** Цена товара */
  price: string
  /** Ссылка на рилс */
  realsLink?: string
  /** Скриншот обзора */
  reviewsScreenshot?: string
  /** Скриншот покупок */
  screenshotMyPurchases?: string
  /** Скриншот штрих-кода */
  screenshotShtrihcode?: string
  /** Скриншот статистики */
  statisticsScreenshot?: string
  /** Скриншот подтверждения платежа */
  paymentScreenshot?: string
  /** Текущий статус заказа или операции */
  status: string
  /** Скриншот историй */
  storiesScreenshot?: string
  /** Название товара */
  title: string
  /**
   * Время завершения
   * @format date-time
   */
  firstStageTime?: string
  /**
   * Время второго завершения
   * @format date-time
   */
  secondStageTime?: string
  /**
   * Время третьего завершения
   * @format date-time
   */
  thirdStageTime?: string
}

export interface RemoveReportManagementDto {
  /** Опциональный параметр определяющий, будут ли возвращены интеграции. */
  isRefund?: boolean
}

export interface CreateTopicDto {
  /** Название темы */
  name: string
}

export interface TopicResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Наименование тематики */
  name: string
}

export interface ProductVerificationDto {
  /** Перечисление id товаров, для подтверждения */
  approval?: number[]
  /** Перечисление id товаров, для отмены верификации */
  disapproval?: number[]
}

export interface ProductListResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  marketplaceUrl?: string
  /** Ссылка на фото */
  photoUrl?: string
  /** Наименование товара */
  title?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Верификация */
  status?: string
  /** Актуальность товара */
  isActual?: boolean
  /** Id заказчика */
  customerId?: number
  /** Имя заказчика */
  customerName?: string
}

export interface ProductResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Артикул товара */
  article: string
  /** Идентификатор маркетплейса */
  marketplaceId: number
  /** Наименование товара */
  title: string
  /** Цена товара */
  price: number
  /** Актуальность товара */
  isActual: boolean
  /** Статус верификации товара */
  isVerified: string
  /** Идентификатор Заказчика */
  customerId: number
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Денежные транзакции */
  topics: string[]
}

export interface UpdateProductDto {
  /** Ссылка на товар */
  marketplaceUrl?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Наименование товара */
  title?: string
  /** Артикул товара */
  article?: string
  /** Цена на товар */
  price?: number
  /** Идентификатор маркетплейса */
  marketplaceId?: number
  /** Перечисление тем для заказов */
  topics?: Topic[]
}

export interface CreateMarketplaceDto {
  /** Название маркетплейса */
  name: string
}

export interface MarketplaceResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Название маркетплейса */
  name: string
}

export interface ResetBodyDto {
  /** Комментарий */
  comment: string
}

export interface UpdateOrderResponseDto {
  orderScreenshot: string
  orderVideo: string
  screenshotMyPurchases: string
  screenshotShtrihcode: string
  adDate: string
  reviewsScreenshot: string
  realsLink: string
  storiesScreenshot: string
  statisticsScreenshot: string
}

export interface OrderResponsesByBlogerResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Номер товара */
  productId: number
  /** Номер задания */
  orderId: number
  /** Дата размещения рекламы */
  adDate?: string
  /** Артикул товара */
  article: string
  /** Название коллекции, к которой относится заказ */
  collection: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ключевое слово, связанное с заказом */
  keyWord: string
  /** Скриншот заказа */
  orderScreenshot?: string
  /** Статус заказа */
  orderStatus: string
  /** Ссылка на видео заказа */
  orderVideo?: string
  /** Ссылка на фото заказа */
  photoUrl: string
  /** Цена заказа */
  price: string
  /** Ссылка на реальные примеры */
  realsLink?: string
  /** Скриншот отзывов о заказе */
  reviewsScreenshot?: string
  /** Скриншот моих покупок */
  screenshotMyPurchases?: string
  /** Скриншот штрих-кода */
  screenshotShtrihcode?: string
  /** Скриншот статистики по заказу */
  statisticsScreenshot?: string
  /** Статус отклика */
  status: string
  /** Скриншот историй, связанных с заказом */
  storiesScreenshot?: string
  /** Название заказа */
  title: string
  /**
   * Время первого этапа заказа
   * @format date-time
   */
  firstStageTime?: string
  /**
   * Время второго этапа заказа
   * @format date-time
   */
  secondStageTime?: string
  /**
   * Время третьего этапа заказа
   * @format date-time
   */
  thirdStageTime?: string
  /** Комментарий */
  comment: string
}

export interface CreateOrderDto {
  /**
   * Сборка ("AUTOMATIC" | "MANUAL")
   * @default "AUTOMATIC"
   */
  collection?: string
  /** Максимальное количество участвующих блогеров */
  maxParticipants?: number
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Ключевое слово */
  keyWord: string
  /**
   * Повторное участие в продвижении
   * @default false
   */
  rePromotion?: boolean
  /**
   * Повторное участие
   * @default false
   */
  reParticipation?: boolean
  /** Идентификатор продукта */
  productId: number
}

export interface OrderListResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Название товара */
  title?: string
  /** Цена товара */
  price?: number
}

export interface BlogerOrderResponseDto {
  /** Уникальный идентификатор */
  id: number
  /** Аватар */
  avatar?: string
  /** Идентификатор пользователя */
  userId?: number
  /** ФИО блогера */
  names?: string
  /** Тематики блогера */
  topics?: string[]
  /** Ссылка на инсту */
  instagram?: string
  /** Telegram username Блогера */
  tgUsername?: string
  /** Охват */
  coveragesCount?: number
  /** Количество подписчиков */
  followersCount?: number
  /** Идентификатор отклика */
  reportId?: number
  /** Статус отклика */
  reportStatus?: string
  /** Комментарий */
  comment?: string
}

export interface OrderByCustomerResponseDto {
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  orderId: number
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Идентификатор продукта */
  productId: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Идентификатор */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Статистика по откликам */
  customReportersStatistic?: object
  /** Блогеры */
  blogers?: BlogerOrderResponseDto
}

export interface CustomReportersResponseDto {
  /** Уникальный идентификатор */
  id?: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt?: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt?: string
  /** Статус заказа */
  status?: string
  /** Номер блогера */
  blogerId?: number
  /** Скриншот заказа */
  orderScreenshot?: string
  /** Видео заказа */
  orderVideo?: string
  /**
   * Время выполнения первого этапа
   * @format date-time
   */
  firstStageTime?: string
  /** Скриншот ... */
  screenshotMyPurchases?: string
  /** Скриншот Штрихкода */
  screenshotShtrihcode?: string
  /** Дата размещения рекламы */
  adDate?: string
  /**
   * Время выполнения второго этапа
   * @format date-time
   */
  secondStageTime?: string
  /** Скриншот обзора */
  reviewsScreenshot?: string
  /** Ссылка на рилс */
  realsLink?: string
  /** Скриншот на сториз */
  storiesScreenshot?: string
  /** Скриншот на сториз */
  statisticsScreenshot?: string
  /**
   * Время выполнения третьего этапа
   * @format date-time
   */
  thirdStageTime?: string
  /** Скриншот платежа */
  paymentScreenshot?: string
  /** Комментарий к отклику */
  comment?: string
}

export interface OrderByBlogerReportResponseDto {
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  orderId: number
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Идентификатор продукта */
  productId: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Идентификатор */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Информация по откликам */
  customReporters?: CustomReportersResponseDto
}

export interface OrderResponsesListResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Id */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Максимальное количесво токенов */
  customReporters?: СustomReportersDto
}

export interface PaymentReportDto {
  /** Скриншот оплаты */
  paymentScreenshot: string
}

export interface OrderResponsesResponseDto {
  /** Уникальный идентификатор */
  id: number
  /**
   * Дата создания записи
   * @format date-time
   */
  createAt: string
  /**
   * Дата последнего обновления записи
   * @format date-time
   */
  updateAt: string
  /** Информация о блогере, связанном с заказом */
  bloger: BlogerReportResponseDto
  /** Номер товара */
  productId: number
  /** Номер задания */
  orderId: number
  /** Дата размещения рекламы */
  adDate?: string
  /** Артикул товара */
  article: string
  /** Название коллекции, к которой относится заказ */
  collection: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ключевое слово, связанное с заказом */
  keyWord: string
  /** Скриншот заказа */
  orderScreenshot?: string
  /** Статус заказа */
  orderStatus: string
  /** Ссылка на видео заказа */
  orderVideo?: string
  /** Ссылка на фото заказа */
  photoUrl: string
  /** Цена заказа */
  price: string
  /** Ссылка на реальные примеры */
  realsLink?: string
  /** Скриншот отзывов о заказе */
  reviewsScreenshot?: string
  /** Скриншот моих покупок */
  screenshotMyPurchases?: string
  /** Скриншот штрих-кода */
  screenshotShtrihcode?: string
  /** Скриншот статистики по заказу */
  statisticsScreenshot?: string
  /** Статус отклика */
  status: string
  /** Скриншот историй, связанных с заказом */
  storiesScreenshot?: string
  /** Название заказа */
  title: string
  /**
   * Время первого этапа заказа
   * @format date-time
   */
  firstStageTime?: string
  /**
   * Время второго этапа заказа
   * @format date-time
   */
  secondStageTime?: string
  /**
   * Время третьего этапа заказа
   * @format date-time
   */
  thirdStageTime?: string
  /** Комментарий */
  comment: string
}

export interface ResponsesManagementDto {
  /** Идентификаторы одобренных блогеров */
  confirmed?: string[]
  /** Идентификаторы отклоненных блогеров */
  rejected?: string[]
}

export interface OrderManagementDto {
  /** Статус заказа */
  status: string
}

export interface CreateProductDto {
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Наименование товара */
  title: string
  /** Артикул товара */
  article: string
  /** Цена на товар */
  price: number
  /** Идентификатор маркетплейса */
  marketplaceId: number
  /** Перечисление тем для заказов */
  topics: Topic[]
}

export interface CreatePaymentDto {
  /** Комментарий к платежу */
  description: string
  /** Количество интеграций к приобретению */
  quantity: number
}

export interface PaymentResponseDto {
  /** Ссылка для перенаправления пользователя на страницу оплаты */
  PaymentURL: string
  /** Успешность операции */
  Success: boolean
}

export interface StatusConfirmDto {
  /** Идентификатор платежа */
  OrderId: number
  /** Успешность выполнения операции */
  Success: boolean
  /** Итоговая cумма */
  Amount: number
}

export interface UpdateUserDto {
  /** Аватар пользователя */
  avatar?: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Admin API
 * @version 1.0.0
 * @contact
 *
 * Описание API, предназначенного для обеспечения функциональности приложения системного администрирования.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignUpDto
     * @summary Эндпоинт предназначен для регистрации пользователей
     * @request POST:/api/auth/sign-up
     */
    authControllerSignUpDto: (data: SignUpDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/auth/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignInDto
     * @summary Эндпоинт предназначен для входа пользователей в ситему
     * @request POST:/api/auth/sign-in
     */
    authControllerSignInDto: (data: SignInDto, params: RequestParams = {}) =>
      this.request<SignInResponseDto, CommonMessageResponse>({
        path: `/api/auth/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerChangePassword
     * @summary Эндпоинт предназначен для замены пароля
     * @request POST:/api/auth/change-password
     * @secure
     */
    authControllerChangePassword: (data: ChangePasswordDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/auth/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerForgotPassword
     * @request POST:/api/auth/forgot-password
     */
    authControllerForgotPassword: (data: UserEmailDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/auth/forgot-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/api/auth/reset-password
     */
    authControllerResetPassword: (data: AuthResetPasswordDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/auth/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerActivate
     * @summary Эндпоинт для отправки запроса на
     * @request PATCH:/api/blogger/activate
     * @secure
     */
    bloggerControllerActivate: (data: UpsertBloggerDto, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger/activate`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerUpdate
     * @summary Эндпоинт служит для обновления существующего блогера
     * @request PATCH:/api/blogger
     * @secure
     */
    bloggerControllerUpdate: (data: UpsertBloggerDto, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerGetProfile
     * @summary Эндпоинт возвращает информацию профиля текущего блогера
     * @request GET:/api/blogger/profile
     * @secure
     */
    bloggerControllerGetProfile: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger/profile`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerGetProfileByUserId
     * @summary Эндпоинт возвращает информацию профиля блогера по id
     * @request GET:/api/blogger/{userId}
     * @secure
     */
    bloggerControllerGetProfileByUserId: (userId: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CustomersControllerFindOne
     * @summary Эндпоинт предназначен для получения информации о профиле Заказчика.
     * @request GET:/api/customers/profile
     * @secure
     */
    customersControllerFindOne: (params: RequestParams = {}) =>
      this.request<CustomerProfileDto, void | CommonMessageResponse>({
        path: `/api/customers/profile`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CustomersControllerUpdate
     * @summary Эндпоинт позволяет заказчикам обновлять свою информацию.
     * @request PATCH:/api/customers
     * @secure
     */
    customersControllerUpdate: (data: UpdateCustomerDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/customers`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CustomersControllerGenerateOrdersExcel
     * @request GET:/api/customers/report/orders
     * @secure
     */
    customersControllerGenerateOrdersExcel: (
      query?: {
        /** Идентификатор заказа для генерации отчета */
        orderId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ExcelReportResponseDto, CommonMessageResponse | void>({
        path: `/api/customers/report/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerUserActivated
     * @summary Эндпоинт для активации пользователя
     * @request POST:/api/management/users/{userId}
     * @secure
     */
    managementControllerUserActivated: (userId: number, data: UserActivateDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetUserById
     * @summary Эндпоинт для получения информации по пользователю
     * @request GET:/api/management/users/{userId}
     * @secure
     */
    managementControllerGetUserById: (
      userId: number,
      query?: {
        /**
         * Number page
         * @default 1
         */
        productPage?: number
        /**
         * Count
         * @default 20
         */
        productTake?: number
        /**
         * Number page
         * @default 1
         */
        ordersPage?: number
        /**
         * Count
         * @default 20
         */
        ordersTake?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponseDto, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerPatchUserById
     * @summary Эндпоинт для изменения информации по пользователю
     * @request PATCH:/api/management/users/{userId}
     * @secure
     */
    managementControllerPatchUserById: (userId: number, data: UpdateUserByAdminDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllUsers
     * @summary Эндпоинт для получения списка пользователей
     * @request GET:/api/management/users
     * @secure
     */
    managementControllerGetAllUsers: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** Строка с поисковым значением(Артикул, наименование или имя заказчика) */
        search?: string
        /** @default "asc" */
        createAt?: string
        /** Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED" */
        status?: string
        /** Роли участников - "ADMIN" | "BLOGER" | "CUSTOMER" | "MODERATOR" */
        role?: string
        /** Заполненность данных для блогера */
        isFullInfo?: boolean
        /** Отобразить заявки на верификацию */
        isApproveChanges?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: UserListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerApproveBlogerChanges
     * @summary Эндпоинт для одобрения изменений блогером
     * @request PATCH:/api/management/users/{userId}/approve-changes
     * @secure
     */
    managementControllerApproveBlogerChanges: (userId: number, data: ApproveChangesDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}/approve-changes`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetApproveBlogerChanges
     * @summary Эндпоинт для одобрения изменений блогером
     * @request GET:/api/management/users/approve-changes/{approveId}
     * @secure
     */
    managementControllerGetApproveBlogerChanges: (approveId: number, params: RequestParams = {}) =>
      this.request<ApproveBlogerChangesResponseDto, void | CommonMessageResponse>({
        path: `/api/management/users/approve-changes/${approveId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerSetCustomerBalance
     * @summary Метод для присвоения баланса заказчику
     * @request POST:/api/management/customer-balance
     * @secure
     */
    managementControllerSetCustomerBalance: (data: CreateTransactionDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/customer-balance`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerPushToBlacklist
     * @summary Метод для добавления  в черный список по Id либо по информации
     * @request POST:/api/management/blacklist
     * @secure
     */
    managementControllerPushToBlacklist: (data: BlackListDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/blacklist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetBlacklist
     * @summary Метод для получения черного списка
     * @request GET:/api/management/blacklist
     * @secure
     */
    managementControllerGetBlacklist: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска. Возможен поиска по комментариям, инстаграмму */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: BlacklistResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/blacklist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllOrders
     * @summary Метод для получения всех заказов
     * @request GET:/api/management/orders
     * @secure
     */
    managementControllerGetAllOrders: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
        /** Завершенные отклики */
        reportCompleted?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: MyOrderListByCustomerResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerEditOrder
     * @summary Метод для изменения заказа по id
     * @request PATCH:/api/management/orders/{orderId}
     * @secure
     */
    managementControllerEditOrder: (orderId: number, data: EditOrderByAdminDto, params: RequestParams = {}) =>
      this.request<
        PaginateResultDto & {
          data?: MyOrderListByCustomerResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/orders/${orderId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetOrderById
     * @summary Метод для получения заказа по ID
     * @request GET:/api/management/orders/{orderId}
     * @secure
     */
    managementControllerGetOrderById: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderByAdminResponseDto, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGenerateOrdersExcel
     * @summary Метод для выгрузки отчета по заданиям в формате excel
     * @request GET:/api/management/orders/report-excel
     * @secure
     */
    managementControllerGenerateOrdersExcel: (
      query?: {
        /** Идентификатор заказа для генерации отчета */
        orderId?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<ExcelReportResponseDto, CommonMessageResponse | void>({
        path: `/api/management/orders/report-excel`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerCancelOrder
     * @summary Метод отмены заказа
     * @request GET:/api/management/orders/{orderId}/cancel
     * @secure
     */
    managementControllerCancelOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}/cancel`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindByIdResponses
     * @summary Метод для просмотра прогресса выполнения заданий( информация по отклику)
     * @request GET:/api/management/reports/{reportId}
     * @secure
     */
    managementControllerFindByIdResponses: (reportId: number, params: RequestParams = {}) =>
      this.request<ReportResponeDto, void | CommonMessageResponse>({
        path: `/api/management/reports/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerDelete
     * @summary Метод для удаления конкретного отклика
     * @request DELETE:/api/management/reports/{reportId}
     * @secure
     */
    managementControllerDelete: (reportId: number, data: RemoveReportManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/reports/${reportId}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerAddTopic
     * @summary Метод для добавления тематики
     * @request POST:/api/management/topics
     * @secure
     */
    managementControllerAddTopic: (data: CreateTopicDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllTopics
     * @summary Метод для получения всех тематик
     * @request GET:/api/management/topics
     * @secure
     */
    managementControllerFindAllTopics: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: TopicResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/topics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerRemoveTopic
     * @summary Метод для удаления тематики
     * @request DELETE:/api/management/topics/{id}
     * @secure
     */
    managementControllerRemoveTopic: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerVerificationProduct
     * @summary  Метод для верификации продукта
     * @request POST:/api/management/products
     * @secure
     */
    managementControllerVerificationProduct: (data: ProductVerificationDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllProducts
     * @summary Метод для получения всех продуктов
     * @request GET:/api/management/products
     * @secure
     */
    managementControllerFindAllProducts: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Актуальность товара */
        isActual?: boolean
        /** Статус товара */
        status?: string
        /** Строка с поисковым значением(Артикул, наименование или имя заказчика) */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: ProductListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindProductById
     * @request GET:/api/management/products/{id}
     * @secure
     */
    managementControllerFindProductById: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponseDto, void | CommonMessageResponse>({
        path: `/api/management/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerSetProductById
     * @request PATCH:/api/management/products/{id}
     * @secure
     */
    managementControllerSetProductById: (id: number, data: UpdateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/products/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerCreateMarketplace
     * @summary Метод для добавления маркетплейса
     * @request POST:/api/management/marketplace
     * @secure
     */
    managementControllerCreateMarketplace: (data: CreateMarketplaceDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/marketplace`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllMarketplace
     * @summary Метод для получения всех маркетплейсов
     * @request GET:/api/management/marketplace
     * @secure
     */
    managementControllerFindAllMarketplace: (params: RequestParams = {}) =>
      this.request<MarketplaceResponseDto, void | CommonMessageResponse>({
        path: `/api/management/marketplace`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerRemoveMarketplace
     * @summary Метод для удаления маркетплейса
     * @request DELETE:/api/management/marketplace/{id}
     * @secure
     */
    managementControllerRemoveMarketplace: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/marketplace/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Marketplace
     * @name ManagementControllerFindAllMarketplace2
     * @summary Эндпоинт для получения всех доступных маркетплейсов
     * @request GET:/api/marketplace
     * @originalName managementControllerFindAllMarketplace
     * @duplicate
     * @secure
     */
    managementControllerFindAllMarketplace2: (params: RequestParams = {}) =>
      this.request<MarketplaceResponseDto, void | CommonMessageResponse>({
        path: `/api/marketplace`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerCreate
     * @summary Метод для создания отклика на заказ
     * @request POST:/api/orders/{orderId}/responses
     * @secure
     */
    orderResponsesControllerCreate: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAllResponses
     * @summary Метод для получения всех откликов по заказу
     * @request GET:/api/orders/{orderId}/responses
     * @secure
     */
    ordersControllerFindAllResponses: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesListResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerPaymentReset
     * @summary Метод для обозначения оброса платежа
     * @request PATCH:/api/orders/responses/{reportId}/payment-reset
     * @secure
     */
    orderResponsesControllerPaymentReset: (reportId: number, data: ResetBodyDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${reportId}/payment-reset`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerPaymentConfirm
     * @summary Метод для подтверждения платежа
     * @request POST:/api/orders/{orderId}/responses/{reportId}/payment-confirm
     * @secure
     */
    orderResponsesControllerPaymentConfirm: (orderId: number, reportId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}/payment-confirm`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerResetResponse
     * @summary Метод для сброса отклика
     * @request PATCH:/api/orders/responses/{id}/reset
     * @secure
     */
    orderResponsesControllerResetResponse: (id: number, data: ResetBodyDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}/reset`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerSteps
     * @summary Метод для изменения шага отклика
     * @request PATCH:/api/orders/responses/{id}/{step}
     * @secure
     */
    orderResponsesControllerSteps: (
      id: number,
      step: number,
      data: UpdateOrderResponseDto,
      params: RequestParams = {},
    ) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}/${step}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerGetReport
     * @summary Метод для просмотра отклика
     * @request GET:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerGetReport: (id: number, params: RequestParams = {}) =>
      this.request<OrderResponsesByBlogerResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerRemove
     * @summary Метод для удаления отклика
     * @request DELETE:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerCreate
     * @summary Метод для создания нового заказа
     * @request POST:/api/orders
     * @secure
     */
    ordersControllerCreate: (data: CreateOrderDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAllOrders
     * @summary Метод для получения всех заказов
     * @request GET:/api/orders
     * @secure
     */
    ordersControllerFindAllOrders: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
        /** Завершенные отклики */
        reportCompleted?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: OrderListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAll
     * @summary Метод для получения моих заказов
     * @request GET:/api/orders/my
     * @secure
     */
    ordersControllerFindAll: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
        /** Завершенные отклики */
        reportCompleted?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: MyOrderListByCustomerResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders/my`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindOne
     * @summary Метод для получения заказа по Id
     * @request GET:/api/orders/{id}
     * @secure
     */
    ordersControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<OrderByCustomerResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindOneBlogerReport
     * @summary Метод для получения моего заказа по Id
     * @request GET:/api/orders/my/{orderId}
     * @secure
     */
    ordersControllerFindOneBlogerReport: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderByBlogerReportResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/my/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerSetReportPayment
     * @summary Метод для добовления отчености по оплате
     * @request POST:/api/orders/{orderId}/responses/{reportId}
     * @secure
     */
    ordersControllerSetReportPayment: (
      reportId: number,
      orderId: number,
      data: PaymentReportDto,
      params: RequestParams = {},
    ) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindByIdResponses
     * @summary Метод для получения отклика по ID
     * @request GET:/api/orders/{orderId}/responses/{reportId}
     * @secure
     */
    ordersControllerFindByIdResponses: (reportId: number, orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerResponsesManagement
     * @summary Метод для управления откликами на заказ
     * @request PATCH:/api/orders/{orderId}/responses-management
     * @secure
     */
    ordersControllerResponsesManagement: (orderId: number, data: ResponsesManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses-management`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerChangeOrderStatus
     * @summary Метод для изменения статуса заказа
     * @request PATCH:/api/orders/{orderId}/management
     * @secure
     */
    ordersControllerChangeOrderStatus: (orderId: number, data: OrderManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/management`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerCancelOrder
     * @summary Метод для отмены заказа
     * @request PATCH:/api/orders/{orderId}/cancel
     * @secure
     */
    ordersControllerCancelOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/cancel`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerCreate
     * @summary Метод для создания товара
     * @request POST:/api/products
     * @secure
     */
    productsControllerCreate: (data: CreateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindAll
     * @summary Метод для получения всех товаров
     * @request GET:/api/products
     * @secure
     */
    productsControllerFindAll: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        isActual?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: ProductResponseDto[]
        },
        CommonMessageResponse | void
      >({
        path: `/api/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindOne
     * @summary Метод для получения одного товара
     * @request GET:/api/products/{id}
     * @secure
     */
    productsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponseDto, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerUpdate
     * @summary Метод для обновления товара
     * @request PATCH:/api/products/{id}
     * @secure
     */
    productsControllerUpdate: (id: number, data: UpdateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerRemove
     * @summary Метод для удаления товара
     * @request DELETE:/api/products/{id}
     * @secure
     */
    productsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerInitializePayment
     * @summary Эндпоинт, для инициализации платежа
     * @request POST:/api/payment/initialize
     * @secure
     */
    paymentControllerInitializePayment: (data: CreatePaymentDto, params: RequestParams = {}) =>
      this.request<PaymentResponseDto, CommonMessageResponse | void>({
        path: `/api/payment/initialize`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerSetPaymentStatus
     * @summary Эндпоинт для тбанка, для изменения статуса платежа()
     * @request POST:/api/payment/response
     */
    paymentControllerSetPaymentStatus: (data: StatusConfirmDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/payment/response`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Topics
     * @name TopicsControllerFindAll
     * @summary Метод для получения всех тематик
     * @request GET:/api/topics
     * @secure
     */
    topicsControllerFindAll: (params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/topics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdate
     * @summary Метод замены аватара
     * @request PATCH:/api/users/avatar
     * @secure
     */
    usersControllerUpdate: (data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/users/avatar`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerTgVerify
     * @summary Эндпоинт возвращает информацию профиля текущего блогера
     * @request GET:/api/users/tg-verify
     * @secure
     */
    usersControllerTgVerify: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/users/tg-verify`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blacklist
     * @name BlacklistControllerFindAll
     * @summary Метод для получения всех записей черного списка
     * @request GET:/api/blacklist/landing
     * @secure
     */
    blacklistControllerFindAll: (
      query: {
        /**
         * Номер страницы для получения данных (начиная с 1).
         * @default 1
         */
        page: number
        /**
         * Количество элементов на странице
         * @default 50
         */
        take: number
        /** Инстаграм */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: BlacklistResponseDto[]
        },
        CommonMessageResponse | void
      >({
        path: `/api/blacklist/landing`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
