import React, { FC, MouseEventHandler, ReactNode, useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { plural } from 'src/shared/lib/plural'
import {
  AdminAPI,
  CommonMessageResponse,
  HttpResponse,
  ProductResponseDto,
  ProductVerificationDto,
} from 'src/shared/api'
import { Tabs } from 'src/shared/ui/Tabs'
import { Product } from 'src/shared/ui/Product'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { ProductStatusType } from 'src/shared/config/types'
import { useNavigate } from 'react-router'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { useProducts } from '../../model/useProducts'
import toast from 'react-hot-toast'

import styles from './AdminCabinetProducts.module.scss'

export const AdminCabinetProducts: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(ProductStatusType.PENDING)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { products, productsCounts } = useProducts(activeTabId as ProductStatusType)

  const { mutate: productVerificationMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    ProductVerificationDto
  >({
    mutationFn: AdminAPI.api.managementControllerVerificationProduct,
  })

  const productsTotal = productsCounts.total

  const tabs = useMemo(
    () => [
      {
        tabId: ProductStatusType.PENDING,
        title: 'Неподтвержденные товары',
        badge: productsCounts.totalPending,
      },
      {
        tabId: ProductStatusType.CONFIRMED,
        title: 'Подтвержденные товары',
        badge: productsCounts.totalConfirmed,
      },
      {
        tabId: ProductStatusType.REJECTED,
        title: 'Отклоненные товары',
        badge: productsCounts.totalRejected,
      },
    ],
    [productsCounts],
  )

  const renderProduct = useCallback(
    (product: ProductResponseDto) => {
      const { id, photoUrl, title, price } = product
      // @ts-ignore
      const status = product.status

      const handleApproveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        const approveProductToastId = toast.loading('Подтверждаем товар')
        e.stopPropagation()
        productVerificationMutation(
          { approval: [id], disapproval: [] },
          {
            onSuccess: () => {
              toast.success('Товар успешно подтверждён! ✨', {
                id: approveProductToastId,
              })
              queryClient.invalidateQueries({ queryKey: ['products'] })
            },
            onError: (data) => {
              toast.error(data.error?.message || 'Ошибка при подтверждении товара', {
                id: approveProductToastId,
              })
            },
          },
        )
      }

      const handleDisapproveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        const disapproveProductToastId = toast.loading('Отклоняем товар')
        e.stopPropagation()
        productVerificationMutation(
          { approval: [], disapproval: [id] },
          {
            onSuccess: () => {
              toast.success('Товар успешно отклонён!', {
                id: disapproveProductToastId,
              })
              queryClient.invalidateQueries({ queryKey: ['products'] })
            },
            onError: (data) => {
              toast.error(data.error?.message || 'Ошибка при отклонении товара', {
                id: disapproveProductToastId,
              })
            },
          },
        )
      }

      let buttons: ReactNode

      if (status === ProductStatusType.PENDING) {
        buttons = (
          <Flex flexDirection="column" gap={8}>
            <CabinetActionButton kind="primary" disabled={isLoading} onClick={handleApproveClick}>
              Подтвердить
            </CabinetActionButton>
            <CabinetActionButton kind="ghost" disabled={isLoading} onClick={handleDisapproveClick}>
              Отклонить
            </CabinetActionButton>
          </Flex>
        )
      }

      if (status === ProductStatusType.CONFIRMED) {
        buttons = (
          <CabinetActionButton kind="ghost" disabled={isLoading} onClick={handleDisapproveClick}>
            Отклонить
          </CabinetActionButton>
        )
      }

      if (status === ProductStatusType.REJECTED) {
        buttons = (
          <CabinetActionButton kind="ghost" disabled={isLoading} onClick={handleApproveClick}>
            Подтвердить
          </CabinetActionButton>
        )
      }

      return (
        <Product
          key={id}
          photoUrl={photoUrl}
          title={title}
          price={price}
          onClick={() => navigate(`${CABINET_PRODUCTS_ROUTE}/${id}`)}
        >
          {buttons}
        </Product>
      )
    },
    [activeTabId],
  )

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>
        {productsTotal && `${productsTotal} ${plural(['товар', 'товара', 'товаров'], productsTotal)}`}
      </h1>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={setActiveTabId} />
      <Flex flexWrap="wrap" gap={16} className={styles.Products}>
        {products.map(renderProduct)}
      </Flex>
    </CabinetPageLayout>
  )
}
