import React, {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'

import { Avatar } from 'src/shared/ui/Avatar'
import { Flex } from 'src/shared/ui/Flex'

import styles from './ProfileAvatar.module.scss'

export interface ProfileAvatarProps {
  firstName?: string
  file?: File | string
  disabled?: boolean
  onChange(file: File): void
  className?: string
}

const ProfileAvatarWithRef = (
  { firstName = '', file, disabled = false, className, onChange }: ProfileAvatarProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string | ArrayBuffer | null | undefined>()
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (file) {
      if (typeof file === 'string') {
        setImagePreviewSrc(file)
      } else {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = (fileReaderEvent: ProgressEvent<FileReader>) => {
          setImagePreviewSrc(fileReaderEvent?.target?.result)
        }
      }
    }
  }, [file])

  const fileHandler = useCallback(
    (fileList: FileList) => {
      const image = fileList[0]
      onChange(image)
    },
    [onChange],
  )

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.target.files) {
        fileHandler(event.target.files)
      }
      event.target.value = ''
    },
    [fileHandler],
  )

  const handleClick = useCallback<MouseEventHandler>(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [])

  return (
    <Flex className={cn(styles.Layout, className)}>
      <Avatar firstName={firstName} avatar={imagePreviewSrc as string} type="square" className={styles.Image} />
      {!disabled && (
        <span onClick={handleClick} className={styles.Text}>
          Изменить фото
        </span>
      )}
      <input
        accept="image/*"
        className={styles.HiddenInput}
        ref={fileInputRef}
        type="file"
        onChange={handleInputChange}
      />
    </Flex>
  )
}

export const ProfileAvatar = forwardRef(ProfileAvatarWithRef)
