import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import { Icon, IconPropsType } from '../Icon'
import styles from './LandingButton.module.scss'
import { Link, LinkProps } from 'react-router-dom'

interface LandingButtonPropsType extends LinkProps {
  children: ReactNode
  kind?: 'primary' | 'ghost'
  icon?: IconPropsType['name']
  to: string
}

export const LandingButton: FC<LandingButtonPropsType> = ({
  className,
  children,
  kind = 'primary',
  icon,
  to,
  ...props
}) => {
  return (
    <Link
      to={to}
      className={cn(
        styles.LandingButton,
        kind === 'ghost' && styles.LandingButton__kindGhost,
        icon && styles.LandingButton__withIcon,
        className,
      )}
      {...props}
    >
      {children}
      {icon && <Icon className={styles.Icon} name={icon} />}
    </Link>
  )
}
