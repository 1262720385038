import React, { ForwardedRef, forwardRef } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Input, InputPropsType } from '../Input'

export type TextInputFieldPropsType = Omit<FieldPropsType, 'children'> & InputPropsType

const TextInputFieldWithRef = (
  { className, kind, label, id, error, isRequired, withHiddenLabel, ...rest }: TextInputFieldPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <Field
      className={className}
      kind={kind}
      label={label}
      id={id}
      error={error}
      isRequired={isRequired}
      withHiddenLabel={withHiddenLabel}
    >
      <Input id={id} isInvalid={Boolean(error)} kind={kind} ref={forwardedRef} {...rest} />
    </Field>
  )
}

export const TextInputField = forwardRef(TextInputFieldWithRef)
