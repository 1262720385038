import React, { FC } from 'react'

import { OrderResponsesResponseDto } from 'src/shared/config/types'

import { Flex } from 'src/shared/ui/Flex'

import { RemainingTime } from 'src/widgets/RemainingTime/RemainingTime'

import { MediaSections } from '../MediaSections/MediaSections'

import styles from './WaitPayConfirmStage.module.scss'

interface WaitPayConfirmStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const WaitPayConfirmStage: FC<WaitPayConfirmStagePropsType> = ({ orderResponse }) => {
  return (
    <Flex className={styles.WaitPayConfirmStage} flexDirection="column">
      <MediaSections orderResponse={orderResponse} />
      <div className={styles.Title}>Подтверждение оплаты</div>
      <RemainingTime
        time={orderResponse.firstStageTime || ''}
        duration={24}
        label={`Вы отправили оплату блогеру.
Подождите пока он подтвердит получение`}
        error="Не волнуйтесь, блогер скоро подтвердит оплату. Если этого не произойдет в ближайшие 48 часов, обратитесь в поддержку"
        className={styles.RemainingTime}
      />
    </Flex>
  )
}
