import React, { FC } from 'react'
import cn from 'classnames'

import { ReactComponent as Activity } from 'src/shared/assets/activity.svg'
import { ReactComponent as Heart } from 'src/shared/assets/heart.svg'
import { ReactComponent as ShieldDone } from 'src/shared/assets/shield_done.svg'

import styles from './Advantages.module.scss'

interface AdvantagesPropsType {
  className: string
}

export const Advantages: FC<AdvantagesPropsType> = ({ className }) => {
  return (
    <section className={cn(styles.Advantages, className)} aria-labelledby="advantages-title">
      <h2 id="advantages-title" className={styles.Title}>
        Преимущества платформы
      </h2>
      <div className={styles.Items}>
        <article className={cn(styles.Item, styles.ComfortItem)} aria-labelledby="comfort-title">
          <Heart />
          <strong id="comfort-title" className={styles.ItemTitle}>
            Удобно
          </strong>
          <p className={styles.ItemDescription}>
            Платформа значительно экономит время, объединяя всех блогеров в одном месте и позволяя быстро отобрать
            нужных блогеров по конкретным параметрам, а наш персональный менеджер упрощает коммуникацию, позволяя легко
            координировать задачи и поддерживать связь с блогерами в рамках одной системы.
          </p>
        </article>
        <article className={cn(styles.Item, styles.ProfitItem)} aria-labelledby="profit-title">
          <ShieldDone />
          <strong id="profit-title" className={styles.ItemTitle}>
            Выгодно
          </strong>
          <p className={styles.ItemDescription}>
            Итоговая стоимость рекламной кампании через платформу Marketiq будет выгоднее, чем через другие платформы:
            вы получите раскрутку вашей карточки по ключевому запросу, положительный развернутый отзыв на ваш товар, а
            также целую рекламную кампанию у блогера на страничке с его искренними рекомендациями к покупке вашего
            товара.
          </p>
        </article>
        <article className={cn(styles.Item, styles.EffectiveItem)} aria-labelledby="effective-title">
          <Activity />
          <strong id="effective-title" className={styles.ItemTitle}>
            Эффективно
          </strong>
          <p className={styles.ItemDescription}>
            Мы позволяем быстро и эффективно найти блогеров, идеально подходящих под целевую аудиторию, благодаря
            встроенным фильтрам и аналитическим данным. Наша платформа автоматизировано собирает данные по просмотрам,
            переходам и вовлеченности, что позволяет оперативно корректировать стратегию.
          </p>
        </article>
      </div>
    </section>
  )
}
