import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useMutation } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { AdminAPI, CommonMessageResponse, HttpResponse, SignInDto, SignInResponseDto } from 'src/shared/api'
import { CABINET_ROUTE, FORGOT_PASSWORD_ROUTE } from 'src/shared/config/consts'
import { DecodedJwtToken } from 'src/shared/config/types'

import { emailRules } from 'src/shared/lib/emailRules'
import { passwordRules } from 'src/shared/lib/passwordRules'
import { useScrollToTop } from 'src/shared/lib/useScrollToTop'

import { AuthButton } from 'src/shared/ui/AuthButton'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { AuthBlock } from 'src/widgets/AuthBlock'

import styles from '../Login/Login.module.scss'

interface LoginFormFields {
  email: string
  password: string
}

export const LoginBlock: FC = () => {
  const [fpHash, setFpHash] = useState('')
  const navigate = useNavigate()
  useScrollToTop()

  const methods = useForm<LoginFormFields>({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()

      setFpHash(visitorId)
    }

    setFp()
  }, [])

  const loginMutation = useMutation<
    HttpResponse<SignInResponseDto, CommonMessageResponse>,
    HttpResponse<SignInResponseDto, CommonMessageResponse>,
    SignInDto
  >({
    mutationFn: AdminAPI.api.authControllerSignInDto,
  })
  const isLoading = loginMutation.isPending

  const onSubmit = useCallback(
    (data: LoginFormFields) => {
      const signInDto: SignInDto = {
        email: data.email,
        password: data.password,
        client: fpHash,
      }

      loginMutation.mutate(signInDto, {
        onSuccess: ({ data }) => {
          const decodedAccessToken = jwtDecode(data.accessToken) as DecodedJwtToken

          localStorage.setItem('accessToken', data.accessToken)
          localStorage.setItem('user', JSON.stringify({ ...data.user, hasTg: Boolean(decodedAccessToken.tgId) }))
          localStorage.setItem('fpHash', fpHash)

          navigate(CABINET_ROUTE)
        },
        onError: (error) => {
          setError('email', {
            message: ' ',
          })
          setError('password', error.error)
        },
      })
    },
    [fpHash, loginMutation],
  )

  const isAuthButtonDisabled = useMemo(
    () => !(Boolean(values.email) && Boolean(values.password)) || isLoading,
    [values],
  )

  return (
    <AuthBlock title="Вход" className={styles.AuthBlock}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
          <div className={styles.InputsContainer}>
            <TextInputFormField
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              autoComplete="email"
              rules={emailRules}
              disabled={isLoading}
            />
            <TextInputFormField
              id="password"
              name="password"
              label="Пароль"
              placeholder="Пароль"
              type="password"
              rules={passwordRules}
              disabled={isLoading}
            />
          </div>
          <Link to={FORGOT_PASSWORD_ROUTE} className={styles.PasswordLink} type="button">
            Забыли пароль?
          </Link>
          <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
            Войти
          </AuthButton>
        </form>
      </FormProvider>
    </AuthBlock>
  )
}
