import { useQuery } from '@tanstack/react-query'
import { AdminAPI, CustomerProfileDto } from '../api'
import { useUserInfo } from './useUser'
import { RoleType } from '../config/types'

export const useCustomerProfile = (): CustomerProfileDto | undefined => {
  const { user } = useUserInfo()

  const { data: profileData } = useQuery({
    queryKey: ['profile'],
    queryFn: () => AdminAPI.api.customersControllerFindOne({ format: 'json' }),
    enabled: user?.role === RoleType.CUSTOMER,
  })

  return profileData?.data
}
