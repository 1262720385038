import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CabinetPageLayout.module.scss'

interface CabinetPageLayoutPropsType {
  className?: string
  children: ReactNode
}

export const CabinetPageLayout: FC<CabinetPageLayoutPropsType> = ({ className, children }) => {
  return (
    <Flex className={cn(styles.CabinetPageLayout, className)} flexDirection="column">
      {children}
    </Flex>
  )
}
