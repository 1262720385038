import React, { forwardRef, TextareaHTMLAttributes, ForwardedRef } from 'react'
import cn from 'classnames'

import styles from './Textarea.module.scss'

export interface TextareaPropsType extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  kind?: 'landing' | 'cabinet' | 'smallCabinet'
  isInvalid?: boolean
  isRequired?: boolean
}

const TextareaWithRef = (
  { className, kind = 'landing', isInvalid = false, isRequired, ...rest }: TextareaPropsType,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) => {
  return (
    <label
      className={cn(
        styles.Container,
        isInvalid && styles.isInvalid,
        kind === 'landing' && styles.Container__landing,
        (kind === 'cabinet' || kind === 'smallCabinet') && styles.Container__cabinet,
        className,
      )}
    >
      <textarea className={styles.Textarea} required={isRequired} ref={forwardedRef} {...rest} />
    </label>
  )
}

export const Textarea = forwardRef(TextareaWithRef)
