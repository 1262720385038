export function generateRandomEmojis(size: number) {
  const emojis = [
    '😀',
    '😁',
    '😂',
    '😄',
    '😅',
    '😊',
    '😉',
    '😋',
    '😎',
    '😍',
    '😘',
    '😚',
    '😊',
    '🤑',
    '😇',
    '🤩',
    '🥳',
    '🙌',
    '👌',
    '✌️',
    '👍',
    '💪',
    '👋',
    '❤️',
    '✨',
    '💫',
    '🌈',
  ]

  const shuffledEmojis = emojis.sort(() => Math.random() - 0.5)

  return shuffledEmojis.slice(0, size).join('')
}
