import React, { FC, useCallback, useMemo, useState } from 'react'
import { DeepPartial, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  AdminAPI,
  BankDetailsDto,
  CommonMessageResponse,
  HttpResponse,
  TopicResponseDto,
  UpdateUserByAdminDto,
  UserActivateDto,
  UserResponseDto,
} from 'src/shared/api'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { UserStatusType } from 'src/shared/config/types'

import { getBankDetailsChange, getTopicsChanges } from 'src/shared/lib/prepareBloggerFields'

import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { BankDetailsSection, BloggerForm, BlogInfo, MediaList, PersonalInfo } from 'src/widgets/BloggerForm'
import { BloggerFormFields } from 'src/widgets/BloggerForm/ui/BloggerForm/BloggerForm'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { RejectModal } from 'src/widgets/RejectModal'

import { useVerificationRequest } from '../../model/useVerificationRequest'

import styles from './VerificationRequest.module.scss'

const FORM_ID = 'VerificationRequest'

interface VerificationRequestPropsType {
  user: UserResponseDto
}

export const VerificationRequest: FC<VerificationRequestPropsType> = ({ user }) => {
  const { bloger } = user
  const bankDetails = bloger?.bankDetails as unknown as BankDetailsDto[]
  const topics = bloger?.topics as unknown as TopicResponseDto[]

  const [formData, setFormData] = useState<BloggerFormFields>()
  const [isUploading, setIsUploading] = useState(false)

  const navigate = useNavigate()

  const handleBackButtonClick = useCallback(() => navigate(CABINET_USERS_ROUTE), [navigate])

  const defaultValues = useMemo<DeepPartial<BloggerFormFields>>(
    () => ({
      avatar: user.avatar || '',
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName || '',
      phone: user.phone,
      country: bloger?.country,
      city: bloger?.city,
      instagram: bloger?.instagram,
      followersCount: bloger?.followersCount,
      coveragesCount: bloger?.coveragesCount,
      topics: topics.map((item) => ({ topicId: String(item.id) })),
      bankDetails: bankDetails,
      verifyInfo: {
        videoStatistics: bloger?.verifyInfo?.videoStatistics || '',
        genderStatistics: bloger?.verifyInfo?.genderStatistics || '',
        countriesStatistics: bloger?.verifyInfo?.countriesStatistics || '',
        lastStoryStatistics: bloger?.verifyInfo?.lastStoryStatistics || '',
        publicationStatistics: bloger?.verifyInfo?.publicationStatistics || '',
      },
    }),
    [user],
  )

  const methods = useForm<BloggerFormFields>({ defaultValues })
  const { watch, handleSubmit } = methods
  const values = watch()

  const queryClient = useQueryClient()

  const { mutate: updateUserStatusMutation, isPending: isLoadingStatusUpdate } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UserActivateDto
  >({
    mutationFn: (data: UserActivateDto) => AdminAPI.api.managementControllerUserActivated(user.id, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  })

  const { mutate: updateUserMutation, isPending: isLoadingUpdateUser } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateUserByAdminDto
  >({
    mutationFn: (data: UpdateUserByAdminDto) => AdminAPI.api.managementControllerPatchUserById(user.id, data),
  })

  const updateUser = useCallback(
    async (
      data: BloggerFormFields,
      status: UserStatusType,
      message: { loading: string; success: string; error: string },
    ) => {
      const updateUserToastId = toast.loading('Обновляем данные пользователя')
      try {
        const verifyInfo = defaultValues.verifyInfo
        const newVerifyInfo = data.verifyInfo

        const { newTopics, deletedTopics } = getTopicsChanges(topics, data.topics)
        const newBankDetails = getBankDetailsChange(bankDetails, data.bankDetails)

        function getUpdatedValue<T>(defaultValue: T, newValue: T): T | undefined {
          return defaultValue === newValue ? undefined : newValue
        }

        const updateBloggerDto: UpdateUserByAdminDto = {
          avatar: getUpdatedValue(defaultValues.avatar, data.avatar),
          email: getUpdatedValue(defaultValues.email, data.email),
          firstName: getUpdatedValue(defaultValues.firstName, data.firstName),
          lastName: getUpdatedValue(defaultValues.lastName, data.lastName),
          phone: defaultValues.phone === data.phone ? undefined : '+7' + data.phone,
          country: getUpdatedValue(defaultValues.country, data.country),
          city: getUpdatedValue(defaultValues.city, data.city),
          instagram: getUpdatedValue(defaultValues.instagram, data.instagram),
          followersCount: getUpdatedValue(Number(defaultValues.followersCount), Number(data.followersCount)),
          coveragesCount: getUpdatedValue(Number(defaultValues.coveragesCount), Number(data.coveragesCount)),
          blogTopics: {
            create: newTopics.map(({ topicId }) => ({ topicId: Number(topicId) })),
            delete: deletedTopics.map((topicId) => ({ topicId: Number(topicId) })),
          },
          bankDetails: newBankDetails as BankDetailsDto[],
          verifyInfo: {
            videoStatistics: getUpdatedValue(verifyInfo?.videoStatistics, newVerifyInfo?.videoStatistics),
            genderStatistics: getUpdatedValue(verifyInfo?.genderStatistics, newVerifyInfo?.genderStatistics),
            countriesStatistics: getUpdatedValue(verifyInfo?.countriesStatistics, newVerifyInfo?.countriesStatistics),
            lastStoryStatistics: getUpdatedValue(verifyInfo?.lastStoryStatistics, newVerifyInfo?.lastStoryStatistics),
            publicationStatistics: getUpdatedValue(
              verifyInfo?.publicationStatistics,
              newVerifyInfo?.publicationStatistics,
            ),
          },
        }

        await new Promise<void>((resolve, reject) => {
          updateUserMutation(updateBloggerDto, {
            onSuccess: () => {
              toast.success('Пользовательские данные обновлены! ✨', { id: updateUserToastId })
              queryClient.invalidateQueries({ queryKey: ['users', user.id] })
              resolve()
            },
            onError: (error) => {
              toast.error(error.error?.message || 'Ошибка при обновлении пользовательских данных', {
                id: updateUserToastId,
              })
              reject(error)
            },
          })
        })

        const userActivateDto: UserActivateDto = {
          status,
          сomment: data.comment,
        }

        toast.loading(message.loading, { id: updateUserToastId })

        await new Promise<void>((resolve, reject) => {
          updateUserStatusMutation(userActivateDto, {
            onSuccess: () => {
              navigate(CABINET_USERS_ROUTE, { replace: true })
              toast.success(message.success, { id: updateUserToastId })
              resolve()
            },
            onError: (error) => {
              toast.error(error.error?.message || message.error, { id: updateUserToastId })
              reject(error)
            },
          })
        })
      } catch (error) {
        console.error(error)
        toast.error(String(error) || 'Ошибка при обновлении пользовательских данных', { id: updateUserToastId })
        setIsUploading(false)
      }
    },
    [updateUserStatusMutation],
  )

  const {
    activateUser,
    handleReject,
    handleRejectModalBlock,
    handleRejectModalClose,
    handleRejectModalSendForImprovement,
  } = useVerificationRequest({ formData, setFormData, updateUser })

  const isTopicsValid = useMemo(() => values.topics.every((item) => item.topicId !== undefined), [values])

  const isBankDetailsValid = useMemo(
    () => values.bankDetails.every((item) => item.accountNumber && item.accountPhone && item.bankName && item.names),
    [values],
  )

  const isButtonDisabled =
    !isTopicsValid ||
    !isBankDetailsValid ||
    !values.instagram ||
    !values.followersCount ||
    !values.coveragesCount ||
    !values.country ||
    !values.city ||
    isLoadingUpdateUser ||
    isLoadingStatusUpdate ||
    isUploading

  if (!bloger) {
    return null
  }

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16} className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Заявка от блогера</PageTitle>
      </Flex>
      <BloggerForm
        formId={FORM_ID}
        methods={methods}
        onSubmit={activateUser}
        isLoading={isLoadingUpdateUser || isLoadingStatusUpdate || isUploading}
        setIsUploading={setIsUploading}
      >
        <Flex gap={24} flexWrap="wrap" className={styles.Blocks}>
          <PersonalInfo showAvatar showEmail showNames showPhone showCountry />
          <BlogInfo showInstagram showTopics showCoverages showFollowers />
          <BankDetailsSection />
        </Flex>

        <MediaList />
      </BloggerForm>
      <Flex className={styles.Buttons} flexDirection="column" gap={8}>
        <CabinetActionButton form={FORM_ID} kind="primary" disabled={isButtonDisabled}>
          Подтвердить
        </CabinetActionButton>
        <CabinetActionButton
          kind="ghost"
          disabled={isButtonDisabled}
          type="button"
          onClick={handleSubmit(handleReject)}
        >
          Отклонить
        </CabinetActionButton>
      </Flex>
      {formData && (
        <RejectModal
          blogger={user}
          onSendForImprovement={handleRejectModalSendForImprovement}
          onBlock={handleRejectModalBlock}
          onClose={handleRejectModalClose}
        />
      )}
    </CabinetPageLayout>
  )
}
