import React, { FC } from 'react'
import { useUserInfo } from 'src/shared/model/useUser'
import { Icon } from 'src/shared/ui/Icon'

import styles from './TelegramLink.module.scss'

export const TelegramLink: FC = () => {
  const { user } = useUserInfo()

  return (
    <a
      className={styles.TelegramLink}
      href={`${process.env.REACT_APP_TG_BOT_URL}?start=userId=${user?.id}`}
      target="_blank"
      rel="noreferrer"
    >
      Телеграм-бот
      <Icon name="telegram-small" />
    </a>
  )
}
