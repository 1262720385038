import { FC } from 'react'
import { Outlet } from 'react-router'

import { AuthLayout } from 'src/widgets/AuthLayout'

export const Login: FC = () => {
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}
