import { useMutation } from '@tanstack/react-query'

import { AdminAPI, CommonMessageResponse, HttpResponse, UpsertBloggerDto } from 'src/shared/api'

export const useBloggerUpdateMutation = () => {
  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpsertBloggerDto
  >({
    mutationFn: AdminAPI.api.bloggerControllerUpdate,
  })
}
