import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  AdminAPI,
  ApproveChangesDto,
  CommonMessageResponse,
  HttpResponse,
  UserActivateDto,
  UserListResponseDto,
} from 'src/shared/api'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { RoleType, UserStatusType } from 'src/shared/config/types'

import { plural } from 'src/shared/lib/plural'

import { Blogger } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Customer } from 'src/shared/ui/Customer/Customer'
import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { RejectModal } from 'src/widgets/RejectModal'

import { AdminCabinetSearch } from '../AdminCabinetSearch'

import styles from './AdminCabinetUsers.module.scss'

enum TabType {
  VERIFICATION_REQUESTS = 'VERIFICATION_REQUESTS',
  UPDATE_REQUESTS = 'UPDATE_REQUESTS',
  BLOGGERS = 'BLOGGERS',
  CUSTOMERS = 'CUSTOMERS',
  BLOCKED_BLOGGERS = 'BLOCKED_BLOGGERS',
  BLOCKED_CUSTOMERS = 'BLOCKED_CUSTOMERS',
}

export const AdminCabinetUsers: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(TabType.VERIFICATION_REQUESTS)
  const [usersCounts, setUsersCounts] = useState<Record<string, number>>({})
  const [bloggerToReject, setBloggerToReject] = useState<UserListResponseDto>()
  const [search, setSearchValue] = useState('')
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const openUser = useCallback((id: number) => navigate(`${CABINET_USERS_ROUTE}/${id}`), [navigate])

  const role = useMemo(() => {
    switch (activeTabId) {
      case TabType.CUSTOMERS:
      case TabType.BLOCKED_CUSTOMERS:
        return RoleType.CUSTOMER
      default:
        return RoleType.BLOGER
    }
  }, [activeTabId])

  const status = useMemo(() => {
    switch (activeTabId) {
      case TabType.VERIFICATION_REQUESTS:
        return UserStatusType.NOT_ACTIVATED
      case TabType.BLOGGERS:
      case TabType.UPDATE_REQUESTS:
      case TabType.CUSTOMERS:
        return UserStatusType.ACTIVATED
      default:
        return UserStatusType.CANCELED
    }
  }, [activeTabId])

  const isFullInfo = activeTabId === TabType.VERIFICATION_REQUESTS ? true : undefined

  const isUpdateRequests = activeTabId === TabType.UPDATE_REQUESTS

  const { data: usersData } = useQuery({
    queryKey: ['users', activeTabId, search],
    // TODO Добавить пагинацию
    queryFn: () =>
      AdminAPI.api.managementControllerGetAllUsers({
        page: 1,
        take: 200,
        role,
        status,
        isFullInfo,
        search,
        isApproveChanges: isUpdateRequests,
      }),
  })

  const users = (usersData?.data.data ?? []) as UserListResponseDto[]

  const { mutate: updateUserMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { userId: number; data: UserActivateDto }
  >({
    mutationFn: ({ userId, data }) => AdminAPI.api.managementControllerUserActivated(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  })

  const { mutate: updateUserStatusMutation, isPending: isLoadingStatusUpdate } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { userId: number; data: ApproveChangesDto }
  >({
    mutationFn: ({ userId, data }) => AdminAPI.api.managementControllerApproveBlogerChanges(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
  })

  useEffect(() => {
    if (usersData?.data) {
      const { data, ...newUsersCounts } = usersData.data

      if (newUsersCounts) {
        setUsersCounts(newUsersCounts)
      }
    }
  }, [usersData])

  const usersTotal = usersCounts.total

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.VERIFICATION_REQUESTS,
        title: 'Заявки на верификацию',
        badge: usersCounts.numberToVerify,
      },
      {
        tabId: TabType.UPDATE_REQUESTS,
        title: 'Заявки на изменение',
        badge: usersCounts.approveChanges,
      },
      {
        tabId: TabType.BLOGGERS,
        title: 'Блогеры',
        badge: usersCounts.blogerCount,
      },
      {
        tabId: TabType.CUSTOMERS,
        title: 'Селлеры',
        badge: usersCounts.customerCount,
      },
      {
        tabId: TabType.BLOCKED_BLOGGERS,
        title: 'Заблокированные блогеры',
        badge: usersCounts.blogerBlockedCount,
      },
      {
        tabId: TabType.BLOCKED_CUSTOMERS,
        title: 'Заблокированные селлеры',
        badge: usersCounts.customerBlockedCount,
      },
    ],
    [usersCounts],
  )

  const renderUser = useCallback(
    (user: UserListResponseDto) => {
      const userId = user.id
      const key = `${activeTabId}-${userId}`

      if (activeTabId === TabType.VERIFICATION_REQUESTS) {
        return (
          <Blogger key={key} {...user} onClick={() => openUser(userId)}>
            <Flex flexDirection="column" gap={8}>
              <CabinetActionButton
                kind="primary"
                disabled={isLoading}
                onClick={(e) => {
                  const updateUserToastId = toast.loading('Подтверждаем пользователя')
                  e.stopPropagation()
                  updateUserMutation(
                    { userId, data: { status: UserStatusType.ACTIVATED } },
                    {
                      onSuccess: () => {
                        toast.success('Пользователь подтверждён! ✨', {
                          id: updateUserToastId,
                        })
                      },
                      onError: (data) => {
                        toast.error(data.error?.message || 'Ошибка при подтверждении пользователя', {
                          id: updateUserToastId,
                        })
                      },
                    },
                  )
                }}
              >
                Подтвердить
              </CabinetActionButton>
              <CabinetActionButton
                kind="ghost"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  setBloggerToReject(user)
                }}
              >
                Отклонить
              </CabinetActionButton>
            </Flex>
          </Blogger>
        )
      }

      if (activeTabId === TabType.UPDATE_REQUESTS) {
        return (
          <Blogger key={key} {...user} onClick={() => openUser(userId)}>
            <Flex flexDirection="column" gap={8}>
              <CabinetActionButton
                kind="primary"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  const updateUserToastId = toast.loading('Обновляем статус пользователя')

                  updateUserStatusMutation(
                    { userId, data: { status: 'CONFIRMED' } },
                    {
                      onSuccess: () => {
                        toast.success('Заявка на изменение одобрена! ✨', { id: updateUserToastId })
                      },
                      onError: (data) => {
                        toast.error(data.error?.message || 'Ошибка при обновлении статуса пользователя', {
                          id: updateUserToastId,
                        })
                      },
                    },
                  )
                }}
              >
                Подтвердить
              </CabinetActionButton>
              <CabinetActionButton
                kind="ghost"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation()
                  const updateUserToastId = toast.loading('Обновляем статус пользователя')

                  updateUserStatusMutation(
                    { userId, data: { status: 'REJECTED' } },
                    {
                      onSuccess: () => {
                        toast.success('Заявка на изменение одобрена! ✨', { id: updateUserToastId })
                      },
                      onError: (data) => {
                        toast.error(data.error?.message || 'Ошибка при обновлении статуса пользователя', {
                          id: updateUserToastId,
                        })
                      },
                    },
                  )
                }}
              >
                Отклонить
              </CabinetActionButton>
            </Flex>
          </Blogger>
        )
      }

      if (activeTabId === TabType.BLOGGERS || activeTabId === TabType.CUSTOMERS) {
        const Component = activeTabId === TabType.BLOGGERS ? Blogger : Customer

        return (
          <Component key={key} {...user} onClick={() => openUser(userId)}>
            <CabinetActionButton
              kind="ghost"
              disabled={isLoading}
              onClick={(e) => {
                const updateUserToastId = toast.loading('Блокируем пользователя')
                e.stopPropagation()
                updateUserMutation(
                  { userId, data: { status: UserStatusType.CANCELED } },
                  {
                    onSuccess: () => {
                      toast.success('Пользователь заблокирован!', {
                        id: updateUserToastId,
                      })
                    },
                    onError: (data) => {
                      toast.error(data.error?.message || 'Ошибка при блокировке', {
                        id: updateUserToastId,
                      })
                    },
                  },
                )
              }}
            >
              Заблокировать
            </CabinetActionButton>
          </Component>
        )
      }

      if (activeTabId === TabType.BLOCKED_BLOGGERS || activeTabId === TabType.BLOCKED_CUSTOMERS) {
        const Component = activeTabId === TabType.BLOCKED_BLOGGERS ? Blogger : Customer

        return (
          <Component key={key} {...user} onClick={() => openUser(userId)}>
            <CabinetActionButton
              kind="ghost"
              disabled={isLoading}
              onClick={(e) => {
                const updateUserToastId = toast.loading('Разблокируем пользователя')
                e.stopPropagation()
                updateUserMutation(
                  { userId, data: { status: UserStatusType.ACTIVATED } },
                  {
                    onSuccess: () => {
                      toast.success('Пользователь разблокирован!', {
                        id: updateUserToastId,
                      })
                    },
                    onError: (data) => {
                      toast.error(data.error?.message || 'Ошибка при разблокировке', {
                        id: updateUserToastId,
                      })
                    },
                  },
                )
              }}
            >
              Разблокировать
            </CabinetActionButton>
          </Component>
        )
      }

      return undefined
    },
    [activeTabId, updateUserMutation, isLoading],
  )

  const handleRejectModalSendForImprovement = useCallback(
    (comment: string) => {
      const updateUserToastId = toast.loading('Отправляем пользователя на доработку')
      updateUserMutation(
        {
          userId: bloggerToReject!.id,
          data: { status: UserStatusType.INCOMPLETE_DATA, сomment: comment },
        },
        {
          onSuccess: () => {
            toast.success('Пользователь отправлен на доработку!', {
              id: updateUserToastId,
            })
          },
          onError: (data) => {
            toast.error(data.error?.message || 'Ошибка при отправке пользователя на доработку', {
              id: updateUserToastId,
            })
          },
        },
      )
      setBloggerToReject(undefined)
    },
    [bloggerToReject],
  )

  const handleRejectModalBlock = useCallback(() => {
    const updateUserToastId = toast.loading('Отклоняем пользователя')
    updateUserMutation(
      { userId: bloggerToReject!.id, data: { status: UserStatusType.CANCELED } },
      {
        onSuccess: () => {
          toast.success('Пользователь отклонён!', {
            id: updateUserToastId,
          })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Ошибка при отклонении пользователя', {
            id: updateUserToastId,
          })
        },
      },
    )
    setBloggerToReject(undefined)
  }, [bloggerToReject])

  const handleRejectModalClose = useCallback(() => setBloggerToReject(undefined), [])

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>
        {usersTotal && `${usersTotal} ${plural(['анкета', 'анкеты', 'анкет'], usersTotal)}`}
      </h1>
      <Tabs className={styles.Tabs} activeTabId={activeTabId} tabs={tabs} onTabClick={setActiveTabId} />
      <AdminCabinetSearch setSearchValue={setSearchValue} />
      <Flex className={styles.Users} flexWrap="wrap" gap={16}>
        {users.map(renderUser)}
      </Flex>
      {bloggerToReject && (
        <RejectModal
          blogger={bloggerToReject}
          onSendForImprovement={handleRejectModalSendForImprovement}
          onBlock={handleRejectModalBlock}
          onClose={handleRejectModalClose}
        />
      )}
    </CabinetPageLayout>
  )
}
