import React, { ReactElement } from 'react'
import type { FieldValues } from 'react-hook-form'

import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'

import { ReactHookFormFieldType } from 'src/shared/config/types'

import { TextareaField, type TextareaFieldPropsType } from '../TextareaField'

type TextareaFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = ReactHookFormFieldType<
  TFieldValues,
  TextareaFieldPropsType
>

export const TextareaFormField = <TFieldValues extends FieldValues = FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  ...props
}: TextareaFormFieldPropsType<TFieldValues>): ReactElement<TextareaFormFieldPropsType<TFieldValues>> => {
  const { field, error } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return <TextareaField {...field} error={error} {...props} />
}
