import React, { FC } from 'react'
import cn from 'classnames'
import { Icon } from 'src/shared/ui/Icon'

import styles from './TelegramButton.module.scss'
import { Link } from 'react-router-dom'

interface TelegramButtonPropsType {
  className: string
}

export const TelegramButton: FC<TelegramButtonPropsType> = ({ className }) => {
  return (
    <Link target="_blank" to={'https://t.me/marketiq_support'} className={cn(styles.TelegramButton, className)}>
      <div className={styles.Circle} />
      <Icon className={styles.Icon} name="telegram" />
      <span className={styles.Text}>
        Написать
        <br />в телеграм
      </span>
    </Link>
  )
}
