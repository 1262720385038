import React, { FC } from 'react'
import cn from 'classnames'

import { BankDetailsFormField } from 'src/shared/ui/BankDetailsFormField'
import { Flex } from 'src/shared/ui/Flex'

import { useBloggerForm } from '../../model/context'

import styles from '../BloggerForm/BloggerForm.module.scss'

interface BankDetailsSectionProps {
  description?: string
  className?: string
}

export const BankDetailsSection: FC<BankDetailsSectionProps> = ({ description, className }) => {
  const { isLoading } = useBloggerForm()

  return (
    <Flex flexDirection="column" className={cn(styles.Block, className)}>
      <Flex flexDirection="column" gap={8}>
        <h2 className={styles.Title}>Банковские реквизиты</h2>
        {description && <span className={styles.Description}>{description}</span>}
      </Flex>

      <BankDetailsFormField
        name="bankDetails"
        disabled={isLoading}
        // className={cn(styles.BankDetails, !isBloggerVerificationForm && styles.BankDetailsVerif)}
        className={cn(styles.BankDetails)}
        kind="cabinet"
      />
    </Flex>
  )
}
