import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AdminAPI, CommonMessageResponse, HttpResponse, UpdateOrderResponseDto } from "src/shared/api"

export const useUpdateOrderResponseMutation = (orderId: number, responseId: number) => {
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    {step: number, data: UpdateOrderResponseDto}
  >({
    mutationFn: ({ step, data }) => AdminAPI.api.orderResponsesControllerSteps(responseId, step, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders', 'my', orderId] })
  })
}
