import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'

import { SignInUserResponseDto } from '../api'
import { LOGIN_ROUTE } from '../config/consts'

export const useUserInfo = () => {
  const [accessToken] = useLocalStorage<string>('accessToken', undefined, { raw: true })
  const [user] = useLocalStorage<SignInUserResponseDto & { hasTg?: boolean }>('user')
  const navigate = useNavigate()

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    localStorage.removeItem('fpHash')

    navigate(LOGIN_ROUTE)
  }, [])

  return {
    accessToken,
    user,
    logout,
  }
}
