import React, { FC, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { AdminAPI, ResponsesManagementDto } from 'src/shared/api'
import { BloggerType, OrderByCustomerResponseDto } from 'src/shared/config/types'

import { CabinetTaskPageLayout } from 'src/widgets/CabinetTaskPageLayout'

import { RejectModal } from './RejectModal/RejectModal'

export const CabinetTask: FC = () => {
  const [bloggerToReject, setBloggerToReject] = useState<BloggerType>()
  const { taskId } = useParams()
  const orderId = Number(taskId)
  const queryClient = useQueryClient()

  const { data: ordersData } = useQuery({
    queryKey: ['orders', orderId],
    queryFn: () => AdminAPI.api.ordersControllerFindOne(orderId),
  })

  const ordersResponsesManagementMutation = useMutation({
    mutationFn: (data: ResponsesManagementDto) => AdminAPI.api.ordersControllerResponsesManagement(orderId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders', orderId] }),
    onError: (data: any) => toast.error(data.error?.message || 'Произошла ошибка'),
  })

  const order = useMemo(() => ordersData?.data as OrderByCustomerResponseDto | undefined, [ordersData])

  const handleBloggerConfirm = useCallback(
    (blogger: BloggerType) => ordersResponsesManagementMutation.mutate({ rejected: [], confirmed: [blogger.id] }),
    [ordersResponsesManagementMutation],
  )

  const handleBloggerReject = useCallback((blogger: BloggerType) => setBloggerToReject(blogger), [])

  const handleRejectModalClose = useCallback(() => setBloggerToReject(undefined), [])

  const handleRejectModalSubmit = useCallback(() => {
    ordersResponsesManagementMutation.mutate({ rejected: [bloggerToReject!.id], confirmed: [] })
    setBloggerToReject(undefined)
  }, [bloggerToReject])

  if (!order || !order.customReportersStatistic) {
    return null
  }

  return (
    <>
      <CabinetTaskPageLayout
        order={order}
        orderId={orderId}
        onConfirm={handleBloggerConfirm}
        onReject={handleBloggerReject}
      />
      {bloggerToReject && (
        <RejectModal blogger={bloggerToReject} onSubmit={handleRejectModalSubmit} onClose={handleRejectModalClose} />
      )}
    </>
  )
}
