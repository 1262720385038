import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Avatar.module.scss'

interface AvatarPropsType {
  className?: string
  avatar?: string
  firstName: string
  type?: 'circle' | 'square'
  onClick?(): void
}

export const Avatar: FC<AvatarPropsType> = ({ className, avatar, firstName, type = 'circle', onClick }) => {
  return (
    <div className={cn(styles.Avatar, type === 'square' && styles.Avatar_square, className)} onClick={onClick}>
      {avatar ? <img className={styles.Image} src={avatar} /> : <span className={styles.Chart}>{firstName[0]}</span>}
    </div>
  )
}
