import React, { FC, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import cn from 'classnames'

import { useUpload } from 'src/shared/model/useUpload'

import { VerifyInfoResponseDto } from 'src/shared/api'
import { VerifyInfoType } from 'src/shared/config/types'

import { CheckboxField } from 'src/shared/ui/CheckboxField'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { UploaderFormField } from 'src/shared/ui/UploaderFormField'

import { useBloggerForm } from '../../model/context'

import styles from '../BloggerForm/BloggerForm.module.scss'
import mediaStyles from './MediaList.module.scss'
interface MediaItem {
  id: VerifyInfoType
  type: 'image' | 'video'
  src?: string
  description: string
  label: string
}

interface MediaListProps {
  isOnlyEditable?: boolean
  isOnlyVisible?: boolean
}

export const MediaList: FC<MediaListProps> = ({ isOnlyEditable = false, isOnlyVisible }) => {
  const [currentUploading, setCurrentUploading] = useState<VerifyInfoType | undefined>()
  const [isEditMode, setIsEditMode] = useState(false)

  const { methods, setIsUploading } = useBloggerForm()
  const upload = useUpload()

  const { setValue, watch } = methods

  const items: MediaItem[] = [
    {
      id: VerifyInfoType.videoStatistics,
      type: 'video',
      src: watch(VerifyInfoType.videoStatistics),
      description: 'Видеозапись экрана общей статистики просмотров за 7,14,30 дней',
      label: '1. Загрузите видеозапись экрана вашей общей статистики просмотров за 7,14,30 дней.',
    },
    {
      id: VerifyInfoType.genderStatistics,
      type: 'image',
      src: watch(VerifyInfoType.genderStatistics),
      description: 'Скриншот статистики возраста аудитории и пола',
      label: '2. Загрузите скриншот статистики возраста аудитории и пола.',
    },
    {
      id: VerifyInfoType.countriesStatistics,
      type: 'image',
      src: watch(VerifyInfoType.countriesStatistics),
      description: 'Скриншот статистики городов аудитории',
      label: '3. Загрузите скриншот статистики городов аудитории.',
    },
    {
      id: VerifyInfoType.lastStoryStatistics,
      type: 'image',
      src: watch(VerifyInfoType.lastStoryStatistics),
      description: 'Скриншот статистики последней истории, где прошло 24 ч и более',
      label: '4. Загрузите скриншот статистики последней истории.',
    },
    {
      id: VerifyInfoType.publicationStatistics,
      type: 'image',
      src: watch(VerifyInfoType.publicationStatistics),
      description: 'Скриншот статистики последнего поста, где прошло 24ч и более',
      label: '5. Загрузите скриншот статистики последнего поста.',
    },
  ]

  const handleEditMode = () => {
    setIsEditMode((prev) => !prev)
  }

  const uploadMedia = useCallback(async (file: File, media: VerifyInfoType) => {
    const uploadMediaToastId = toast.loading('Загружаем файл')
    setCurrentUploading(media)
    setIsUploading(true)
    setValue(media, ' ')

    try {
      const uploadedFile = await upload(file)
      setValue(media, uploadedFile)

      toast.success('Файл загружен', {
        id: uploadMediaToastId,
      })
    } catch (error) {
      toast.error(String(error) || 'Ошибка при загрузке файла', {
        id: uploadMediaToastId,
      })
    } finally {
      setIsUploading(false)
      setCurrentUploading(undefined)
    }
  }, [])

  return (
    <Flex className={styles.Block} flexDirection="column" gap={32}>
      <Flex flexDirection="column" gap={32}>
        {isOnlyEditable ? (
          <Flex flexDirection="column" gap={8}>
            <h2 className={styles.Title}>Охваты</h2>
            <p className={styles.Description}>
              Прикрепите скриншоты статистики из 3 последних сторис и видеозапись раздела охватов
            </p>
          </Flex>
        ) : (
          <Flex justifyContent="space-between">
            <h2 className={styles.Title}>Скриншоты и видео</h2>
            {!isOnlyVisible && (
              <CheckboxField checked={isEditMode} withHiddenLabel onChange={handleEditMode} id="EditMedia">
                Редактирование
              </CheckboxField>
            )}
          </Flex>
        )}

        <Flex flexWrap="wrap" gap={24} className={mediaStyles.MediaList}>
          {items.map((item) =>
            isEditMode || isOnlyEditable ? (
              <UploaderFormField
                key={item.id}
                id={item.id}
                name={item.id}
                type={item.type}
                kind="smallCabinet"
                placeholder="Загрузить файл"
                label={item.label}
                isLoading={currentUploading === item.id}
                className={cn(!isOnlyEditable && mediaStyles.MediaUploadItem)}
                onChange={(file: File) => uploadMedia(file, item.id)}
              />
            ) : (
              <Media
                key={item.id}
                type={item.type}
                src={item.src}
                desciption={item.description}
                className={mediaStyles.Media}
              />
            ),
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
