import React, { FC } from 'react'
import cn from 'classnames'
import { CollectionType } from 'src/shared/config/types'

import styles from './Collection.module.scss'

interface CollectionPropsType {
  className?: string
  collection: string
}

export const Collection: FC<CollectionPropsType> = ({ className, collection }) => {
  return (
    <span className={cn(styles.Collection, collection === CollectionType.AUTOMATIC && styles.Collection__isAutomatic, className)}>
      {collection === CollectionType.AUTOMATIC ? 'Автоматический сбор' : 'Ручной сбор'}
    </span>
  )
}
