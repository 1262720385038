import React, { FC, useCallback } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { useNavigate } from 'react-router'
import { CABINET_ROUTE } from 'src/shared/config/consts'
import { PageTitle } from 'src/shared/ui/PageTitle'

import styles from './CabinetNotifications.module.scss'

export const CabinetNotifications: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_ROUTE), [navigate])

  return (
    <CabinetPageLayout>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Уведомления</PageTitle>
      </div>
      <div className={styles.Text}>
        Этот раздел сайта сейчас в разработке. <br /> Пожалуйста, загляните сюда попозже.
      </div>
    </CabinetPageLayout>
  )
}
