import React, { FC, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { AdminAPI, AuthResetPasswordDto, CommonMessageResponse, HttpResponse } from 'src/shared/api'
import { LOGIN_ROUTE } from 'src/shared/config/consts'

import { getPasswordAgainRules } from 'src/shared/lib/getPasswordAgainRules'
import { passwordRules } from 'src/shared/lib/passwordRules'

import { AuthButton } from 'src/shared/ui/AuthButton'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'

import { AuthBlock } from 'src/widgets/AuthBlock'

import styles from '../Login/Login.module.scss'

interface ResetPasswordFormFields {
  password: string
  passwordAgain: string
}

export const ResetPasswordBlock: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const methods = useForm<ResetPasswordFormFields>({
    defaultValues: {
      password: '',
      passwordAgain: '',
    },
  })
  const { handleSubmit, watch } = methods
  const values = watch()

  const forgotPasswordMutation = useMutation<
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, CommonMessageResponse>,
    AuthResetPasswordDto
  >({
    mutationFn: AdminAPI.api.authControllerResetPassword,
  })
  const isLoading = forgotPasswordMutation.isPending

  const onSubmit = useCallback(
    (data: ResetPasswordFormFields) => {
      const hash = searchParams.get('hash') || ''
      const signInDto: AuthResetPasswordDto = {
        password: data.password,
        hash,
      }

      forgotPasswordMutation.mutate(signInDto, {
        onSuccess: () => {
          navigate(LOGIN_ROUTE)
          toast.success('Вы успешно сбросили пароль! ✨', { duration: 5000 })
        },
        onError: (data) => {
          toast.error(data.error?.message || 'Произошла ошибка со сбросом пароля')
        },
      })
    },
    [forgotPasswordMutation, searchParams],
  )

  const isAuthButtonDisabled = useMemo(
    () => !(Boolean(values.password) && Boolean(values.passwordAgain)) || isLoading,
    [values],
  )

  const passwordAgainRules = useMemo(() => getPasswordAgainRules(values.password), [values.password])

  return (
    <AuthBlock title="Сброс пароля" className={styles.AuthBlock}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
          <div className={styles.InputsContainer}>
            <TextInputFormField
              id="password"
              name="password"
              label="Придумайте пароль"
              placeholder="Пароль"
              type="password"
              autoComplete="new-password"
              rules={passwordRules}
              disabled={isLoading}
              isRequired
            />
            <TextInputFormField
              id="passwordAgain"
              name="passwordAgain"
              label="Повторите пароль"
              placeholder="Пароль"
              type="password"
              rules={passwordAgainRules}
              disabled={isLoading}
              isRequired
            />
            <input type="password" className="visibility-hidden" />
          </div>
          <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
            Обновить пароль
          </AuthButton>
        </form>
      </FormProvider>
    </AuthBlock>
  )
}
