import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Accordion } from 'src/shared/ui/Accordion'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'
import { CABINET_ROUTE } from 'src/shared/config/consts'
import { BLOGGERCHECK } from '../../config/consts'

import styles from './CabinetBloggersInfo.module.scss'

export const CabinetBloggersInfo: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_ROUTE), [navigate])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <PageTitle>Блогеры и как мы их выбираем</PageTitle>
        <p className={styles.Text}>
          Проверка блогеров на нашем сервисе - это самая основная наша задача, у нас на сайте находятся уже проверенные
          блогеры, которые прошли у нас несколько этапов верификации!
        </p>
      </Flex>
      <Accordion kind="cabinet" type="multiply" data={BLOGGERCHECK} />
    </CabinetPageLayout>
  )
}
