import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale/ru'

import { useUserInfo } from 'src/shared/model/useUser'

import { CustomReporterType, OrderResponsesResponseDto, RoleType } from 'src/shared/config/types'

import { Avatar } from 'src/shared/ui/Avatar'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'
import { PageTitle } from 'src/shared/ui/PageTitle'

import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CompletedStage } from '../CompletedStage/CompletedStage'
import { ProgressStage } from '../ProgressStage/ProgressStage'
import { WaitPayConfirmStage } from '../WaitPayConfirmStage/WaitPayConfirmStage'
import { WaitPaymentStage } from '../WaitPaymentStage/WaitPaymentStage'

import styles from './CabinetIntegrationPageLayout.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface CabinetIntegrationPageLayoutPropsType {
  orderResponse: OrderResponsesResponseDto
}

export const CabinetIntegrationPageLayout: FC<CabinetIntegrationPageLayoutPropsType> = ({ orderResponse }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const openSupport = () => {
    window.open('https://t.me/marketiq_support', '_blank')
  }

  const renderStage = useCallback(() => {
    if (!orderResponse) {
      return null
    }

    if (orderResponse.status === CustomReporterType.WAIT_PAYMENT) {
      return <WaitPaymentStage role={role} orderResponse={orderResponse} />
    }

    if (orderResponse.status === CustomReporterType.WAIT_PAY_CONFIRM) {
      return <WaitPayConfirmStage orderResponse={orderResponse} />
    }

    if (
      orderResponse.status === CustomReporterType.PAID ||
      orderResponse.status === CustomReporterType.COMPLETED_STEP_2
    ) {
      return <ProgressStage orderResponse={orderResponse} />
    }

    if (orderResponse.status === CustomReporterType.COMPLETED) {
      return <CompletedStage orderResponse={orderResponse} />
    }
  }, [role, orderResponse])

  return (
    <CabinetPageLayout className={styles.CabinetIntegrationPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        {orderResponse && (
          <Flex flexDirection="column" isFluid>
            <Flex className={styles.Header}>
              <Flex flexDirection="column" gap={8}>
                <PageTitle>{`Интеграция №${orderResponse.id}`}</PageTitle>
                <span
                  className={styles.CreatedAt}
                >{`Создана ${format(orderResponse.createAt, 'dd MMMM yyyy', { locale: ru })}`}</span>
              </Flex>
              <CabinetGhostButton onClick={openSupport} className={styles.CabinetGhostButton}>
                Обратиться в поддержку
              </CabinetGhostButton>
            </Flex>
            <Flex className={styles.Blogger}>
              <Flex gap={12} alignItems="center">
                <Avatar
                  className={styles.Avatar}
                  avatar={orderResponse.bloger.avatar}
                  firstName={orderResponse.bloger.firstName}
                />
                <Flex flexDirection="column" gap={4}>
                  <span className={styles.FirstName}>{orderResponse.bloger.firstName}</span>
                  <span className={styles.instagram}>{orderResponse.bloger.instagram}</span>
                </Flex>
              </Flex>
              <Flex className={styles.Info}>
                <Flex className={styles.Item} flexDirection="column">
                  <span className={styles.Title}>Подписчики:</span>
                  <span className={styles.Value}>{numberFormat.format(orderResponse.bloger.followersCount)}</span>
                </Flex>
                <Flex className={styles.Item} flexDirection="column">
                  <span className={styles.Title}>Средний охват:</span>
                  <span className={styles.Value}>{numberFormat.format(orderResponse.bloger.coveragesCount)}</span>
                </Flex>
                <Flex className={styles.Item} flexDirection="column">
                  <span className={styles.Title}>Тематика:</span>
                  <Flex gap={4} className={styles.Tags}>
                    {orderResponse.bloger.topics.map((item: any) => (
                      <Flex key={item.name} className={styles.Tag} alignItems="center">
                        {item.name}
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {renderStage()}
          </Flex>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
